<template>
  <header class="uni-header">
    <!-- Header navbar -->
    <div
      class="uni-header-navbar"
      data-uk-sticky="top: 60; animation: uk-animation-slide-top"
    >
      <div class="uk-container uk-container-large">
        <nav
          class="uk-navbar uk-navbar-container uk-navbar-transparent"
          data-uk-navbar
        >
          <div class="uk-navbar-top">
            <div class="uk-navbar-left uk-flex-1 uk-visible@m">
              <ul
                class="uk-navbar-nav dark:uk-text-gray-10 uk-visible@m"
                data-uk-navbar-bound
              >
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li>
                  <router-link to="/explore">Popular Events</router-link>
                </li>

                <!-- <li v-if="loggedIn && userInfo.role !== 'user'">
                  <router-link to="/my-events">My Events</router-link>
                </li> -->
                <li>
                  <router-link to="/contact">Contact Us</router-link>
                </li>

                <!-- <li v-if="loggedIn">
                                <a>Submissions</a>
                                <ul class="uk-submenu">

                                </ul>
                            </li> -->
              </ul>
            </div>

            <div class="uk-navbar-center">
              <div class="uk-navbar-item uk-hidden@m">
                <a class="uk-link" href="#uni_mobile_menu" data-uk-toggle>
                  <span class="material-icons">menu</span>
                </a>
              </div>

              <a
                href="/"
                class="uk-logo uk-navbar-item uk-h4 uk-h3@m uk-margin-remove"
              >
                <span class="uk-visible@l">
                  <img
                    v-if="logo"
                    class="uk-visible"
                    width="140"
                    :src="$baseUrl + logo"
                    alt="islot"
                  />
                </span>
                <span class="uk-hidden@l">
                  <img class="" width="60" :src="$baseUrl + logo" alt="islot" />
                </span>
              </a>
            </div>

            <div class="uk-navbar-right uk-flex-1 uk-flex-right">
              <div class="uk-navbar-item">
                <!-- header: search toggle -->
                <button
                  aria-label="Search"
                  type="button"
                  class="uk-button uk-button-small uk-button-icon uk-radius-circle uk-margin-2xsmall-right uk-visible@m uk-hidden"
                >
                  <i class="uk-icon-small" data-feather="search"></i>
                </button>
                <div
                  class="uk-drop ml-5"
                  data-uk-drop="mode: click; pos: right; offset: 0"
                >
                  <div class="uk-search ml-5 uk-search-navbar uk-width-1-1">
                    <form
                      class="search-form uk-inline uk-width-1-1@m"
                      action=""
                      method="get"
                    >
                      <span class="uk-form-icon">
                        <i class="uk-icon-small" data-feather="search"></i>
                      </span>
                      <input
                        @keyup.enter="goToHome()"
                        v-model="searchedValue"
                        class="uk-input uk-form-medium"
                        type="text"
                        placeholder="Search"
                      />

                      <button
                        aria-label="Clear Search Input"
                        type="button"
                        class="search-clear uk-form-icon uk-form-icon-flip uk-hidden"
                      >
                        <i class="uk-icon-xsmall" data-feather="x"></i>
                      </button>
                      <div
                        class="search-results uk-card uk-card-small uk-card-default uk-width-medium@m uk-height-large@m uk-margin-small-top uk-overflow-auto uk-position-absolute uk-position-z-index uk-radius uk-hidden dark:uk-background-gray-90"
                      >
                        <div
                          class="search-results-content uk-panel uk-hidden"
                        ></div>
                        <div
                          class="search-results-loading uk-flex-center uk-flex-middle uk-height-1-1"
                        >
                          <span
                            class="uk-flex-center uk-flex-middle"
                            data-uk-spinner="ratio: .8"
                          ></span>
                        </div>
                      </div>
                      <noscript>
                        <button
                          aria-label="Submit"
                          type="submit"
                          class="uk-form-icon uk-form-icon-flip"
                        >
                          <i class="uk-icon-xsmall" data-feather="search"></i>
                        </button>
                      </noscript>
                    </form>
                  </div>
                </div>

                <!-- header: nofitications -->

                <!-- header: call to actions -->
                <!-- <router-link
                  v-if="loggedIn && userInfo.role !== 'user'"
                  to="/create-post"
                  class="uk-button uk-button-small uk-button-darkgrey uk-radius-large uk-margin-xsmall-left uk-margin-xsmall-right uk-visible@m"
                >
                  <span>Create Event</span>
                </router-link> -->

                <router-link
                  v-if="!loggedIn"
                  to="/register"
                  class="uk-button uk-button-small uk-button-darkgrey uk-radius-large uk-margin-xsmall-left uk-margin-xsmall-right uk-visible@m"
                >
                  <span>Register</span>
                </router-link>
                <router-link
                  v-if="!loggedIn"
                  to="/login"
                  class="uk-button uk-button-small uk-button-default uk-radius-large"
                >
                  <span>Sign in</span>
                  <!-- <a v-if="!loggedIn" class="uk-button uk-button-small uk-button-default uk-radius-large" href="#" @click="login()"
                        >Sign in</a> -->
                </router-link>

                <!-- header: user logged in -->

                <button
                  v-else
                  :style="{ display: loggedIn ? 'block !important' : 'none' }"
                  type="button"
                  class="uni-login-visible uk-button uk-button-small uk-button-icon uk-radius-circle uk-box-shadow-xsmall uk-margin-xsmall-left profile-image"
                  aria-expanded="false"
                >
                  <img
                    v-if="userInfo.image"
                    :src="$baseUrl + userInfo.image"
                    :alt="userInfo.name"
                    class="uni-user-avatar-image uk-cover uk-radius-circle"
                    data-uk-cover=""
                  />
                  <img
                    v-else
                    v-show="!userInfo.image"
                    src="/assets/images/artworks/15.gif"
                    alt="test user"
                    class="uni-user-avatar-image uk-cover uk-radius-circle"
                    data-uk-cover=""
                  />
                </button>

                <div
                  v-if="loggedIn"
                  class="uk-dropdown uk-dropdown-bottom-right"
                  data-uk-dropdown="boundary: .uni-header-navbar; mode: click; animation: uk-animation-slide-bottom-small;"
                  style="left: 959.343px; top: 59.0003px"
                >
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="uk-margin-xsmall">
                      <div
                        class="uk-grid-2xsmall uk-flex-middle uk-grid uk-grid-stack"
                        data-uk-grid=""
                      >
                        <div class="uk-width-expand uk-first-column">
                          <h5 class="uk-h6 uk-margin-remove">
                            <router-link
                              :to="'/author/' + userInfo.id"
                              class="uni-user-link uk-link-reset"
                              >@{{ userInfo.name }}</router-link
                            >
                          </h5>
                          <span>Edit Profile</span>
                          <!-- <span class="uk-text-small uk-text-muted">Balance:
                                                    <span class="uk-text-gradient">{{
                              userBalance ? userBalance : "0"
                            }}</span></span> -->
                        </div>
                      </div>
                      <router-link
                        :to="'/author/' + userInfo.id"
                        aria-label="Dashboard"
                        class="uk-position-cover"
                      ></router-link>
                    </li>
                    <li>
                      <router-link to="/activity">Activity</router-link>
                    </li>
                    <!-- <li v-if="loggedIn && userInfo.role !== 'user'">
                      <router-link to="/submissions"
                        >Submissions received</router-link
                      >
                    </li> -->
                    <li>
                      <router-link to="/my-submissions"
                        >My submission</router-link
                      >
                    </li>
                    <li class="uk-nav-divider"></li>

                    <li class="uk-nav-divider"></li>
                    <li>
                      <a class="uk-text-danger" href="#" @click="logout()"
                        >Logout</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapState({
      logo: (state) => state.logo,
      loggedIn: (state) => state.loggedIn,
      userBalance: (state) => state.userBalance,
    }),
    userIsAuthenticated() {
      return this.isAuthenticated;
    },
    ...mapGetters({
      userInfo: "getUserInfo",
      getLoginStatus: "getLoginStatus",
    }),
  },
  data() {
    return {
      isAuthenticated: false,
      searchedValue: "",
      loggedUser: null,
    };
  },
  methods: {
    ...mapActions([
      "fetchUserInfo",
      "logout",
      "fetchUserBalance",
      "fetchPosts",
    ]),

    filter() {
      this.fetchPosts({
        currentPage: this.currentPage,
        searchedValue: this.searchedValue,
      });
    },
    goToHome() {
      this.$router.push({
        path: "/explore",
        query: {
          q: this.searchedValue,
        },
      });
    },
  },
  created() {},
  // mounted() {
  //   console.log("mounted");
  //   this.fetchUserInfo();
  //   this.fetchUserBalance();
  //   let token = localStorage.getItem("sanctum_token");
  //   if (token && token.length > 0 && token != "undefined") {
  //     this.$store.state.loggedIn = true;
  //   }
  //   if (this.$router.currentRoute._value.name != "LoginView") {
  //     this.fetchPosts({
  //       currentPage: this.currentPage,
  //       searchedValue: "",
  //     });
  //   }
  // },
  mounted() {
    console.log("mounted");
    this.fetchUserInfo()
      .then(() => {
        return Promise.all([
          this.fetchUserBalance(),
          this.fetchPosts({
            currentPage: this.currentPage,
            searchedValue: "",
          }),
        ]);
      })
      .then(() => {
        let token = localStorage.getItem("sanctum_token");
        if (token && token.length > 0 && token !== "undefined") {
          this.$store.state.loggedIn = true;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
