<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>
  <div id="image_popup" class="uk-modal" data-uk-modal>
    <div
      class="uk-modal-dialog uk-card-xsmall uk-card-small@m uk-padding-medium-horizontal uk-margin-auto-vertical uk-width-medium@m uk-text-center uk-radius-large"
    >
      <button
        aria-label="Close"
        class="uk-modal-close-outside"
        type="button"
        data-uk-close
      ></button>
      <img v-if="popupImageUrl" :src="popupImageUrl" alt="Submission Image" style="max-height: 300px; max-width: 320px;">
      <!-- Add this line to display the submission image -->
      <div
        class="uni-modal-share-icons uk-grid uk-child-width-expand uk-flex-center"
        data-uk-grid
      ></div>
    </div>
  </div>
  <EditSubmissionModal :data="submissionToEdit"></EditSubmissionModal>
  <!-- Place a bid modal -->
  <PlaceBidModal
    :amountForTicket="singlePost.amount_for_ticket"
  ></PlaceBidModal>

  <!-- Connect wallet modal -->
  <WalletModal></WalletModal>

  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>

    <!-- Dark/Light toggle -->
    <!-- <div class="darkmode-trigger uk-position-bottom-right uk-position-small uk-position-fixed uk-box-shadow-large uk-radius-circle" data-darkmode-toggle="">
                <label class="switch">
                    <span class="sr-only">Dark mode toggle</span>
                    <input type="checkbox">
                    <span class="slider"></span>
                </label>
            </div> -->

    <div class="uni-artwork">
      <div
        class="uni-artwork-media event_detail_banner uk-section-medium@m uk-background-gray-10 dark:uk-background-white-5"
      >
        <div class="uk-container uk-container-large p-0">
          <div class="uk-panel">
            <div class="uk-panel uk-flex-center uk-flex-top">
              <div class="featured-image uk-panel uk-overflow-hidden">
                <canvas width="500" height="500"></canvas>
                <img
                  v-if="singlePost.image"
                  :src="$baseUrl + singlePost.image"
                  :alt="singlePost.title"
                  class="uk-cover"
                />
                <a
                  aria-label="Zoom"
                  href="#"
                  class="zoom-out uk-position-cover"
                ></a>
              </div>
            </div>
            <div class="uk-position-bottom-right uk-padding-xsmall">
              <!-- <a href="assets/images/artworks/07.gif" class="zoom-out uk-button uk-button-xsmall uk-button-small@m uk-button-primary uk-button-invert uk-button-icon uk-radius-circle uk-box-shadow-2xsmall" data-caption="Metaverse Game - by @TheSalvare">
                                    <i class="material-icons uk-icon-xsmall uk-icon-small@m uk-text-unset">zoom_out_map</i>
                                </a>
                                <button type="button" class="uk-button uk-button-xsmall uk-button-small@m uk-button-primary uk-button-invert uk-button-icon uk-radius-circle uk-box-shadow-2xsmall uk-margin-xsmall-left" data-uk-toggle="target: #share_this">
                                    <i class="material-icons uk-icon-xsmall uk-icon-small@m uk-text-unset">share</i>
                                </button> -->
              <!-- <button type="button" class="uk-button uk-button-xsmall uk-button-small@m uk-button-danger uk-button-invert uk-button-icon uk-radius-circle uk-box-shadow-2xsmall uk-margin-xsmall-left" data-uk-toggle="target: #send_report">
                                    <i class="material-icons uk-icon-xsmall uk-icon-small@m">flag</i>
                                </button> -->
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="singlePost.winner_ticket !== null && singlePost.auction_end !==null"
        class="winner-profile-section"
      >
        <div class="uk-container uk-container-large">
          <div class="winner-profile-wrapper">
            <div class="winner-info">
              <div>
                <div class="user-img">
                  <img :src="$baseUrl + winner.image" alt="winner-img" />
                </div>
                <div class="badge">
                  <span
                    >Winner <i class="fas fa-medal" aria-hidden="true"></i
                  ></span>
                </div>
              </div>
              <div class="winner-content">
                <h5 class="name">{{ winner.name }}</h5>
                <p class="user-email">{{ winner.email }}</p>
                <h5 class="game-status">Games won</h5>
                <p>{{ gamesWon }}</p>
              </div>
            </div>
            <div class="user-address" v-if="singlePost">
              <h5>LottoLand Asia</h5>
              <p>{{ formattedDate.time }} Results</p>
              <p class="date" v-if="singlePost.auction_end">
                <i class="fas fa-calendar-alt" aria-hidden="true"></i> {{ formattedDate.date }}
              </p>
              <p class="date" v-else>
                <i class="fas fa-calendar-alt" aria-hidden="true"></i>To Announce
              </p>
            </div>
            <div class="ticket-number">
              <h3>{{ singlePost.winner_ticket }}</h3>
              <p>Ticket Number</p>
            </div>
            <div class="price-amount">
              <h3>₹ {{ singlePost.sponsor_amount }}</h3>
              <p>Prize amount</p>
            </div>
          </div>

          <!-- <div v-if="singlePost.announcement_text " class="announcement-text">
            <span>{{ singlePost.announcement_text }}</span>
          </div> -->
        </div>
      </div>
      <div v-else-if="singlePost.auction_end !== null " class="winner-profile-section">
        <div class="uk-container uk-container-large">
          <div class="winner-profile-wrapper">
            <div class="winner-info dummy-info">
              <div>
                <div class="user-img dummy-img">
                  <!-- Placeholder image or dummy image -->
                  <img src="/assets/images/avatar.png" alt="dummy-img" />
                </div>
              </div>
              <div class="winner-content dummy-content">
                <h5 class="dummy-name"></h5>
                <p class="dummy-user-email"></p>
                <h5 class="dummy-game-status"></h5>
                <p class="dummy-result"></p>
              </div>
            </div>
            <div class="ticket-number">
              <h3>
                <i class="fas fa-star" aria-hidden="true"></i
                ><i class="fas fa-star" aria-hidden="true"></i
                ><i class="fas fa-star" aria-hidden="true"></i>
              </h3>
              <p>Ticket Number</p>
            </div>
            <div class="user-address" v-if="singlePost">
              <h5>LottoLand Asia</h5>
              <p>{{ formattedDate.time }} Results</p>
              <p class="date" v-if="singlePost.auction_end">
                <i class="fas fa-calendar-alt" aria-hidden="true"></i> {{ formattedDate.date }}
              </p>
              <p class="date" v-else>
                <i class="fas fa-calendar-alt" aria-hidden="true"></i> To Announce
              </p>
            </div>
            <div class="price-amount">
              <h3>₹ {{ singlePost.sponsor_amount }}</h3>
              <p>Prize amount</p>
            </div>
          </div>

          <!-- <div v-if="singlePost.announcement_text" class="announcement-text">
            <span>{{ singlePost.announcement_text }}</span>
          </div> -->
        </div>
      </div>
      <div
        v-if="singlePost.announcement_text !==null"
        class="announce-section"
      >
        <div class="uk-container uk-container-large">
          <div  class="announcement-text">
            <span>{{ singlePost.announcement_text }}</span>
          </div>
        </div>
      </div>

      <div class="uk-section uk-section-medium@m">
        <div class="uk-container uk-container-large">
          <div
            class="uk-grid-small uk-grid-2xlarge@m uk-grid-match uk-grid"
            data-uk-grid
          >
            <div class="uk-width-expand">
              <div class="uk-panel uk-text-large@m">
                <h1 style="margin-bottom: 0px" class="uk-h3 uk-h2@m">
                  {{ singlePost.title }}
                </h1>
                <div
                  class="uk-grid uk-grid-divider uk-flex-middle"
                  data-uk-grid
                >
                  <div>
                    <span class="uk-text-gradient uk-text-large uk-text-bold"
                      >₹ {{ singlePost.sponsor_amount }}</span
                    >
                    <!-- <span class="uk-text-muted">( 0.009 ETH)</span> -->
                  </div>
                  <div>
                    <span class="uk-text-muted">jackpot Prize</span>
                  </div>
                </div>
                <h3 class="uk-h6 uk-h5@m uk-margin-large-top@m mb-1 pt-1">
                  Description
                </h3>
                <div>
                  <div v-html="singlePost.description"></div>
                </div>

                <div
                  class="uk-grid uk-grid-2xsmall mt-30 uk-grid-xsmall@m uk-child-width-1-2 uk-text-small uk-margin-large-top@m uk-text-bold uk-text-medium"
                  data-uk-grid=""
                >
                  <div class="uk-first-column">
                    <div class="uk-panel">
                      <div
                        class="uk-text-bold uk-text-muted uk-margin-xsmall-bottom"
                      >
                        Creator
                      </div>
                      <div
                        class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid uk-grid-stack"
                        data-uk-grid=""
                      >
                        <div class="uk-width-auto uk-first-column">
                          <div class="uni-user-avatar uk-panel">
                            <div
                              class="uk-panel uk-overflow-hidden uk-radius-circle"
                            >
                              <canvas width="48" height="48"></canvas>
                              <img
                                v-if="singlePost?.user?.image"
                                :src="$baseUrl + singlePost?.user?.image"
                                alt="TheSalvare"
                                class="uni-user-avatar-image uk-cover uk-radius-circle"
                                data-uk-cover=""
                                loading="lazy"
                                style="height: 48px; width: 48px"
                              />
                              <a
                                class="uk-position-cover"
                                href="#"
                                @click.prevent="navigateToUserProfile"
                              >
                              </a>
                            </div>
                            <i
                              class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-xsmall"
                              >check_circle</i
                            >
                          </div>
                        </div>
                        <div class="uk-visible@m">
                          <div>
                            <a
                              class="uni-user-link uk-link-reset uk-text-bold"
                              href="#"
                              @click.prevent="navigateToUserProfile"
                              >{{ singlePost?.user?.name || "" }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="uk-panel">
                      <div
                        class="uk-text-bold uk-text-muted uk-margin-xsmall-bottom"
                      >
                        Provider
                      </div>
                      <div
                        class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid uk-grid-stack"
                        data-uk-grid=""
                      >
                        <div class="uk-width-auto uk-first-column">
                          <div class="uni-user-avatar uk-panel">
                            <div
                              class="uk-panel uk-overflow-hidden uk-radius-circle"
                            >
                              <canvas width="48" height="48"></canvas>
                              <img
                                v-if="singlePost?.provider?.image"
                                :src="$baseUrl + singlePost.provider.image"
                                alt="Seriesone"
                                class="uni-user-avatar-image uk-cover uk-radius-circle"
                                data-uk-cover=""
                                loading="lazy"
                                style="height: 48px; width: 48px"
                              />
                            </div>
                            <i
                              class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-xsmall"
                              >check_circle</i
                            >
                          </div>
                        </div>
                        <div
                          class="uk-visible@m uk-grid-margin uk-first-column"
                        >
                          <div>
                            <a class="uni-user-link uk-link-reset" href="#">{{
                              singlePost?.provider?.name || ""
                            }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h3
                  v-if="mySubmissions.length > 0"
                  class="uk-h6 uk-h5@m uk-margin-large-top@m mb-1 pt-1"
                >
                  My Submission
                </h3>
                <div class="mt-2">
                  <div class="uk-panel">
                    <div
                      id="explore-list-container"
                      class="submission_grid submission-wrapper"
                    >
                      <div
                        class="h-100"
                        v-for="(mySubmission, index) in mySubmissions"
                        :key="index"
                      >
                        <div
                          class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle h-100"
                        >
                          <div
                            class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m"
                            style="margin: 0px 0"
                          >
                            <div class="uk-panel uk-image-middle">
                              <img
                                v-if="mySubmission.image"
                                :src="$baseUrl + mySubmission.image"
                                alt="image is missing"
                                class="uk-radius"
                              />
                            </div>
                          </div>

                          <div class="uni-artwork-content">
                            <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                              <!-- <p
                                v-if="mySubmission.luckdrawnumbers"
                                class="uk-link-reset mt-3"
                                href="#"
                              >
                                {{ getNumbers(mySubmission.luckdrawnumbers) }}
                              </p> -->

                              <div
                                v-if="mySubmission.luckdrawnumbers.length > 3"
                              >
                                <button
                                  @click="
                                    () =>
                                      alertNumbers(mySubmission.luckdrawnumbers)
                                  "
                                  class="read_more_btn mt-4"
                                >
                                  Read More
                                </button>
                              </div>
                              <span
                                  v-if="mySubmission.luckdrawnumbers.length > 0"
                                  class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                              >
                                  Lucky No. {{ getNumbers(mySubmission.luckdrawnumbers.slice(0, 3)) }}
                              </span>
                            </h2>
                            <span
                              class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                              >{{ $formatDate(mySubmission.created_at) }}</span
                            >
                            <span class="uk-text-bold uk-text-gradient">{{
                              mySubmission.amount
                            }}</span>

                            <div class="action-btns">
                              <i class="fas fa-ellipsis-h"></i>

                              <div class="buttons-list">
                                <button
                                  @click="openEditModal(index)"
                                  class="btn"
                                  data-uk-toggle="target: #edit_submission"
                                >
                                  Edit
                                </button>
                                <!-- Delete button with UK icon -->
                                <button
                                  class="btn"
                                  @click="onDeleteButtonClick(mySubmission.id)"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                            <!-- Button to open the edit modal -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr
                  class="uk-margin-medium-top uk-margin-remove-bottom uk-hidden@m"
                />
              </div>
            </div>
            <div class="uk-width-2xlarge@m">
              <div class="uk-panel">
                <div class="post-detail-right-cont">
                  <div class="uk-panel mb-30">
                    <h5
                      class="uk-h6 uk-h5@m uk-margin-xsmall-bottom uk-margin-bottom"
                    >
                      Screenshot amount per entry
                      <div class="info-icon">
                        <i
                          class="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>

                        <div class="tooltip">
                          <span class="tooltiptext"
                            >The screenshot amount per entry is the minimum
                            screenshot amount required to obtain a ticket
                            number. You will receive multiple ticket numbers
                            based on your screenshots. For example, if your
                            screenshot amount is 500 Rs and the screenshot
                            amount per entry is 100 Rs, you will receive 5
                            tickets</span
                          >
                        </div>
                      </div>
                    </h5>
                    <h4 class="uk-h4 uk-h2@m uk-margin-remove">
                      {{ singlePost.amount_for_ticket }}
                    </h4>
                    <div
                      class="trigger-jacpat-cont"
                      v-if="singlePost.event_type != 'count_down'"
                    >
                      <h4>
                        Trigger Jackpot Target : {{ singlePost.target }}
                        <div class="info-icon mb-30">
                          <i
                            class="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div class="tooltip" style="">
                            totalAccumulation
                            <span class="tooltiptext"
                              >Trigger Jackpot Target tooltip</span
                            >
                          </div>
                        </div>
                      </h4>

                      <ProgressBar
                        :percentage="
                          (
                            (100 * totalAccumulation) / singlePost.target || 0
                          ).toFixed(3)
                        "
                      />
                    </div>
                  </div>
                  <div v-if="singlePost.event_type == 'count_down'">
                    <div class="uk-panel event-ending-countdown">
                      <h5 class="uk-h6 uk-h5@m">Event ending in</h5>
                      <div id="count-down" class="countdown_root">
                        <div class="countdown_flex_col uk-background-gradient">
                          <div
                            class="uk-countdown-day uk-h5 uk-h4@m uk-margin-2xsmall uk-text-white"
                          >
                            {{ days || "00" }}
                          </div>
                          <div
                            class="uk-countdown-label uk-text-small uk-text-white"
                          >
                            Days
                          </div>
                        </div>
                        <div class="countdown_flex_col uk-background-gradient">
                          <div
                            class="uk-countdown-hour uk-h5 uk-h4@m uk-margin-2xsmall uk-text-white"
                          >
                            {{ hours || "00" }}
                          </div>
                          <div
                            class="uk-countdown-label uk-text-small uk-text-white"
                          >
                            Hours
                          </div>
                        </div>
                        <div class="countdown_flex_col uk-background-gradient">
                          <div
                            class="uk-countdown-minute uk-h5 uk-h4@m uk-margin-2xsmall uk-text-white"
                          >
                            {{ minutes || "00" }}
                          </div>
                          <div
                            class="uk-countdown-label uk-text-small uk-text-white"
                          >
                            Mins
                          </div>
                        </div>
                        <div class="countdown_flex_col uk-background-gradient">
                          <div
                            class="uk-countdown-second uk-h5 uk-h4@m uk-margin-2xsmall uk-text-white"
                          >
                            {{ seconds || "00" }}
                          </div>
                          <div
                            class="uk-countdown-label uk-text-small router-view uk-text-white"
                          >
                            Sec
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-flex gap-20 buttons-cont">
                    <button
                      type="button"
                      :disabled="
                        totalSubmissionsForPost >= singlePost.ticket_per_user ||
                        singlePost.winner_ticket ||
                        (singlePost.event_type != 'trigger_amount' &&
                          new Date(singlePost.countdown) < new Date()) ||
                        (singlePost.event_type == 'trigger_amount' &&
                          totalAccumulation >= singlePost.target)
                      "
                      class="uk-button uk-button-medium uk-button-primary flex-1"
                      data-uk-toggle="target: #place_bid"
                      @click="submitScreenshotHandler"
                    >
                      Submit Screenshots ({{ totalSubmissionsForPost }}/{{
                        singlePost.ticket_per_user
                      }})
                    </button>
                    <button
                      type="button"
                      @click="playnow(singlePost.post_link)"
                      class="uk-button uk-button-medium uk-button-primary uk-button-alt flex-1"
                    >
                      Play Now
                    </button>
                  </div>
                </div>
                <hr class="uk-margin-medium uk-margin-large@m" />
                <ul
                  class="uk-subnav uk-subnav-small uk-subnav-opacity uk-margin-small-bottom"
                  data-uk-switcher="connect: .uni-switcher-bids; animation: uk-animation-fade;"
                >
                  <li v-if="latestSubmissions.length > 0" class="uk-h6 uk-h5@m">
                    <a href="#bids">Recent Players Submissions</a>
                  </li>
                  <!-- <li class="uk-h6 uk-h5@m"><a href="#history">Sort Higher win</a></li> -->
                </ul>
                <ul
                  class="uni-switcher-bids uk-switcher uk-text-bold uk-text-small uk-text-large@m uk-text-muted"
                  style="max-height: 370px; overflow-y: visible"
                >
                  <li>
                    <div class="uk-panel tab-overflow">
                      <ul
                        class="uk-list uk-list-small recents_cont"
                        data-uk-overflow="selContainer: .uk-switcher; selContent: .tab-overflow"
                        style="padding: 30px 4px 16px;"
                      >
                        <template
                          v-for="(submission, index) in latestSubmissions"
                          :key="index"
                        >
                          <li
                            v-if="
                              submission &&
                              submission.user &&
                              singlePost &&
                              singlePost.user
                            "
                            class="uk-card uk-card-xsmall uk-card-default uk-radius uk-box-shadow-2xsmall uk-background-gray-10 dark:uk-background-white-5"
                            @click="showImagePopup(submission)"
                            data-uk-toggle="target: #image_popup"
                          >
                            <div class="uk-grid-xsmall uk-grid" data-uk-grid>
                              <div class="uk-width-auto">
                                <div>
                                  <div class="uni-user-avatar uk-panel">
                                    <div
                                      class="uk-panel uk-overflow-hidden uk-radius-circle"
                                    >
                                      <canvas width="40" height="40"></canvas>
                                      <img
                                        :src="
                                          $baseUrl +
                                          (submission.user.image || '')
                                        "
                                        alt="0x355...2f4f4"
                                        class="uni-user-avatar-image uk-cover uk-radius-circle"
                                        data-uk-cover
                                        loading="lazy"
                                      />
                                      <router-link
                                        v-if="
                                          submission.user.name &&
                                          singlePost.user.id ===
                                            submission.user.id
                                        "
                                        :to="{
                                          name: 'AuthorView',
                                          params: { id: submission.user.id },
                                        }"
                                        :aria-label="submission.user.name"
                                        class="uk-position-cover"
                                        :disabled="
                                          singlePost.user.id !==
                                          submission.user.id
                                        "
                                      ></router-link>
                                    </div>
                                    <span
                                      v-if="submission.is_verified === 1"
                                      class="verification"
                                    >
                                      <span class="uk-text-meta uk-flex-middle">
                                        <i
                                          class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons"
                                          >check_circle</i
                                        >
                                      </span>
                                    </span>
                                    <span
                                      v-else-if="submission.is_verified === 0"
                                      class="verification"
                                    >
                                      <span class="uk-text-meta uk-flex-middle">
                                        <i
                                          class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-warning material-icons"
                                          >schedule</i
                                        >
                                      </span>
                                    </span>
                                    <span
                                      v-else-if="submission.is_verified === 2"
                                      class="verification"
                                    >
                                      <span class="uk-text-meta uk-flex-middle">
                                        <i
                                          class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-danger material-icons"
                                          >close</i
                                        >
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="uk-width-expand">
                                <div
                                  class="uk-panel uk-grid-small uk-grid uk-flex-between uk-child-width-auto"
                                  data-uk-grid
                                  style="line-height: 1"
                                >
                                  <div>
                                    <div class="info-icon">
                                      <i
                                        class="fa fa-info-circle info-icon"
                                        aria-hidden="true"
                                      ></i>
                                      <div>
                                        <div v-if="!showMoreButton" class="tooltip" style="max-width: 500px; max-height: 300px">
                                            <span class="tooltiptext">
                                                {{
                                                    submission.is_verified === 1
                                                        ? getNumbers(submission.luckdrawnumbers)
                                                        : submission.is_verified === 0
                                                        ? "Not yet verified"
                                                        : "Rejected"
                                                }}
                                            </span>
                                        </div>
                                      </div>
                                    </div>
                                    <p class="uk-margin-2xsmall">
                                      <b
                                        class="uk-text-gray-100 dark:uk-text-white"
                                      >
                                        <router-link
                                          v-if="
                                            submission.user.id ===
                                            singlePost.user.id
                                          "
                                          :to="{
                                            name: 'AuthorView',
                                            params: { id: singlePost.user.id },
                                          }"
                                          class="uni-user-link uk-link-reset"
                                        >
                                          {{ submission.user.name }}
                                        </router-link>

                                        <span v-else>
                                          {{ submission.user.name }}
                                        </span>

                                        {{ " " }}
                                      </b>
                                      <span class="uk-text-medium@m">
                                        {{ submission.submission_entry }}/{{
                                          singlePost.ticket_per_user
                                        }}
                                      </span>
                                    </p>
                                    <span class="uk-text-medium@m">
                                      {{
                                        $formatDate(submission.user.created_at)
                                      }}
                                      at
                                      {{ getTime(submission.user.created_at) }}
                                    </span>
                                  </div>
                                  <div class="uk-width-auto uk-width-1-4@m">
                                    <div class="uk-panel">
                                      <h6
                                        class="uk-text-small uk-h5@m uk-margin-remove-bottom"
                                      >
                                        Winnings <br />
                                        <span class="mt-5px">{{
                                          submission.amount
                                        }}</span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer2></Footer2>
</template>

<script>
import Header from "../components/Header.vue";
import Footer2 from "../components/Footer2.vue";
import MobileMenu from "../components/MobileMenu.vue";
import WalletModal from "../components/WalletModal.vue";
import PlaceBidModal from "../components/PlaceBidModal.vue";
import ProgressBar from "../components/ProgressBar.vue";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { fetchMySubmissions } from "@/store/actions";
import EditSubmissionModal from "./EditSubmissionModal.vue";
export default {
  props: ["id"],
  name: "PostDetail",
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      showEditModal: false,
      submissionToEdit: null,
      submission: {},
      showEditFields: false,
      baseUrl: "https://admin.islot.in/",
      isImagePopupVisible: false,
      popupImageUrl: "",
      allLuckdrawNumbers: "",
      showMoreButton: false,
    };
  },
  components: {
    Header,
    Footer2,
    MobileMenu,
    WalletModal,
    PlaceBidModal,
    ProgressBar,
    EditSubmissionModal,
  },
  computed: {
    formattedDate() {
      if (this.singlePost && this.singlePost.auction_end) {
        const dateTime = new Date(this.singlePost.auction_end);
        const date = dateTime.toLocaleDateString(undefined, {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        
        return { date, time };
      }
      return { date: '', time: '' };
    },
    ...mapState({
      singlePost: (state) => state.singlePost,
      getUser: (state) => state.userInfo?.id,
      reportForm: (state) => state.reportForm,
      errors: (state) => state.errors,
      message: (state) => state.message,
      mySubmissions: (state) => state.mySubmissions,
      totalSubmissionsForPost: (state) => state.totalSubmissionsForPost,
      totalAccumulation: (state) => state.totalAccumulationForPost,
      latestSubmissions: (state) => state.latestSubmissions,
      winner: (state) => state.winner,
      gamesWon: (state) => state.userGamesWon,
      eventEnded() {
        return (
          this.singlePost.winner_ticket ||
          (this.days === 0 &&
            this.hours === 0 &&
            this.minutes === 0 &&
            this.seconds === 0) ||
          new Date(this.singlePost.countdown) < new Date()
        );
      },
    }),
  },
  methods: {
    ...mapActions([
      "fetchSinglePost",
      "fetchMySubmissions",
      "saveReport",
      "deleteSubmission",
      "updateSubmission",
    ]),
    showImagePopup(submission) {
      if (submission && submission.image) {
        this.popupImageUrl = `${this.baseUrl}/${submission.image}`;
        this.isImagePopupVisible = true;
      }
    },
   
    openModal(index) {
    const submission = this.latestSubmissions[index];
    
    // Set the allLuckdrawNumbers property as an array
    this.allLuckdrawNumbers = this.getNumbers(submission.luckdrawnumbers, submission.submission_entry);

    // Check if the number of luckdrawnumbers is greater than 3
    this.showMoreButton = submission.luckdrawnumbers.length > 3;

    UIkit.modal("#luckdrawNumbersModal").show();
  },
    playnow(link) {
      window.open(link, "_blank");
    },
    onDeleteButtonClick(id) {
  if (!id) {
    console.error("Submission ID is undefined.");
    return;
  }

  // Show a confirmation popup
  const isConfirmed = window.confirm("Are you sure you want to delete this submission?");

  if (isConfirmed) {
    this.deleteSubmission(id)
      .then(() => {
        console.log("Submission deleted successfully.");
      })
      .catch((error) => {
        console.error("Error deleting submission:", error);
      });
  }
},

    openEditModal(id) {
      this.submissionToEdit = this.mySubmissions[id];

      if (!this.getUser) {
        this.$router.push("/login");
      } else {
        UIkit.switcher("#edit_submission [data-uk-switcher]").show(0);
      }
    },

    async fetchSubmissionsForPost(post_id) {
      try {
        await this.$store.dispatch("fetchMySubmissions", post_id);
      } catch (e) {
        console.error("Error fetching submissions:", e);
      }
    },

    async fetchPostDetailsWithSubmissions(post_id) {
      try {
        await this.fetchPostDetails(post_id);

        await this.fetchSubmissionsForPost(post_id);
      } catch (e) {
        console.error("Error fetching post details with submissions:", e);
      }
    },


    getNumbers(numbers, submissionCount) {
  let luckyNumber = "";

  const limit = submissionCount;

  numbers.forEach((item, i) => {
    if (i >= limit) return;
    luckyNumber = luckyNumber + item.number + " , ";
  });

  return luckyNumber.slice(0, -2);
},

alertNumbers(numbers, submissionCount) {
      let luckyNumber = "";

  const limit = submissionCount;

  numbers.forEach((item, i) => {
    if (i >= limit) return;
    luckyNumber = luckyNumber + item.number + " , ";
  });

  // return luckyNumber.slice(0, -2);
      alert(luckyNumber.slice(0, -2));
    },
    getTime(date) {
      const formattedDate = moment(date).format("HH:mm");
      return formattedDate;
    },
    submitScreenshotHandler() {
      if (!this.getUser) {
        this.$router.push("/login");
      } else {
        UIkit.switcher("#place_bid [data-uk-switcher]").show(0);
      }
    },
    navigateToUserProfile() {
      this.$router.push(`/author/${this.singlePost?.user?.id}`);
    },
  },
  mounted() {
    let post_id = this.$route.params.id;
    this.fetchSinglePost(post_id);
    this.fetchMySubmissions(post_id);
    setInterval(() => {
      if (this.singlePost.winner_ticket) {
        this.days = "";
        this.hours = "";
        this.minutes = "";
        this.seconds = "";
      } else {
        const now = moment();
        const end = moment(this.singlePost.countdown);
        const diff = end.diff(now);

        if (diff < 0) {
          this.days = "";
          this.hours = "";
          this.minutes = "";
          this.seconds = "";
        } else {
          const duration = moment.duration(diff);
          this.days = duration.days();
          this.hours = duration.hours();
          this.minutes = duration.minutes();
          this.seconds = duration.seconds();
        }
      }
    }, 1000);
  },
};
</script>
<style>
  .action-btns {
    position: absolute;
    top: 16px;
    right: 16px;
    width: fit-content;
    background-color: #ffffff87;
    border-radius: 4px;
    padding: 0px 10px;
    line-height: 10px;
  }

  .buttons-list {
    position: absolute;
    width: fit-content;
    height: auto;
    background-color: #ffffff87;
    top: 100%;
    z-index: 99999;
    padding: 5px;
    left: auto;
    border-radius: 4px;
    right: 0;
  }
  .action-btns .buttons-list {
    display: none;
  }

  .action-btns:hover .buttons-list {
    display: block;
  }
  .action-btns .buttons-list button.btn {
    padding: 3px 10px;
  }
  .submission_grid.submission-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  /* Tooltip css */
  .info-icon {
    position: relative;
    display: inline-block;
  }

  .tooltip {
    position: absolute;
    visibility: hidden;
    width: 220px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 1;
    transition: opacity 0.3s;
    padding: 10px;
    
  }

  .info-icon:hover .tooltip {
    visibility: visible;
    opacity: 1;
    overflow: auto;
  }

  .winner-profile-section {
    padding: 50px 0 0 0;
  }
  .announce-section {
    padding-bottom: 50px;
  }
  .winner-profile-wrapper {
    /* background-color: var(--color-primary) !important; */
    background: linear-gradient(to right, #f14a4a, #ed3b85);
    padding: 40px 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
  }
  .winner-profile-wrapper > div {
    width: 20%;
    padding: 0 70px;
    min-height: 135px;
  }

  .winner-profile-wrapper > div.winner-info {
    width: 33%;
  }

  .winner-profile-wrapper > div.user-address {
    width: 27%;
  }
  .winner-profile-wrapper > div.user-address .date {
    color: #ccc;
  }
  .winner-profile-wrapper > div.ticket-number {
    width: 20%;
    text-align: center;
  }
  .winner-profile-wrapper > div.price-amount {
    text-align: center;
  }

  .winner-profile-wrapper > div.user-address,
  .winner-profile-wrapper > div.price-amount,
  .winner-profile-wrapper > div.ticket-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .winner-info {
    display: flex;
    align-items: center;
    border-right: 1px solid #ccc;
  }

  .winner-info > div:first-child {
    padding-right: 25px;
  }
  .winner-info .user-img img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
  }

  .winner-profile-wrapper h5 {
    color: #fff;
    margin: 0;
    text-align: left;
  }
  .winner-profile-wrapper p {
    color: #fff;
    margin: 0;
  }

  .winner-profile-wrapper h3 {
    color: #fff;
    margin: 0;
    font-size: 30px;
  }
  .winner-info .badge {
    width: 100%;
    background-color: white;
    color: var(--color-primary);
    border-radius: 80px !important;
    margin-top: 10px;
  }

  .winner-info .badge span {
    display: inline-block;
    color: var(--color-primary);
    padding: 3px 10px;
  }
  .winner-info .badge i {
    margin-left: 5px;
  }
  .winner-info p.user-email {
    text-align: left;
    color: #d5cfcf;
  }
  .dummy-content {
    width: 150px;
  }
  .dummy-content .dummy-name,
  .dummy-content .dummy-game-status,
  .dummy-content .dummy-user-email,
  .dummy-content .dummy-result {
    border: 2px solid #ccc;
    border-radius: 10px;
    margin-bottom: 16px !important;
    margin-top: 5px;
  }
  .user-address {
    text-align: left !important;
  }
  .ticket-number,
  .user-address {
    border-right: 1px solid #ccc;
  }
  .ticket-number i {
    color: #ccc;
  }
  .user-img {
    text-align: center;
  }
  .announcement-text {
    width: 100%;
    height: 50px;
    background: linear-gradient(to right, #f14a4a, #ed3b85);
    margin-top: 30px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }

  .announcement-text span {
    display: block;
    padding-left: 10px;
    line-height: 50px;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    animation: marquee 30s linear infinite; 
  }

  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }


  @media (max-width: 767px) and (min-width: 320px) {
    .winner-profile-wrapper {
      flex-direction: column;
      padding: 15px;
      background: linear-gradient(to bottom, #f14a4a, #ed3b85);
    }
    .winner-profile-wrapper > div {
      width: 100%;
      padding: 0 15px;
      min-height: 135px;
    }
    .winner-profile-wrapper > div.ticket-number,
    .winner-profile-wrapper > div.user-address,
    .winner-profile-wrapper > div.winner-info {
      width: 100%;
    }
    .winner-info,
    .ticket-number,
    .user-address {
      border-right: 0;
      border-bottom: 1px solid #ccc;
    }
    .winner-info .user-img img {
      max-width: 80px;
      min-height: 80px;
    }
    .dummy-info .user-img img {
      width: 80px;
      height: 80px;
    }
    .winner-info > div:first-child {
      padding-right: 5px;
    }
    .winner-profile-wrapper > div.user-address {
      padding: 0 35px;
    }
  }
  @media (max-width: 390px) and (min-width: 320px) {
    .winner-info p.user-email {
      font-size: 11px;
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .winner-info {
      flex-direction: column;
    }
    .winner-content p {
      text-align: center;
    }
    .winner-content h5 {
      text-align: center;
    }
    .winner-info > div:first-child {
      padding-right: 0;
    }
    .winner-info .user-img img {
      display: block;
      margin: auto;
    }

    .badge {
      display: block;
      margin: auto;
      padding: 0;
    }
    .winner-profile-wrapper > div.ticket-number,
    .winner-profile-wrapper > div.user-address,
    .winner-profile-wrapper > div.price-amount {
      padding: 0;
      text-align: center;
    }
    .user-address p {
      text-align: center;
    }
    .user-address h5 {
      text-align: center;
    }
   }
  </style>
