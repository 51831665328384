<template>
<!-- Menu modal -->
<MobileMenu></MobileMenu>
<div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>
    <div class="uni-sign-in uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top">
        <!-- Page header -->
        <div class="uk-margin-top uk-margin-large-top@m">
            <div class="uk-container">
                <div class="uk-flex-column uk-flex-center uk-width-medium@m uk-margin-auto">
                    <form class="uk-form-stacked uk-panel uk-width-1-1" @submit.prevent="requestResetPassword">
                        <h3 class="uk-h3 uk-text-center uk-margin-large-bottom@m">
                            Forgot password?
                        </h3>
                        <div v-if="resetPasswordResponse" class="response">
                            <div v-if="resetPasswordResponse.success" class="success-message text-success">
                                {{ resetPasswordResponse.message }}
                            </div>
                            <div v-else class="error-message">
                                <p v-for="(error, key) in resetPasswordResponse.errors" :key="key" class="text-danger">
                                    {{ error[0] }}
                                </p>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label for="email-reset" class="uk-form-label">
                                Your email: <span class="uk-text-danger">*</span>
                            </label>
                            <input id="email-reset" type="email" name="email" class="uk-input" v-model="email" required />
                        </div>
                        <div class="uk-margin-top">
                            <button type="submit" class="uk-button uk-button-primary uk-button-large@m uk-width-expand">
                                Reset my password
                            </button>
                        </div>
                    </form>

                    <!-- <form class="uk-form-stacked uk-panel uk-width-1-1" @submit.prevent="requestResetPassword">
                        <h3 class="uk-h3 uk-text-center uk-margin-large-bottom@m">
                            Forgot password?
                        </h3>
                        <div v-if="successMessage" class="uk-text-meta uk-flex-middle">
                            <i class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons">check_circle</i>
                            <span class="uk-text-extra-large" style="font-size: 18px; font-weight: bold">{{ successMessage }}</span>
                        </div>
                        <div v-if="errorMessage" class="errors">
                            <p class="text-danger">
                                {{ errorMessage }}
                            </p>
                        </div>
                        <div v-if="errors && errors.email" class="errors">
                            <p class="text-danger">
                                {{ errors.email[0] }}
                            </p>
                        </div>
                        <div class="uk-margin">
                            <label for="email-reset" class="uk-form-label">
                                Your email: <span class="uk-text-danger">*</span>
                            </label>
                            <input id="email-reset" type="email" name="email" class="uk-input" v-model="email" required />
                        </div>
                        <div class="uk-margin-top">
                            <button type="submit" class="uk-button uk-button-primary uk-button-large@m uk-width-expand">
                                Reset my password
                            </button>
                        </div>
                    </form> -->

                    <p class="uk-text-small uk-text-bold uk-text-center uk-margin-large-top@m">
                        Did you remember your password?
                        <a href="#user_login" class="uk-link-line" data-uk-switcher-item="0"><span>Log in</span></a>.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";

import {
    mapState,
    mapActions
} from "vuex";

export default {
    name: "ForgotPassword",
    components: {
        Header,
        Footer,
        MobileMenu,
    },

    data() {
        return {
            email: '',
            message: '',
            errors: []
        };
    },
    computed: {
        ...mapState({
            errors: (state) => state.errors,
        }),
        resetPasswordResponse() {
            return this.$store.state.resetPasswordResponse;
        },
    },
    methods: {
        ...mapActions(["forgotPassword"]),
        async requestResetPassword() {
            try {
                await this.forgotPassword({
                    email: this.email,
                });
                this.errors = [];
            } catch (error) {
                this.errors = error.errors;
            }
        },
    },
};
</script>
