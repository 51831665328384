<template>
    <footer class="uni-footer uk-section-small uk-section-medium@m uk-border-top">
            <div class="uk-container uk-container-expand">
                <div class="uk-grid-2xlarge uk-grid-divider uk-child-width-1-1 uk-grid" data-uk-grid>
                    <!-- <div>
                        <div class="uk-panel uk-section@m">
                            <div class="uk-flex-center uk-flex-middle uk-text-center">
                                <div class="uk-panel uk-flex-column uk-flex-middle">
                                    <h1 class="uk-h3 uk-h1@m uk-margin-small">Join Our Telegram</h1>
                                    <p class="uk-text-lead uk-text-muted uk-margin-remove">Be the first to know our lastest Events for bunus grab!</p>
                                    <button style="margin-top: 15px!important;" type="button" class="uk-button uk-button-medium uk-button-info uk-width-1-1" data-uk-toggle="target: #place_bid" onclick="UIkit.switcher('#place_bid [data-uk-switcher]').show(0)"><i class="uk-icon-small brand-twitter"></i>Join Telegram</button>
                                    <p style="margin-top: 15px!important;" class="uk-text-small uk-text-muted uk-margin-large-top@m">No worries we don't spam! just one post weekly.</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div>
                        <div class="uk-grid-small uk-grid-medium@m uk-child-width-auto uk-flex-center uk-flex-between@m uk-flex-middle uk-text-center uk-text-left@m uk-grid" data-uk-grid>
                            <!-- <div class="uk-flex-last@m">
                                <ul class="uni-social-media uk-subnav uk-subnav-small uk-text-muted">
                                    <li>
                                        <a aria-label="twitter" href="https://twitter.com/unistudioco"><i class="uk-icon-small brand-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a aria-label="facebook" href="https://facebook.com/unistudioco"><i class="uk-icon-small brand-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a aria-label="snapchat" href="https://snapchat.com/unistudioco"><i class="uk-icon-small brand-snapchat"></i></a>
                                    </li>
                                    <li>
                                        <a aria-label="youtube-play" href="https://youtube.com/unistudioco"><i class="uk-icon-small brand-youtube-play"></i></a>
                                    </li>
                                    <li>
                                        <a aria-label="reddit-alien" href="https://reddit.com/unistudioco"><i class="uk-icon-small brand-reddit-alien"></i></a>
                                    </li>
                                    <li>
                                        <a aria-label="quora" href="https://quora.com/unistudioco"><i class="uk-icon-small brand-quora"></i></a>
                                    </li>
                                </ul>
                            </div> -->
                            <div>
                                <div class="uk-grid-small uk-grid-medium@m uk-flex-center uk-flex-between@m uk-flex-middle uk-grid" data-uk-grid>
                                    <!-- <div class="uk-flex-last@m">
                                        <ul class="uk-subnav uk-subnav-small uk-subnav-medium@m uk-text-bold">
                                            <li><a href="terms.html">Terms</a></li>
                                            <li><a href="privacy.html">Privacy policy</a></li>
                                        </ul>
                                    </div> -->
                                    <div>
                                        <p class="uk-text-muted">© Islot, All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>
<script>
export default {
    name: 'Footer2',
}
</script>