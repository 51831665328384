import axios from "axios";

window.axios = axios;

// Local Server
// axios.defaults.baseURL = "http://localhost:8000/";

// Live Server
axios.defaults.baseURL = "https://admin.islot.in/";

// Add an Axios interceptor to set the Authorization header
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("sanctum_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
