<template>
   <div id="connect_wallet" class="uk-modal" data-uk-modal>
            <div class="uk-modal-dialog uk-card-xsmall uk-card-small@m uk-padding-medium-horizontal uk-margin-auto-vertical uk-width-medium@m uk-radius-large">
                <button aria-label="Close" class="uk-modal-close-outside" type="button" data-uk-close></button>
                <div class="uk-panel uk-flex-center uk-flex-middle uk-height-1-1">
                    <ul hidden class="uk-subnav uk-subnav-pill" data-uk-switcher="animation: uk-animation-fade; duration: 150;">
                        <li><a href="#switcher_wallet">Connect wallet</a></li>
                        <li><a href="#wallet_feedback">Feedback</a></li>
                    </ul>
                    <ul class="uk-switcher">
                        <li>
                            <div class="uk-panel">
                                <h3 class="uk-h4 uk-h3@m">Sign in with your wallet</h3>
                                <div data-uk-margin="margin: uk-margin-xsmall-top">
                                    <a href="#wallet" class="uk-button uk-button-medium uk-button-default uk-button-outline uk-width-expand" data-uk-switcher-item="next">
                                        <span class="uk-position-center-left uk-position-small"> <img class="uk-icon-small" src="/assets/images/other/torus.svg" alt="torus" data-uk-svg loading="lazy"> </span>
                                        <b>Torus</b>
                                    </a>
                                    <a href="#wallet" class="uk-button uk-button-medium uk-button-default uk-button-outline uk-width-expand" data-uk-switcher-item="next">
                                        <span class="uk-position-center-left uk-position-small"> <img class="uk-icon-small" src="/assets/images/other/metamask.svg" alt="metamask" data-uk-svg loading="lazy"> </span>
                                        <b>Metamask</b>
                                    </a>
                                    <a href="#wallet" class="uk-button uk-button-medium uk-button-default uk-button-outline uk-width-expand" data-uk-switcher-item="next">
                                        <span class="uk-position-center-left uk-position-small"> <img class="uk-icon-small" src="/assets/images/other/coinbase.svg" alt="coinbase" data-uk-svg loading="lazy"> </span>
                                        <b>Coinbase</b>
                                    </a>
                                    <a href="#wallet" class="uk-button uk-button-medium uk-button-default uk-button-outline uk-width-expand" data-uk-switcher-item="next">
                                        <span class="uk-position-center-left uk-position-small"> <img class="uk-icon-small" src="/assets/images/other/walletconnect.svg" alt="walletconnect" data-uk-svg loading="lazy"> </span>
                                        <b>Mobile Wallet</b>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="uk-panel uk-text-center uk-flex-column uk-flex-middle">
                                <i class="uk-icon-large uk-text-success material-icons">check_circle</i>
                                <p class="uk-text-xlarge">Your purchase has been completed!</p>
                                <button type="button" class="uk-button uk-button-small uk-button-default uk-button-outline uk-modal-close">Close</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: 'WalletModal',
    }
</script>


