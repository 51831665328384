export default {
    userInfo: {},
    userProfileInfo: {},
    logo: '',
    userBalance:'',
        winningAmount: 0,
    registrationForm: {
        image: '',
        cover: '',
        name: '',
        email: '',
        password: ''
    },
    resetPasswordResponse: null,
    loginForm:{
        email:'',
        password:''
    },
    RestPassword: null,
    passwordResetSuccess: false,
    passwordResetError: null,
    reportForm:{
        post_id: '',
        submission_id: '',
        report_against: '',
        description: '',
    },
    loggedIn:false,
    errors: {},
    message: "",
    posts:[],
    currentPage: 1,
    lastPage: 0,
    myPosts:[],
    
    liveAuctions:[],
    users: [],
    providers: [],
    authors: [],
    bannerData: {},
    mySubmissions:[],
    submissionOnMyPosts:[],
    mySubmissionsList:[],
    singlePost:{},
    totalSubmissionsForPost:0,
    latestSubmissions:[],
    winner:[],
    gamesWon: 0,
    activities:[],
    authorDetails:{},
    postsCount:{},
    authorPosts:[],
    AuthorPrizesGiven:[],
    AuthorSubmissions: null,
    createPostForm:{
        image: '',
        title: '',
        description:'',
        post_link:'',
        amount_for_ticket:'',
        target:'',
        sponsor_amount:'',
        auction_end:'',
        status: 1
    },
    submitScreenshotForm:{
       image:'',
       credit:'',
       post_id:'' 
    },
    postDrawForm:{
        post_id:'',
        winner_ticket:'',
        action:'manual'
    }
}