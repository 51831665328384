<template>
  <div id="edit_submission" class="uk-modal" data-uk-modal>
    <div class="loader" v-if="loading">
      <div class="circle-loader"></div>
    </div>

    <div
      class="uk-modal-dialog uk-card-xsmall uk-card-small@m uk-padding-medium-horizontal uk-margin-auto-vertical uk-width-medium@m uk-radius-large"
    >
      <button
        aria-label="Close"
        class="uk-modal-close-outside"
        type="button"
        data-uk-close
      ></button>
      <div class="uk-panel uk-flex-center uk-flex-middle uk-height-1-1">
        <ul
          hidden
          class="uk-subnav uk-subnav-pill"
          data-uk-switcher="animation: uk-animation-fade; duration: 150;"
        >
          <li><a href="#switcher_wallet">Connect wallet</a></li>
          <li><a href="#switcher_place_bid">Place a bid</a></li>
          <li><a href="#switcher_feedback">Feedback</a></li>
        </ul>
        <ul class="uk-switcher">
          <li>
            <div class="uk-panel">
              <h3 class="uk-h4 uk-h3@m">Submit the screenshots</h3>
              <div data-uk-margin="margin: uk-margin-xsmall-top">
                <form @submit.prevent="submitForm()">
                  <div class="form-group">
                    <label for="amount">Winning Amount</label>
                    <input
                      @change="updateValue($event)"
                      v-model="amountValue"
                      id="amount"
                      ref="amountInput"
                      type="number"
                      class="form-control py-10 h-max-content"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <img
                      :src="previewImageUrl || $baseUrl + imageUrl"
                      alt="Screenshot"
                      style="height: 150px; width: 110px"
                    />
                    <label for="screenshot">Screenshot</label>
                    <input
                      type="file"
                      @change="onFileChange($event)"
                      id="screenshot"
                      class="form-control py-10 h-max-content"
                      accept="image/*"
                    />
                  </div>

                  <button
                    type="submit"
                    class="uk-button uk-button-medium uk-button-primary w-100 mt-20"
                    :disabled="isButtonDisabled"
                  >
                    Update Submission
                  </button>

                  <p class="mt-1">
                    Submission time will not be returned if your winning doesn't
                    qualify
                  </p>
                </form>
              </div>
            </div>
          </li>

          <!-- Other switcher items if needed -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import { useRouter } from "vue-router";

export default {
  name: "EditSubmissionModal",
  data() {
    return {
      loading: false,
      isButtonDisabled: false,
      previewImageUrl: "",
    };
  },
  props: {
    data: "Object",
  },

  computed: {
    ...mapState({
      submitScreenshotForm: (state) => state.submitScreenshotForm,
      winningAmount: "getWinningAmount",
    }),
    amountValue() {
      return this.data ? this.data.amount : "";
    },
    imageUrl() {
      return this.data ? this.data.image : "";
    },
  },

  methods: {
    onFileChange(event) {
      this.submitScreenshotForm.image = event.target.files[0];
      if (!this.submitScreenshotForm.image.type.startsWith("image/")) {
        alert("Please select an image file.");
        event.target.value = "";
        return;
      }
      // Set the preview image URL when a new image is selected
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImageUrl = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },

    updateValue(e) {
      this.data.amount = e.target.value;
    },
    async submitForm() {
      this.loading = true;
      this.isButtonDisabled = true;
      const router = useRouter();

      try {
        const submissionForm = {
          user_id: this.data.user_id,
          amount: this.$refs.amountInput.value,
          status: this.data.status,
          image: this.submitScreenshotForm.image,
          id: this.data.id,
        };

        await this.$store.dispatch("updateSubmission", {
          submissionForm: submissionForm,
        });

        if (router) {
          await router.push({
            name: "post-detail",
            params: { id: this.data.id },
          });
          console.log("hello from router");
        } else {
          console.error("Router instance is not available.");
        }
      } finally {
        this.loading = false;
        this.isButtonDisabled = true;
      }
    },
  },

};
</script>
<style>
.circle-loader {
  border: 8px solid #948b8b; 
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite; 
  margin: 0 auto;
  z-index: 100;
  margin-top: 90px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
