<template>
  <div class="progress-bar-1">
    <div class="progress" :style="{ width: percentage + '%' }">
      {{ percentage >100 ? 100 : percentage }}%
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
.progress-bar-1 {
  width: 100%;
  height: 21px;
  border-radius: 50px;
  background: #eaeaea;
  overflow: hidden;
}

.progress {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  min-height: 100%;
  overflow: hidden;
  border-radius: 50px !important;
  background: linear-gradient(
    90deg,
    #ff4980 30.4%,
    #ff4a68 45.73%,
    #ff4e29 77.89%
  );
  max-width: 100%;
  
}
</style>
