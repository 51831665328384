export default {
  setUserInfo(state, payload) {
    state.userInfo = payload;
  },
  SET_PASSWORD_RESET_RESPONSE(state, response) {
    state.resetPasswordResponse = response;
  },
  setLoginForm(state, payload) {
    state.loginForm = payload;
  },

  SET_PASSWORD_RESET_ERROR(state, payload) {
    state.passwordResetSuccess = false;
    state.passwordResetError = payload;
  },
  setUserProfileInfo(state, payload) {
    state.userProfileInfo = {
      id: payload.id,
      image: "",
      cover: "",
      name: payload.name,
      email: payload.email,
      password: "",
      new_password: "",
    };
  },

  newFormData: (state, formData) => {
    state.formData = [formData, ...state.formData];
  },
  setWinningAmount(state, amount) {
    state.winningAmount = amount;
  },
  setSubmission(state, submission) {
    state.fetchedSubmission = submission;
  },
  setPostsCount(state, payload) {
    state.postsCount = payload;
  },
  setWinnerInformation(state, winnerInfo) {
    state.winnerInformation = winnerInfo; 
  },
  setUserBalance(state, payload) {
    state.userBalance = payload;
  },
  setSubmissionOnMyPosts(state, { payload, currentPage }) {
    if (currentPage > 1)
      state.submissionOnMyPosts = state.submissionOnMyPosts.concat(payload);
    else state.submissionOnMyPosts = payload;
  },
  setMySubmissionsList(state, { payload, currentPage }) {
    if (currentPage > 1)
      state.mySubmissionsList = state.mySubmissionsList.concat(payload);
    else state.mySubmissionsList = payload;
  },
  setLoggedIn(state, payload) {
    state.loggedIn = payload;
  },
  setPosts(state, { payload, currentPage }) {
    console.log('Payload:', payload);
    console.log('Current Page:', currentPage);

    if (currentPage > 1) state.posts = state.posts.concat(payload);
    else state.posts = payload;
  },
  setLiveAuction(state, payload) {
    state.liveAuctions = payload;
  },
  sortPostsNewestFirst(state) {
    state.posts = state.posts.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  sortPostsOldestFirst(state) {
    state.posts = state.posts.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
  },
  setCurrentPage(state, payload) {
    state.currentPage = payload;
  },
  setLastPage(state, payload) {
    state.lastPage = payload;
  },
  setLogo(state, payload) {
    state.logo = payload;
  },
  setMyPosts(state, { payload, currentPage }) {
    if (currentPage > 1) state.myPosts = state.myPosts.concat(payload);
    else state.myPosts = payload;
  },
  sortMyPostsNewestFirst(state) {
    state.myPosts = state.myPosts.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  sortMyPostsOldestFirst(state) {
    state.myPosts = state.myPosts.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
  },
  setMySubmissions(state, payload) {
    state.mySubmissions = payload;
  },
  setSinglePost(state, payload) {
    state.singlePost = payload;
  },
  setUsers: (state, payload) => {
    state.users = payload;
  },
  setProviders: (state, payload) => {
    state.providers = payload;
  },
  setWinner(state, winnerData) {
    state.winner = winnerData;
  },
  userGamesWon(state, gamesData) {
    state.userGamesWon = gamesData;
  },
  

  setAuthors: (state, { payload, currentPage }) => {
    if (currentPage > 1) state.authors = state.authors.concat(payload);
    else state.authors = payload;
  },
  setbanner: (state, payload) => {
    state.bannerData = payload;
  },
  setTotalSubmissionsForPost(state, payload) {
    state.totalSubmissionsForPost = payload;
  },
  setTotalAccumulationForPost(state, payload) {
    state.totalAccumulationForPost = payload;
  },
  setLatestSubmissions(state, payload) {
    state.latestSubmissions = payload;
  },
  setActivities(state, { payload, currentPage }) {
    if (currentPage > 1) state.activities = state.activities.concat(payload);
    else state.activities = payload;
  },
  setAuthorDetails(state, payload) {
    state.authorDetails = payload;
  },
  setAuthorPosts(state, payload) {
    state.authorPosts = payload;
  },
  setAuthorPrizesGiven(state, payload) {
    state.AuthorPrizesGiven = payload;
  },
  setAuthorSubmissions(state, payload) {
    state.AuthorSubmissions = payload;
  },
  setFollowers: (state, followers) => {
    state.followers = followers;
  },
  setFollowing(state, following) {
    state.following = following;
  },

  resetCreatePostForm(state) {
    state.createPostForm = {
      image: "",
      title: "",
      description: "",
      amount_for_ticket: "",
      target: "",
      post_link: "",
      sponsor_amount: "",
      status: 1,
    };
  },
  setErrors(state, payload) {
    state.errors = payload;
  },
  setMessage(state, payload) {
    state.message = payload;
  },

  setReportForm(state, payload) {
    state.reportForm = payload;
  },
  setPostDrawForm(state, payload) {
    state.postDrawForm = payload;
  },
};
