<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>

  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>

    <div
      class="uni-contact uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top"
    >
      <!-- Page header -->
      <header class="uni-page-header">
        <div class="uk-container">
          <h1 class="heading uk-h3 uk-h1@m uk-text-center">Contact</h1>
        </div>
      </header>
      <div class="uk-margin-top uk-margin-large-top@m">
        <div class="uk-container">
          <div class="uk-grid-small uk-grid-large@m uk-grid" data-uk-grid>
            <div class="uk-width-expand@m">
              <div
                class="uk-card uk-card-border uk-radius uk-card-xsmall uk-card-small@m add-data"
              >
                <form
                  action="?"
                  @submit.prevent="onSend"
                  class="uk-grid uk-grid-xsmall uk-child-width-1-1"
                  data-uk-grid
                >
                  <div class="uk-width-1-2@m">
                    <div class="uk-form-controls">
                      <input
                        class="uk-input uk-form-medium uk-text-bold"
                        type="text"
                        v-model="name"
                        placeholder="Name"
                        required
                      />
                    </div>
                  </div>
                  <div class="uk-width-1-2@m">
                    <div class="uk-form-controls">
                      <input
                        class="uk-input uk-form-medium uk-text-bold"
                        type="email"
                        v-model="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>

                  <div class="uk-form-controls">
                    <input
                      class="uk-input uk-form-medium uk-text-bold"
                      type="text"
                      v-model="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div class="uk-form-controls">
                    <textarea
                      class="uk-textarea uk-padding uk-height-xsmall uk-radius-large uk-text-bold"
                      rows="5"
                      v-model="message"
                      placeholder="Type your message"
                      required
                    ></textarea>
                  </div>
                  <div class="uk-form-controls">
                    <label for="attachment" class="uk-form-label">Attachment: (optional)</label>
                    <input
                      class="uk-input uk-form-medium uk-text-bold"
                      type="file"
                      ref="fileInput"
                      @change="handleFileChange"
                    />
                  </div>
                  <div class="uk-form-controls">
                    <button
                      type="submit"
                      class="uk-button uk-button-primary uk-width-1-1"
                    >
                      Send message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-height-2xsmall" hidden></div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "ContactView",
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      file: null,
    };
  },
  components: {
    Header,
    Footer,
    MobileMenu,
  },
  methods: {
    ...mapActions(["addFormData"]),
    handleFileChange(event) {
      this.file = event.target.files[0]; // Assign the selected file to the 'file' data property
    },
    async onSend() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("subject", this.subject);
      formData.append("message", this.message);
      if (this.file) {
    formData.append("file", this.file);
  }

      try {
        const response = await axios.post(
          "https://admin.islot.in/api/contact",
          formData
        );
        console.log("Message sent successfully!");
        alert("Form data received and email sent to admin");

        // Clear the input fields
        this.name = "";
        this.email = "";
        this.subject = "";
        this.message = "";
        this.file = null;

        this.$router.push({ name: "Contact" });
      } catch (error) {
        console.error("Failed to send the message.", error);
      }
    },
  },
};
</script>
