<template>
<div id="uni_mobile_menu" class="uni-mobile-menu uk-offcanvas" data-uk-offcanvas="mode: push; overlay: true; flip: true; selPanel: .uk-offcanvas-bar-panel;">
    <div class="uk-offcanvas-bar-panel uk-panel dark:uk-background-gray-100">
        <div class="uni-mobile-menu-wrap uk-flex-column uk-flex-between" data-uk-height-viewport="offset-bottom: true;">
            <div class="uni-mobile-menu-content">
                <header class="uk-card uk-card-2xsmall uk-flex-middle uk-flex-between">
                    <div class="uk-flex-1">
                        <button aria-label="Close Menu" class="uk-offcanvas-close uk-button uk-button-small uk-button-icon uk-button-default uk-button-outline uk-radius-circle" type="button">
                            <i class="uk-icon-small" data-feather="arrow-left"></i>
                        </button>
                    </div>
                    <div>
                        <h5 class="uk-h5 uk-margin-remove">Navigation</h5>
                    </div>
                    <div class="uk-flex-1"></div>
                </header>
                <hr class="uk-margin-remove" />
                <div class="uk-card uk-card-small">
                    <div class="uk-panel">
                        <ul class="uk-nav uk-nav-default">
                            <li>
                                <a class="py-2" href="/"><i class="uk-icon-xsmall" data-feather="compass"></i>
                                    Home</a>
                            </li>
                            <li>
                                <router-link to="/explore"><i class="uk-icon-xsmall" data-feather="compass"></i>Popular Events</router-link>
                            </li>
                            <!-- <li v-if="loggedIn && userInfo.role !== 'user'">
                                <router-link to="/my-events">
                                    <i class="uk-icon-xsmall" data-feather="compass"></i>
                                    My Events
                                </router-link>
                            </li> -->
                            <li>
                                <router-link to="/contact"><i class="uk-icon-xsmall" data-feather="compass"></i>Contact Us</router-link>
                            </li>
                            <!-- <li v-if="loggedIn" >
                                        <router-link class="py-2" to="/activity"><i class="uk-icon-xsmall" data-feather="compass"></i> Activity</router-link>
                                    </li> -->

                            <!-- <li v-if="loggedIn">
                                <router-link v-if="loggedIn && userInfo.role !== 'user'" to="/create-post" class="uk-button uk-button-small uk-button-darkgrey uk-radius-large uk-margin-xsmall-left uk-margin-xsmall-right ">
                                    <span>Create Event</span>
                                </router-link>
                            </li> -->

                            <!-- <li v-if="loggedIn" >
                                        <router-link class="py-2" to="/submissions"><i class="uk-icon-xsmall" data-feather="compass"></i> Submission Received</router-link>
                                    </li>
                                    -->
                                    <li>
                                        <router-link class="py-2" to="/my-submissions"><i class="uk-icon-xsmall" data-feather="compass"></i>My Submission</router-link>
                                    </li> 
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapGetters
} from "vuex";
export default {
    name: "MobileMenu",
    computed: {
        ...mapState({
            loggedIn: (state) => state.loggedIn,
        }),
        ...mapGetters({
            userInfo: "getUserInfo",
            getLoginStatus: "getLoginStatus",
        }),
    },
};
</script>
