<template>
<!-- Menu modal -->
<MobileMenu></MobileMenu>
<div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>
    <div class="uni-sign-in uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top">
        <!-- Page header -->
        <div class="uk-margin-top uk-margin-large-top@m">
            <div class="uk-container">
                <div class="uk-flex-column uk-flex-center uk-width-medium@m uk-margin-auto">
                    <form @submit.prevent="updatePassword">
                        <h3 class="uk-h3 uk-text-center uk-margin-large-bottom@m">
                            Reset password.
                        </h3>
                        <div v-if="errors.length" class="errors">
                            <p v-for="(error, index) in errors" :key="index" class="text-danger">
                                {{ error }}
                            </p>
                        </div>
                        <div v-if="message" class="uk-text-meta uk-flex-middle">
                            <i class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons">check_circle</i>
                            <span class="uk-text-extra-large" style="font-size: 18px; font-weight: bold">{{ message }}</span>
                        </div>
                        <div class="uk-margin">
                            <label for="password-login-page" class="uk-form-label">New password: <span class="uk-text-danger">*</span></label>
                            <input id="password-login-page" type="password" v-model="newPassword" class="uk-input" required />
                        </div>
                        <div class="uk-margin">
                            <label for="confirm-password" class="uk-form-label">Confirm Password:
                                <span class="uk-text-danger">*</span></label>
                            <input id="confirm-password" type="password" v-model="confirmPassword" class="uk-input" required />
                        </div>
                        <div class="uk-margin-medium">
                            <button class="uk-button uk-button-primary uk-button-large@m uk-width-expand" type="submit">
                                Update Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";

import {
    mapState,
    mapActions
} from "vuex";

export default {
    name: "ResetPassword",
    components: {
        Header,
        Footer,
        MobileMenu,
    },

    data() {
        return {
            newPassword: "",
            confirmPassword: "",
            errors: [],
            message: "",
        };
    },
    computed: {
        ...mapState({
            errors: (state) => state.errors,
        }),
    },
    methods: {
        ...mapActions(["resetPassword"]),
        async updatePassword() {
            try {
                const params = new URLSearchParams(window.location.search);
                const email = params.get("email");
                const token = params.get("token");

                await this.resetPassword({
                    email,
                    token,
                    password: this.newPassword,
                    password_confirmation: this.confirmPassword,
                });
                // Password reset successful, show success message or perform additional actions
                this.message = "Password changed successfully";
                this.errors = []; // Clear any previous errors
            } catch (error) {
                // Handle the error, show error message or perform additional actions
                this.errors = error.response.data.errors;
                this.message = ""; // Clear the success message in case of an error
            }
        },
    },
};
</script>
