<template>
    <div>
      <div ref="recaptchaContainer"></div>
      <!-- Add other form fields and buttons here -->
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      this.renderRecaptcha();
    },
    methods: {
      renderRecaptcha() {
        grecaptcha.ready(() => {
          grecaptcha.render(this.$refs.recaptchaContainer, {
            sitekey: '6Leu0zEmAAAAANEeTbg1YRGPpDKfUYBSbj8FDYLE',
            callback: (response) => {
              // Store the reCAPTCHA response for validation
              this.$emit('recaptcha-verified', response);
            },
          });
        });
      },
    },
  };
  </script>
  