<template>
<!-- Menu modal -->
<MobileMenu></MobileMenu>
<div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>

    <div class="uni-sign-up uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top">
        <div class="uk-margin-top uk-margin-large-top@m">
            <div class="uk-container">
                <div v-if="errors.length" class="errors">
                    <p v-for="(error, index) in errors" :key="index" class="text-danger">
                        {{ error }}
                    </p>
                </div>
                <div v-if="message" class="uk-text-meta uk-flex-middle">
                    <i class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons">check_circle</i>
                    <span class="uk-text-extra-large" style="font-size: 18px; font-weight: bold">{{ message }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<Footer></Footer>
</template>

    
<script>
import {
    mapState,
    mapActions
} from "vuex";
import axios from "axios";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";

export default {
    name: "VerifyEmail",
    components: {
        Header,
        Footer,
        MobileMenu,

    },
    computed: {
        ...mapState(["errors"]),
    },
    data() {
        return {
            message: "",
        };
    },
    methods: {
    verifyEmail() {
      const token = this.$route.query.token;

      if (token) {
        axios.post('https://admin.islot.in/api/verify-email', { token })
          .then(response => {
         
            console.log(response.data.message);
          })
          .catch(error => {
         
            console.error(error.response.data.error);
          });
      } else {
       
        console.error('Invalid token');
      }
    }
  },
  mounted() {
  
    this.verifyEmail();
  }
};
</script>
