<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>

  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>

    <div
      class="uni-explore uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top"
    >
      <!-- Page header -->
      <header class="uni-page-header mb-2">
        <div class="uk-container">
          <h1 class="heading uk-h3 uk-h1@m uk-text-center">Explore</h1>
        </div>
      </header>
      <div class="">
        <div class="uk-container">
          <!-- artworks listing + desktop filters -->
          <div class="uni-explore-filter uk-visible">
            <div
              class="uk-grid-2xsmall uk-grid-2xlarge@m uk-flex-between uk-flex-middle uk-grid"
              data-uk-grid=""
            >
              <div
                class="uk-width-expand uk-visible@m uk-width-auto@m uk-flex-1@m uk-first-column"
              >
                <!-- <div class="uk-width-1-1 uk-width-auto@m uk-form-custom" data-uk-form-custom="target: > * > span:first-child">
                                <select>
                                    <option value="1">Live auction</option>
                                    <option value="2">Hot bids</option>
                                    <option value="3">Buy Now</option>
                                    <option value="4">Has Offers</option>
                                    <option value="5">Open for bids</option>
                                </select>
                                <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                    <span>Live auction</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down icon uk-icon-2xsmall">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                </button>
                            </div> -->
              </div>
              <div class="uk-width-auto uk-width-auto@m mobile_w_100">
                <div
                  class="uk-grid-2xsmall uk-visible uk-grid mobile_w_100"
                  data-uk-grid=""
                >
                  <div class="mobile_w_100">
                    <div class="uk-inline mobile_w_100">
                      <span class="uk-form-icon uk-form-medium">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-search icon uk-icon-small"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </span>
                      <input
                        @keyup="filter()"
                        v-model="searchedValue"
                        class="uk-input uk-form-medium"
                        type="text"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="uk-width-1-1 uk-width-auto@m uk-flex-right@m uk-flex-1@m uk-first-column">
                            <div class="uk-width-1-1 uk-width-auto@m uk-form-custom" data-uk-form-custom="target: > * > span:first-child">
                                <select @change="sortData()" v-model="sort">
                                    <option value="new">Sort by: Newest</option>
                                    <option value="old">Sort by: Oldest</option>
                                </select>
                                <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                    <span>Sort by: Newest</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down icon uk-icon-2xsmall">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                </button>
                            </div>
                        </div> -->
            </div>
          </div>
          <div class="uk-margin-top">
            <div class="uk-container">
              <div class="uk-panel">
                <div class="uk-grid uk-grid-large@m" data-uk-grid>
                  <div class="uk-width-expand@m">
                    <div
                      id="explore-list-container"
                      class="uk-grid-2xsmall uk-grid-small@m uk-grid-match uk-child-width-1-2 uk-child-width-1-3@m uk-grid"
                      data-uk-grid
                    >
                      <div
                        v-for="(post, index) in posts"
                        :key="index"
                        class="home_posts"
                      >
                        <div
                          class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle"
                        >
                          <div class="uni-artwork-header uk-panel">
                            <div
                              class="uk-grid uk-grid-xsmall uk-flex-middle uk-flex-between uk-text-small uk-text-bold"
                              data-uk-grid
                            >
                              <div>
                                <div class="uk-text-bold">
                                  <div
                                    class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid"
                                    data-uk-grid
                                  >
                                    <div class="uk-width-auto">
                                      <div class="uni-user-avatar uk-panel">
                                        <div
                                          class="uk-panel uk-overflow-hidden uk-radius-circle"
                                        >
                                          <canvas
                                            width="32"
                                            height="32"
                                          ></canvas>
                                          <img
                                            v-if="post.user && post.user.image"
                                            :src="$baseUrl + post.user.image"
                                            alt="TheSalvare"
                                            class="uni-user-avatar-image uk-cover uk-radius-circle"
                                            data-uk-cover
                                          />
                                          <router-link
                                            aria-label="TheSalvare"
                                            :to="'post-detail/' + post.id"
                                            class="uk-position-cover"
                                          ></router-link>
                                        </div>
                                        <i
                                          v-if="
                                            post.user && post.user.is_verified
                                          "
                                          class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-3xsmall"
                                          >check_circle</i
                                        >
                                      </div>
                                    </div>
                                    
                                    <div class="uk-visible@m">
                                      <router-link
                                        v-if="post.user && post.user.name"
                                        class="uni-user-link uk-link-reset"
                                        :to="'post-detail/' + post.id"
                                        >{{ post.user.name }}</router-link
                                      >
                                    </div>
                                    <p
                                  v-if="
                                    (post.ticket_per_user <=
                                      totalSubmissionsForPost ||
                                      post.winner_ticket ||
                                      (post.event_type !== 'trigger_amount' &&
                                        new Date(post.auction_end) <
                                          new Date()) ||
                                      (post.event_type === 'trigger_amount' &&
                                        totalAccumulation >= post.target)) &&
                                    post.updated_at !== null &&
                                    new Date(post.updated_at) >
                                      new Date(post.created_at)
                                  "
                                  class="closed-message"
                                >
                                  Closed
                                </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="d-flex justify-content-end mt-3">
                            <span class="uk-text-bold uk-text-gradient me-1"
                              >{{ post.amount_for_ticket }} ₹</span
                            >
                            <span
                              class="uk-text-bold uk-text-black"
                              style="margin-left: 0.5rem"
                              >Per Entry</span
                            >
                          </div>
                          <div
                            class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m"
                            style="margin: 0px 0"
                          >
                            <div class="uk-panel uk-image-middle">
                              <img
                                :src="$baseUrl + post.image"
                                :style="{ height: '200px', width: '300px' }"
                                alt=""
                                class="uk-radius object_cover"
                              />
                              <router-link
                                :to="'post-detail/' + post.id"
                                class="uk-position-cover"
                                aria-label="Metaverse Game"
                              ></router-link>
                            </div>
                          </div>

                          <div class="uni-artwork-content uk-panel mt-2">
                            <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                              <router-link
                                class="uk-link-reset"
                                :to="'post-detail/' + post.id"
                                >{{ post.title }}</router-link
                              >
                              <!-- <span class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m">1 / 1</span> -->
                            </h2>

                            <!-- <div class="d-flex justify-content-between mt-3">
                        <span
                          class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                          >Jackpot Prize</span
                        >
                        <span class="uk-text-bold uk-text-gradient"
                          ><h3>{{ post.sponsor_amount }} ₹</h3></span
                        >
                      </div> -->
                            <div class="d-flex justify-content-between mt-3">
                              <span
                                class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                                style="font-size: larger"
                              >
                                Jackpot Prize
                              </span>

                              <span class="uk-text-bold uk-text-gradient">
                                <h3 style="font-size: larger">
                                  {{ post.sponsor_amount }} ₹
                                </h3>
                              </span>
                            </div>

                            <!-- <span class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block">Highest Winning amount</span>
                                        <span class="uk-text-bold uk-text-gradient">{{ post.highest_bid?post.highest_bid:0 }} ₹</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="uni-btn uk-margin-large-top uk-margin-xlarge-top@m uk-flex-center"
                    >
                      <button
                        v-if="currentPage < lastPage"
                        @click="loadMore(++currentPage, searchedValue)"
                        class="uk-button uk-button-small uk-button-medium@m uk-button-default uk-button-outline uk-width-medium@m"
                        type="button"
                        data-load-more-container="#explore-list-container"
                        data-toggle-loading
                      >
                        <span class="not-loading1">Load more</span>
                        <span class="loading uk-margin-remove uk-flex-middle"
                          ><span
                            class="uk-flex-middle uk-margin-2xsmall-right"
                            data-uk-spinner="ratio: .5"
                          ></span
                          >Loading...</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "IndexView",
  // name: 'HomeView',
  data() {
    return {
      sort: "new",
      searchedValue: "",
      posts: [],
    };
  },
  components: {
    Header,
    Footer,
    MobileMenu,
  },
  computed: {
    ...mapState({
      myPosts: (state) => state.myPosts,
      posts: (state) => state.posts,
      currentPage: (state) => state.currentPage,
      lastPage: (state) => state.lastPage,
      errors: (state) => state.errors,
    }),
    posts() {
      return this.$store.state.posts;
    },
  },
  methods: {
    ...mapActions(["fetchPosts"]),
    sortData() {
      if (this.sort == "new") {
        this.$store.commit("sortPostsNewestFirst");
      } else {
        this.$store.commit("sortPostsOldestFirst");
      }
    },
    loadMore(currentPage, searchedValue) {
      console.log("Load More - Current Page:", currentPage);
      this.fetchPosts({
        currentPage: currentPage || 1,
        searchedValue: searchedValue,
      });
    },

    filter() {
      this.fetchPosts({
        currentPage: this.currentPage,
        searchedValue: this.searchedValue,
      });
    },
  },
  mounted() {
    let searchedValue = "";
    if (this.$route.query.q != "") searchedValue = this.$route.query.q;
    this.fetchPosts({
      currentPage: this.currentPage,
      searchedValue: searchedValue,
    });
    console.log("Posts:", this.posts);
  },
};
</script>
<style>

.closed-message {
  color: red;
  position: absolute;
  top: 0;
  right: 0;
}

.closed-message {
  font-weight: bold;
  background-color: #f9f9f9;
  padding: 5px 10px;
  border-radius: 5px;
}

</style>