<template>
<!-- Menu modal -->
<MobileMenu></MobileMenu>

<div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>
    <div class="uni-authors uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top">
        <!-- Page header -->
        <header class="uni-page-header">
            <div class="uk-container">
                <h1 class="heading uk-h3 uk-h1@m uk-text-center">Authors</h1>
            </div>
        </header>
        <div class="uk-margin-top uk-margin-large-top@m">
            <div class="uk-container">
                <!-- authors listing -->
                <div class="uk-section-small uk-section-large@m uk-padding-remove-bottom">
                    <div id="authors-list-container" class="uk-grid-2xsmall uk-grid-medium@m uk-grid-match uk-child-width-1-2 uk-child-width-1-4@m uk-grid" data-uk-grid>
                        <div v-for="(author, index) in authors" :key="index">
                            <div class="uk-panel uk-overflow-hidden uk-card uk-card-border uk-radius-large uk-transition-toggle">
                                <div class="uk-card-media-top uk-panel uk-overflow-hidden">
                                    <canvas class="uk-visible@m" height="120"></canvas>
                                    <canvas class="uk-hidden@m" height="140"></canvas>
                                    <img v-if="author.cover" :src="$baseUrl + author.cover" alt="Archiver" class="uk-cover" data-uk-cover loading="lazy" />
                                    <router-link :to="'/author/' + author.id" class="uk-position-cover" aria-label="Archiver"></router-link>
                                    <!-- <div class="uk-position-top-right uk-position-xsmall uk-transition-fade">
                                        <button type="button" class="uk-button uk-button-2xsmall uk-button-icon uk-button-danger uk-button-invert uk-radius-circle uk-box-shadow-small" data-uk-tooltip="Report user" data-uk-toggle="target: #send_report">
                                            <i class="uk-icon-xsmall material-icons">flag</i>
                                        </button>
                                    </div> -->
                                </div>
                                <div class="uk-card-xsmall uk-padding-remove-top" style="margin-top: -32px">
                                    <div class="uk-grid uk-flex-center uk-flex-left@m" data-uk-grid>
                                        <div>
                                            <div class="uk-panel uk-padding-2xsmall uk-radius-circle uk-background-white dark:uk-background-gray-100">
                                                <div class="uk-panel uk-overflow-hidden uk-radius-circle">
                                                    <canvas width="60" height="60"></canvas>
                                                    <img v-if="author.image" :src="$baseUrl + author.image" alt="Archiver" class="uni-user-avatar-image uk-cover uk-radius-circle" data-uk-cover loading="lazy" />
                                                    <router-link aria-label="Archiver" :to="'/author/' + author.id" class="uk-position-cover"></router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="uk-h6 uk-margin-2xsmall-top uk-margin-xsmall-top@m uk-margin-remove-bottom uk-text-center uk-text-left@m">
                                        <router-link :to="'/author/' + author.id" class="uk-link">{{ author.name }}</router-link>
                                    </h3>
                                    <!-- <span class="uk-text-meta uk-flex-middle uk-visible@m">
                                        <span>0xfaa...239c</span>
                                        <a href="#copy_code" class="uk-icon-2xsmall uk-margin-xsmall-left material-icons">content_copy</a>
                                    </span> -->
                                    <!-- <p class="uk-text-small uk-text-muted uk-visible@m">
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit..
                                    </p> -->
                                    <hr class="uk-margin-small uk-visible@m" />
                                    <div class="uk-grid-2xsmall uk-flex-middle uk-flex-between uk-text-center uk-text-left@m uk-child-width-auto@m uk-grid" data-uk-grid>
                                        <div>
                                            <div class="uk-text-lead@m uk-text-bold dark:uk-text-white">
                                                {{ author.followers_count }}
                                            </div>
                                            <div class="uk-text-meta uk-visible@m">Followers</div>
                                        </div>
                                        <div>
                                            <button v-if="author.id != userInfo.id && !author.is_following" role="button" class="uk-button uk-button-primary uk-button-small uk-button-medium@m uk-width-xsmall@m" data-toggle-active data-not-active-class="uk-button-primary uk-box-shadow-small" data-active-class="uk-button-default uk-button-outline"
                                             @click="followUser(author)">
                                                <span class="text-white">Follow</span>

                                            </button>
                                            <button v-else-if="author.id != userInfo.id" role="button" class="uk-button uk-button-primary uk-button-small uk-button-medium@m uk-width-xsmall@m" data-toggle-active data-not-active-class="uk-button-primary uk-box-shadow-small" data-active-class="uk-button-default uk-button-outline"
                                             @click="unfollowUser(author)">
                                                <span class="text-white">Following</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uni-btn uk-margin-large-top uk-margin-xlarge-top@m uk-flex-center">
                        <button v-if="currentPage < lastPage" @click="loadMore(++currentPage)" class="uk-button uk-button-small uk-button-medium@m uk-button-default uk-button-outline uk-width-medium@m" type="button" data-load-more-container="#explore-list-container" data-load-more="explore-ajax.html?page=%page%" data-toggle-loading>
                                            <!-- <span class="no-more-loading">No more</span> -->
                                            <span class="not-loading">Load more</span>
                                            <span class="loading uk-margin-remove uk-flex-middle"><span class="uk-flex-middle uk-margin-2xsmall-right" data-uk-spinner="ratio: .5"></span>Loading...</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import axios from 'axios';

import {
    mapState,
    mapActions
} from "vuex";
export default {
    name: "AuthorsView",
    data() {
        return {
            isAuthenticated: false,
        };
    },
    components: {
        Header,
        Footer,
        MobileMenu,
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo,
            authors: (state) => state.authors,
            currentPage: (state) => state.currentPage,
            lastPage: (state) => state.lastPage,
            errors: (state) => state.errors,
        }),
    },
    methods: {
        ...mapActions(["fetchAuthors", "followUser", "unfollowUser"]),
        loadMore(currentPage){
            this.fetchAuthors({
                currentPage: currentPage,
            });
        }
    },
    mounted() {
        this.fetchAuthors({
            currentPage: 1,
        });
    },
};
</script>
