import router from "@/router";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  fetchUserInfo: async ({ commit, dispatch, state }) => {
    try {
      if (state.loggedIn || localStorage.getItem("slot_login")) {
        const response = await axios.get(`api/user`);
        commit("setUserInfo", response.data);
        commit("setUserProfileInfo", response.data);
        if (response.data.status === 2) {
          dispatch("logout");
        }
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  //User logout
  logout: async ({ commit }) => {
    try {
      localStorage.removeItem("sanctum_token");
      localStorage.removeItem("slot_login");
      const response = await axios.post(`api/logout`);
      if (response.data.status == true) {
        commit("setUserInfo", {});
        commit("setMessage", response.data.message);
        commit("setLoggedIn", false);

        // router.push("api/login");
        router.push("/login");
      } else {
        const errors = Object.values(response.data.data)
          .flat()
          .map((message) => message);
        commit("setErrors", errors);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    } finally {
      location.reload();
    }
  },

  forgotPassword: async ({ commit }, payload) => {
    try {
      const response = await axios.post(`api/forgot-password`, payload);
      commit("SET_PASSWORD_RESET_RESPONSE", response.data);
    } catch (error) {
      commit("SET_PASSWORD_RESET_RESPONSE", error.response.data);
    }
  },

  resetPassword: async ({ commit }, payload) => {
    try {
      const response = await axios.post(`api/reset-password`, payload);

      commit("SET_PASSWORD_RESET_SUCCESS", response.data);
    } catch (error) {
      
      commit("SET_PASSWORD_RESET_ERROR", error.response.data);
    }
  },

  //Get user wallet balance
  fetchUserBalance: async ({ commit, state }) => {
    try {
      // Check if the current route is not the login page
      if (state.loggedIn) {
        const response = await axios.get(`api/user/balance`);
        if (response.data.status) {
          commit("setUserBalance", response.data.balance);
        } else {
          commit("setUserBalance", 0);
        }
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e.message);
    }
  },

  fetchPosts: async ({ commit }, { currentPage, searchedValue }) => {
    try {
      // Check if the current route is not the login page
      let url = `api/posts?page=${currentPage}&status=1`;  // Include status parameter
  
      if (
        searchedValue !== undefined &&
        searchedValue !== null &&
        searchedValue !== ""
      ) {
        url += `&search=${searchedValue}`;
      }
  
      const response = await axios.get(url);
      console.log('API Response:', response);
  
      // Check if the response object is defined and contains data
      if (response && response.data && response.data.data) {
        commit("setPosts", { payload: response.data.data.data, currentPage });
        commit("setCurrentPage", response.data.data.current_page);
        commit("setLastPage", response.data.data.last_page);
        commit("setLogo", response.data.logo);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (e) {
      commit("setErrors", e.response ? e.response.data : e.message);
    }
  },

  fetchWinnerInformation({ commit }, postId) {
    console.log('Post ID:', postId);  
    const url = `/api/posts/${postId}/winner`;
    console.log('Request URL:', url);  
    
    return axios.get(url)  // Added 'return' here to return the promise
      .then(response => {
        commit('setWinnerInformation', response.data);
        console.log('API Response:', response);
        return response;  // Return the response data
      })
      .catch(error => {
        console.error('Error fetching winner information:', error);
        throw error;  // Throw the error to be caught by the caller
      });
  },
  
  
  

  // Get all my posts list
  fetchMyPosts: async ({ commit }, { currentPage, searchedValue }) => {

console.log("hello from fetch my post?");
    
    try {
      let url = `api/my-posts?page=${currentPage}`;
      if (
        searchedValue != "undefined" &&
        searchedValue != null &&
        searchedValue != ""
      ) {
        url += `&search=${searchedValue}`;
      }
      const response = await axios.get(url);
      commit("setMyPosts", { payload: response.data.data.data, currentPage });
      commit("setCurrentPage", response.data.data.current_page);
      commit("setLastPage", response.data.data.last_page);
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  // Get all live auction posts
  fetchLiveAuction: async ({ commit }) => {
    try {
      const response = await axios.get(`api/live-auction`);
      commit("setLiveAuction", response.data.data);
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  fetchUsers: async ({ commit, rootState }) => {
    try {
      // Check if the current route is not the login page
      // if (rootState.route.path !== '/login') {
      const response = await axios.get("/api/users");
      if (response.data.status) {
        commit("setUsers", response.data.data.users);
        commit("setProviders", response.data.data.providers);
        commit("setbanner", response.data.data.banner);
      }
      // }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  // Get all author list
  fetchAuthors: async ({ commit }, { currentPage }) => {
    try {
      const response = await axios.get("/api/authors?page=" + currentPage);
      if (response.data.status) {
        commit("setAuthors", { payload: response.data.data.data, currentPage });
        commit("setCurrentPage", response.data.data.current_page);
        commit("setLastPage", response.data.data.last_page);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  // Get all authordetails
  fetchAuthor: async ({ commit }, author_id) => {
    try {
      const response = await axios.get("/api/author/" + author_id);
      
      if (response.data.status) {
        commit("setAuthorDetails", response.data.user);
        commit("setAuthorPosts", response.data.posts);
        commit("setAuthorSubmissions", response.data.submissions); 
        commit("setAuthorPrizesGiven", response.data.prizes);

      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },



  // Get all fetch submission on my posts list
  fetchSubmissionOnMyPosts: async ({ commit }, { post_id, currentPage }) => {
    let url = "api/submissions-on-my-posts";
    if (post_id != "" && post_id != null) url += "/" + post_id;
    try {
      const response = await axios.get(url + "?page=" + currentPage);
      commit("setSubmissionOnMyPosts", {
        payload: response.data.data.data,
        currentPage,
      });
      commit("setCurrentPage", response.data.data.current_page);
      commit("setLastPage", response.data.data.last_page);
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  // Get all my submission filter post is optional
  fetchMySubmissionsList: async ({ commit }, { post_id, currentPage }) => {
    let url = "api/my-submissions-list";
    if (post_id != "" && post_id != null) url += "/" + post_id;
    try {
      const response = await axios.get(url);
      commit("setMySubmissionsList", {
        payload: response.data.data.data,
        currentPage,
      });
      commit("setCurrentPage", response.data.data.current_page);
      commit("setLastPage", response.data.data.last_page);
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  // Get my submissions for post detail page list
  fetchMySubmissions: async ({ commit }, post_id) => {
    try {
      const response = await axios.get(`api/post/mySubmissions/` + post_id);
      commit("setMySubmissions", response.data.data);
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },


  deleteSubmission: async ({ commit }, id) => {
    try {
      await axios.delete(`api/submissions/${id}`);
      commit("removeDeletedSubmission", id);
  
      // Reload the page after a short delay (e.g., 500 milliseconds)
      setTimeout(() => {
        window.location.reload();
      });
    } catch (e) {
      // Handle any errors
      const errorMessage = e.response?.data?.message || "An error occurred while deleting the submission.";
      commit("setErrors", errorMessage);
    }
  },
  

updateSubmission: async ({ commit }, { submissionForm }) => {
  console.log(submissionForm);
  try {
    let formData = new FormData();
    formData.append("user_id", submissionForm.user_id);
    formData.append("amount", submissionForm.amount);
    formData.append("status", submissionForm.status);

    if (submissionForm.image) {
      formData.append("image", submissionForm.image);
    }

    const response = await axios.post(
      `api/submissions/${submissionForm.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-HTTP-Method-Override": "PUT"
        },
      }
    );

    if (response.data.success) {
      alert(response.data.message);
      location.reload();
      console.log("hello");
    } else {
      console.log(response);
      alert(response?.data?.message);
    }
  } catch (e) {
    console.error("Error updating submission:", e);
  }
},


  
  fetchActivites: async ({ commit }, { currentPage, searchedValue }) => {
    try {
      let url = `api/activities` + "?page=" + currentPage;
      if (
        searchedValue != "undefined" &&
        searchedValue != null &&
        searchedValue != ""
      ) {
        url += `&search=${searchedValue}`;
      }
      const response = await axios.get(url);
      commit("setActivities", {
        payload: response.data.data.data,
        currentPage,
      });
      commit("setCurrentPage", response.data.data.current_page);
      commit("setLastPage", response.data.data.last_page);
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  // Save Submission/screenshot against post
  saveSubmission: async ({ commit }, submissionForm) => {
    try {
      let formData = new FormData();
      formData.append("credit", submissionForm.credit);
      formData.append("post_id", submissionForm.post_id);
      formData.append("image", submissionForm.image);
      const response = await axios.post(`api/post/submission`, formData);
      if (response.data.status || response.data.message) {
        location.reload();
      } else {
        console.log(response);
        alert(response?.data?.message);
        //commit("setErrors",response?.data);
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  // Save Report/complaint against post
  saveReport: async ({ commit }, reportForm) => {
    try {
      let formData = new FormData();
      formData.append("description", reportForm.description);
      formData.append("post_id", reportForm.post_id);
      formData.append("submission_id", reportForm.submission_id);
      formData.append("report_against", reportForm.report_against);
      const response = await axios.post(`api/save-report`, formData);
      if (response.data.status) {
        alert(response.data.message);
        location.reload();
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  // Annouce Draw of the post
  annouceDraw: async ({ commit }, postDrawForm) => {
    try {
      let formData = new FormData();
      formData.append("post_id", postDrawForm.post_id);
      formData.append("action", postDrawForm.action);
      formData.append("winner_ticket", postDrawForm.winner_ticket);
      const response = await axios.post(`api/postDraw`, formData);
      if (response.data.status) {
        alert(response.data.message);
        location.reload();
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  // Cancel Report/complaint
  cancelReport: async ({ commit }) => {
    commit("setReportForm", {});
  },
  // Cancel draw of the post
  cancelDraw: async ({ commit }) => {
    commit("setPostDrawForm", {
      post_id: "",
      winner_ticket: "",
      action: "manual",
    });
  },
  // Get single post
  fetchSinglePost: async ({ commit, dispatch, state }, id) => {
    console.log('Fetching post with id:', id);
    console.log('Current state:', state);
    let isUserLoggedIn = state?.loggedIn || localStorage.getItem("slot_login");
    let apiRoute = "post-unauth";
    if (isUserLoggedIn) apiRoute = "post";
    try {
      const response = await axios.get(`api/${apiRoute}/` + id);
      commit("setSinglePost", response.data.data.post);
      commit("setLatestSubmissions", response.data.data.latestSubmissions);
      commit("setTotalSubmissionsForPost", response.data.data.totalSubmissions);
      commit("setTotalAccumulationForPost", response.data.data.totalAccumulation);
      if (response.data.data.winner) {
        commit("setWinner", response.data.data.winner);
      }
      commit("userGamesWon", response.data.data.userGamesWon);
      
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },

  async registerUser({ commit }, formData) {
    try {
      const response = await axios.post("/api/register", formData);
      const message = response.data.message;
      commit("SET_REGISTRATION_MESSAGE", message);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateProfile: async ({ commit }, payload) => {
    let formData = new FormData();

    formData.append("image", payload.image);
    formData.append("cover", payload.cover);
    formData.append("name", payload.name);
    formData.append("old_password", payload.password);
    formData.append("new_password", payload.new_password);
    const response = await axios.post(
      `api/update-profile/` + payload.id,
      formData
    );
    if (response.data.status == true) {
      alert(response.data.message);
      location.reload();
      commit("setUserInfo", response.data.data);
      commit("setErrors", []);
    } else {
      const errors = Object.values(response.data.data)
        .flat()
        .map((message) => message);
      commit("setErrors", errors);
    }
  },

  //follow user
  followUser: async ({ commit }, author) => {
    const response = await axios.post(`api/users/${author.id}/follow`);
    if (response.data.status == true) {
      commit("setMessage", response.data.message);
      commit("setErrors", []);
      author.is_following = true;
    } else {
      const errors = Object.values(response.data.data)
        .flat()
        .map((message) => message);
      commit("setErrors", errors);
    }
  },
  //unfollow user
  unfollowUser: async ({ commit }, author) => {
    const response = await axios.delete(`api/users/${author.id}/unfollow`);
    if (response.data.status == true) {
      commit("setMessage", response.data.message);
      commit("setErrors", []);
      author.is_following = false;
    } else {
      const errors = Object.values(response.data.data)
        .flat()
        .map((message) => message);
      commit("setErrors", errors);
    }
  },

  // Get the logged-in user's followers
  getFollowers: async ({ commit }) => {
    try {
      const response = await axios.get("/api/followers");
      commit("setFollowers", response.data);
    } catch (error) {
      commit("setErrors", [error.message]);
    }
  },

  login: async ({ commit }, payload) => {
    try {
      const response = await axios.post(`api/login`, payload);
      if (response.data.status === true) {
        commit("setUserInfo", response.data.data);
        localStorage.setItem("sanctum_token", response.data.token);
        commit("setErrors", {});
        commit("setMessage", response.data.message);
        commit("setLoggedIn", true);
        // Cookies.set('loggedIn', true, { expires: 7 })
        localStorage.setItem("slot_login", 1);
        // window.location.href = "/";
        router.push({ name: "home" });
      } else {
        const error = response.data.message;
        const specificErrors = response.data.data;
        commit("setErrors", specificErrors);
        commit("setMessage", error);
      }
    } catch (error) {
      // Handle error
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Show error message from the server
        const serverError = error.response.data.message;
        commit("setErrors", {});
        commit("setMessage", serverError);
      } else {
        // Show a generic error message
        commit("setErrors", {});
        commit(
          "setMessage",
          "An error occurred during login. Please try again later."
        );
      }
    }
  },

  // Create New Post
  createPost: async ({ commit }, payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (key === "description" && value.quill && value.quill.root) {
        formData.append(key, value.quill.root.innerHTML);
      } else {
        formData.append(key, value);
      }
    });

    const response = await axios.post(`api/post/create`, formData);
    if (response.data.status == true) {
      commit("setErrors", []);
      commit("setMessage", response.data.message);
      commit("resetCreatePostForm");

      const postId = response.data.postId;
      router.push(`/post-detail/${postId}`);
    } else {
      const errors = Object.values(response.data.data)
        .flat()
        .map((message) => message);
      commit("setErrors", errors);
      commit("setMessage", "");
    }
  },

  // change the verification of the submission
  submissionApproval: async ({ commit }, { submission_id, verification }) => {
    try {
      const response = await axios.get(
        `api/submission/${verification}/${submission_id}`
      );
      if (response.data.status) {
        alert(response.data.message);
        location.reload();
      }
    } catch (e) {
      commit("setErrors", e.response.data);
    }
  },
  async addFormData({ commit }, formData) {
    try {
      // Send form data to the API endpoint
      const response = await axios.post("YOUR_API_ENDPOINT", formData);
      commit("newFormData", response.data);
      console.log("Message sent successfully!");

      // Retrieve admin's email from the backend
      const adminEmailResponse = await axios.get("YOUR_ADMIN_EMAIL_ENDPOINT");
      const adminEmail = adminEmailResponse.data.email;

      // Send email to admin with the form data
      const emailData = {
        to: adminEmail,
        subject: "New Form Submission",
        body: `Name: ${formData.get("name")}\nEmail: ${formData.get(
          "email"
        )}\nSubject: ${formData.get("subject")}\nMessage: ${formData.get(
          "message"
        )}`,
      };
      await axios.post("YOUR_EMAIL_API_ENDPOINT", emailData);
      console.log("Email sent to admin successfully!");
    } catch (error) {
      console.error("Failed to send message.", error);
    }
  },
};
