import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

import store from "./store";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/custom.css";

import "./axios";
import moment from "moment";

const app = createApp(App);
app.use(store);
app.use(router);

app.config.globalProperties.$formatDate = function (dateString) {
  return moment(dateString).format("DD MMM YYYY");
};

app.config.globalProperties.$activityDate = function (dateString) {
  const date = moment(dateString);
  return date.fromNow();
};

app.config.globalProperties.$baseUrl = "https://admin.islot.in/";
// app.config.globalProperties.$baseUrl = "http://localhost:8000/";

app.mount("#app");
