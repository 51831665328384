<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>

  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>

    <!-- Hero start -->
    <div class="uk-panel uk-overflow-hidden dark:uk-background-white-5">
      <div
        class="uk-position-cover dark:uk-hidden"
        style="
          background-image: linear-gradient(
            to left,
            var(--color-primary),
            var(--color-secondary),
            var(--color-tertiary),
            var(--color-info)
          );
          opacity: 0.1;
        "
      ></div>
      <img
        class="uk-cover"
        v-if="isMobile"
        src="/assets/images/mobile_banner.png"
        alt="Hero Image"
        data-uk-cover=""
      />
      <img
        class="uk-cover"
        v-if="!isMobile"
        src="/assets/images/desktop_banner.png"
        alt="Hero Image"
        data-uk-cover=""
      />

      <div class="uk-container uk-container-large banner_root_cont">
        <div class="uk-panel">
          <div
            class="uk-grid uk-grid-xsmall uk-grid-2xlarge@m uk-grid-match uk-flex-middle"
            data-uk-grid
          >
            <div class="uk-width-1-2@s">
              <div class="uk-text-left uk-flex-left uk-flex-middle">
                <div
                  class="uk-panel uk-section uk-section-xlarge@m uk-position-z-index"
                >
                  <h2
                    class="uk-h3 uk-h2@m uk-margin-small uk-margin@m text_white"
                  >
                    {{ bannerData.banner_heading }}
                    <!-- Buy, Sell, Discover Exclusive <br class="uk-visible@m">
                                            Digital collectibles -->
                  </h2>
                  <p class="uk-text-2xlarge@m uk-width-xlarge@m">
                    {{ bannerData.banner_description }}
                  </p>
                  <a
                    v-if="!loggedIn"
                    :href="bannerData.banner_link"
                    target="_blank"
                    class="uk-button uk-button-gradient uk-button-large@m uk-radius-large uk-margin-top uk-margin-medium-top@m"
                    >{{ bannerData.banner_button_text }}</a
                  >
                  <a
                    v-else
                    href="/explore"
                    class="uk-button uk-button-gradient uk-button-large@m uk-radius-large uk-margin-top uk-margin-medium-top@m"
                    >Explore</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Hero end -->

    <!-- how it works start -->
    <div
      class="uni-features uk-section uk-section-large@m uk-padding-small-bottom@m uk-panel uk-overflow-hidden"
    >
      <div class="uk-container">
        <div class="uk-panel">
          <div
            class="uni-features-listing align_items_stretch step_items_cont uk-grid-2xsmall uk-grid-small@m uk-grid-match uk-child-width-1-3 uk-child-width-1-3@m uk-grid"
            data-uk-grid="masonry: true;"
          >
            <div class="step_item">
              <div
                class="uni-feature two uk-card uk-card-xsmall uk-card-medium@m uk-card-border uk-radius uk-radius-large@m"
              >
                <div
                  class="uk-grid-2xsmall uk-grid-small@m uk-child-width-1-1 uk-grid"
                  data-uk-grid
                >
                  <div class="justify_center">
                    <div
                      class="uni-feature-icon uk-panel uk-inline-block uk-radius-circle uk-background-gradient"
                    >
                      <i
                        class="material-icons uk-icon-small uk-icon-medium@m uk-text-white"
                        >account_balance_wallet</i
                      >
                    </div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-panel">
                      <h3 class="title uk-h6 uk-h5@m uk-margin-remove">
                        1<span class="uk-visible@m">.</span>
                        <span class="uk-hidden@s"><br /></span>
                        Register
                      </h3>

                      <p
                        class="description uk-text-large@m uk-text-muted uk-visible@m"
                      >
                        Join as a member to join Lucky Draws from your favorite
                        platforms
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="step_item">
              <div
                class="uni-feature two uk-card uk-card-xsmall uk-card-medium@m uk-card-border uk-radius uk-radius-large@m"
              >
                <div
                  class="uk-grid-2xsmall uk-grid-small@m uk-child-width-1-1 uk-grid"
                  data-uk-grid
                >
                  <div class="justify_center">
                    <div
                      class="uni-feature-icon uk-panel uk-inline-block uk-radius-circle uk-background-gradient"
                    >
                      <i
                        class="material-icons uk-icon-small uk-icon-medium@m uk-text-white"
                        >data_saver_on</i
                      >
                    </div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-panel">
                      <h3 class="title uk-h6 uk-h5@m uk-margin-remove">
                        2<span class="uk-visible@m">.</span>
                        <span class="uk-hidden@s"><br /></span> Participate
                        Lucky Draws
                      </h3>
                      <p
                        class="description uk-text-large@m uk-text-muted uk-visible@m"
                      >
                        play in your favorite brands and submit your winnings
                        amount to earn lucky draw entries
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="step_item">
              <div
                class="uni-feature two uk-card uk-card-xsmall uk-card-medium@m uk-card-border uk-radius uk-radius-large@m"
              >
                <div
                  class="uk-grid-2xsmall uk-grid-small@m uk-child-width-1-1 uk-grid"
                  data-uk-grid
                >
                  <div class="justify_center">
                    <div
                      class="uni-feature-icon uk-panel uk-inline-block uk-radius-circle uk-background-gradient"
                    >
                      <i
                        class="material-icons uk-icon-small uk-icon-medium@m uk-text-white"
                        >savings</i
                      >
                    </div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-panel">
                      <h3 class="title uk-h6 uk-h5@m uk-margin-remove">
                        3<span class="uk-visible@m">.</span
                        ><span class="uk-hidden@s"><br /></span>Win jackpots
                      </h3>
                      <p
                        class="description uk-text-large@m uk-text-muted uk-visible@m"
                      >
                        Earn lots of cash reward from the jackpot pool
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- how it works end -->

    <!-- live auctions start -->
    <div
      class="uni-creations uk-section-small uk-section-medium@m uk-panel uk-overflow-hidden swiper-parent"
    >
      <div class="uk-container">
        <header
          class="uk-grid-xsmall uk-flex-between uk-flex-middle uk-grid"
          data-uk-grid
        >
          <div class="uk-panel">
            <h2 class="uk-h4 uk-h3@m">Popular Events 🔥</h2>
          </div>
          <div class="uk-panel">
            <router-link
              to="/explore"
              class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-visible@m"
              >See all</router-link
            >
            <router-link
              to="/explore"
              class="uk-button uk-button-2xsmall uk-button-default uk-button-outline uk-button-icon uk-radius-circle uk-hidden@m"
            >
              <i class="uk-icon-small material-icons">chevron_right</i>
            </router-link>
          </div>
        </header>
        <div class="uk-panel uk-margin-top uk-margin-medium-top@m">
          <div
            class="swiper-container swiper-match"
            data-swiper="
            items: 2;
             gap: 8; 
             freeMode: true;"
            data-swiper-m="
            items: 4; 
            gap: 24;"
          >
            <div class="swiper-wrapper uk-grid-match">
              <div
                class="swiper-slide"
                v-for="(post, index) in liveAuctions"
                :key="index"
              >
                <div>
                  <template v-if="post.user.status == 2">
                    <div
                      class="uni-user-link-suspended uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle"
                    >
                      <div class="uni-artwork-header uk-panel">
                        <div
                          class="uk-grid uk-grid-xsmall uk-flex-middle uk-flex-between uk-text-small uk-text-bold"
                          data-uk-grid
                        >
                          <div>
                            <div class="uk-text-bold">
                              <div
                                style="display: flex; align-items: center"
                                class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid"
                                data-uk-grid
                              >
                                <div class="uk-width-auto">
                                  <div class="uni-user-avatar uk-panel">
                                    <div
                                      class="uk-panel uk-overflow-hidden uk-radius-circle"
                                    >
                                      <canvas width="32" height="32"></canvas>
                                      <img
                                        v-if="post.user && post.user.image"
                                        :src="$baseUrl + post.user.image"
                                        alt="TheSalvare"
                                        class="uni-user-avatar-image uk-cover uk-radius-circle"
                                        data-uk-cover
                                      />
                                    </div>
                                    <i
                                      v-if="post.user && post.user.is_verified"
                                      class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-3xsmall"
                                      >check_circle</i
                                    >
                                  </div>
                                </div>
                                <div class="uk-visible@m">
                                  <span
                                    v-if="post.user && post.user.name"
                                    :class="{
                                      'uni-user-link': post.user.status !== 2,
                                      'uni-user-link-suspended':
                                        post.user.status === 2,
                                    }"
                                  >
                                    {{ post.user.name }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-end mt-3">
                        <span class="uk-text-bold uk-text-gradient me-1"
                          >{{ post.amount_for_ticket }} ₹</span
                        >
                        <span
                          class="uk-text-bold uk-text-black"
                          style="margin-left: 0.5rem"
                          >Per Entry</span
                        >
                      </div>
                      <div
                        class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m"
                        style="margin: 0px 0"
                      >
                        <!-- Existing code for featured image -->
                        <div class="uk-panel uk-image-middle">
                          <img
                            :src="$baseUrl + post.image"
                            :style="{ height: '200px', width: '300px' }"
                            alt=""
                            class="uk-radius object_cover"
                          />
                          <router-link
                            :to="'post-detail/' + post.id"
                            class="uk-position-cover"
                            aria-label="Metaverse Game"
                          ></router-link>
                        </div>
                      </div>
                      <div class="uni-artwork-content uk-panel mt-2">
                        <h2
                          class="uk-h6 uk-h5@m uk-margin-2xsmall"
                          id="uni-user-suspended"
                        >
                          User Suspended
                        </h2>
                        <div class="d-flex justify-content-between mt-3">
                          <span
                            class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                            style="font-size: larger"
                            >Jackpot Prize</span
                          >
                          <span class="uk-text-bold uk-text-gradient">
                            <h3 style="font-size: larger">
                              {{ post.sponsor_amount }} ₹
                            </h3>
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div
                      class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle"
                      style="max-height: 450px"
                    >
                      <div class="uni-artwork-header uk-panel">
                        <div
                          class="uk-grid uk-grid-xsmall uk-flex-middle uk-flex-between uk-text-small uk-text-bold"
                          data-uk-grid
                        >
                          <div>
                            <div class="uk-text-bold">
                              <div
                                style="display: flex; align-items: center"
                                class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid"
                                data-uk-grid
                              >
                                <div class="uk-width-auto">
                                  <div class="uni-user-avatar uk-panel">
                                    <div
                                      class="uk-panel uk-overflow-hidden uk-radius-circle"
                                    >
                                      <canvas width="32" height="32"></canvas>
                                      <img
                                        v-if="post.user && post.user.image"
                                        :src="$baseUrl + post.user.image"
                                        alt="TheSalvare"
                                        class="uni-user-avatar-image uk-cover uk-radius-circle"
                                        data-uk-cover
                                      />
                                      <router-link
                                        aria-label="TheSalvare"
                                        :to="'post-detail/' + post.id"
                                        class="uk-position-cover"
                                      ></router-link>
                                    </div>
                                    <i
                                      v-if="post.user && post.user.is_verified"
                                      class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-3xsmall"
                                      >check_circle</i
                                    >
                                  </div>
                                </div>
                                <div class="uk-visible@m">
                                  <router-link
                                    v-if="post.user && post.user.name"
                                    class="uni-user-link uk-link-reset"
                                    :to="'post-detail/' + post.id"
                                    >{{ post.user.name }}</router-link
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-end mt-3">
                        <span class="uk-text-bold uk-text-gradient me-1"
                          >{{ post.amount_for_ticket }} ₹</span
                        >
                        <span
                          class="uk-text-bold uk-text-black"
                          style="margin-left: 0.5rem"
                          >Per Entry</span
                        >
                      </div>
                      <div
                        class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m"
                        style="margin: 0px 0"
                      >
                        <div class="uk-panel uk-image-middle">
                          <img
                            :src="$baseUrl + post.image"
                            :style="{ height: '200px', width: '300px' }"
                            alt=""
                            class="uk-radius object_cover"
                          />
                          <router-link
                            :to="'post-detail/' + post.id"
                            class="uk-position-cover"
                            aria-label="Metaverse Game"
                          ></router-link>
                        </div>
                        <div
                          class="uk-label uk-label-primary uk-background-gradient uk-position-bottom-left uk-font-mono"
                        >
                          <div
                            v-if="post.hours || post.minutes"
                            class="uk-grid-gallery uk-child-width-auto uk-text-bold uk-grid"
                          >
                            <div class="uk-countdown-hours">
                              {{ post.hours }} H
                            </div>
                            <div class="sep">:</div>
                            <div class="uk-countdown-minutes">
                              {{ post.minutes }} M
                            </div>
                          </div>
                          <div
                            v-else-if="post.event_type == 'trigger_amount'"
                            class="uk-grid-gallery uk-child-width-auto uk-text-bold uk-grid"
                          >
                            <div class="uk-countdown-hours">
                              Target {{ post.target || 0 }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="uni-artwork-content uk-panel mt-2">
                        <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                          <router-link
                            class="uk-link-reset"
                            :to="'post-detail/' + post.id"
                            >{{ trimIfTextLong(post.title) }}</router-link
                          >
                          <!-- <span class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m">1 / 1</span> -->
                        </h2>
                        <div class="d-flex justify-content-between mt-3">
                          <div
                            class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                            style="font-size: larger"
                          >
                            Jackpot Prize
                            <div
                            class="info-icon"
                          >
                            <i class="fa fa-info-circle info-icon" aria-hidden="true"></i>
                            <div class="tooltip">
                              <span class="tooltiptext">The jackpot prize is the winning prize given to one lucky winner
                                 based on their ticket number, 
                                 with the result determined by a third-party guarantee.</span>
                            </div>
                          </div>
                        </div>
                          
                          <span class="uk-text-bold uk-text-gradient">
                            <h3
                              class="uk-text-default uk-text-large@m uk-text-nowrap"
                            >
                              {{ post.sponsor_amount }} ₹
                            </h3>
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <!-- Navigation -->
          <!-- <a aria-label="Next" href="#next" class="swiper-next uk-button uk-button-small uk-button-icon uk-border uk-radius-circle uk-position-center-right uk-position-z-index uk-box-shadow-small dark:uk-background-gray-100"><i class="uk-icon-small" data-feather="chevron-right"></i></a>
                <a aria-label="Prev" href="#prev" class="swiper-prev uk-button uk-button-small uk-button-icon uk-border uk-radius-circle uk-position-center-left uk-position-z-index uk-box-shadow-small dark:uk-background-gray-100"><i class="uk-icon-small" data-feather="chevron-left"></i></a> -->
        </div>
      </div>
    </div>

    <!-- live auctions end -->

    <!-- top artists start -->
    <div
      class="uni-artists uk-section-small uk-section-medium@m uk-panel uk-overflow-hidden"
    >
      <div class="uk-container">
        <header
          class="uk-grid-xsmall uk-flex-between uk-flex-middle uk-grid"
          data-uk-grid="masonry: true;"
        >
          <div class="uk-panel">
            <h2 class="uk-h4 uk-h3@m">Organizer 🌟</h2>
          </div>

          <!-- <div class="uk-panel">
                    <router-link to="/all-authors" class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-visible@m">See all</router-link>
                    <router-link to="/all-authors" class="uk-button uk-button-2xsmall uk-button-default uk-button-outline uk-button-icon uk-radius-circle uk-hidden@m">
                        <i class="uk-icon-small material-icons">chevron_right</i>
                    </router-link>
                </div> -->
        </header>
        <div class="uk-panel uk-margin-top uk-margin-medium-top@m">
          <div
            class="uk-grid-2xsmall uk-grid-small@m uk-grid-match uk-child-width-1-2 uk-child-width-1-6@m uk-grid"
            data-uk-grid
          >
            <div v-for="(user, index) in users" :key="index">
              <div
                class="uni-artist uk-panel uk-overflow-hidden uk-card uk-card-2xsmall uk-card-border uk-radius uk-radius-large@m uk-text-center uk-box-shadow-hover-medium"
              >
                <div
                  class="uni-artist-cover uk-card-media-top uk-panel uk-overflow-hidden uk-radius"
                >
                  <canvas class="uk-visible@m" height="120"></canvas>
                  <canvas class="uk-hidden@m" height="140"></canvas>
                  <img
                    v-if="user.cover"
                    :src="$baseUrl + user.cover"
                    alt="Archiver"
                    class="uk-cover"
                    data-uk-cover
                    loading="lazy"
                  />
                  <router-link
                    :to="'/author/' + user.id"
                    class="uk-position-cover"
                    aria-label="Archiver"
                  ></router-link>
                </div>
                <div class="uni-artist-content uk-padding-remove-top">
                  <div
                    class="uni-artist-avatar uk-grid uk-flex-center"
                    data-uk-grid
                  >
                    <div>
                      <div
                        class="uk-panel uk-padding-2xsmall uk-radius-circle uk-background-white dark:uk-background-gray-100"
                      >
                        <div class="uni-user-avatar uk-panel">
                          <div
                            class="uk-panel uk-overflow-hidden uk-radius-circle"
                          >
                            <canvas width="60" height="60"></canvas>
                            <img
                              v-if="user.image"
                              :src="$baseUrl + user.image"
                              alt="Archiver"
                              class="uni-user-avatar-image uk-cover uk-radius-circle"
                              data-uk-cover
                              loading="lazy"
                            />
                            <router-link
                              aria-label="Archiver"
                              :to="'/author/' + user.id"
                              class="uk-position-cover"
                            ></router-link>
                          </div>
                          <i
                            class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-xsmall"
                            >check_circle</i
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3
                    class="uni-artist-name uk-text-small uk-h6@m uk-margin-xsmall-top uk-margin-remove-bottom"
                  >
                    <router-link :to="'/author/' + user.id" class="uk-link">{{
                      user.name
                    }}</router-link>
                  </h3>
                </div>
              </div>
            </div>
            <div v-for="(provider, index) in providers" :key="index">
              <div
                class="uni-artist uk-panel uk-overflow-hidden uk-card uk-card-2xsmall uk-card-border uk-radius uk-radius-large@m uk-text-center uk-box-shadow-hover-medium"
              >
                <div
                  class="uni-artist-cover uk-card-media-top uk-panel uk-overflow-hidden uk-radius"
                >
                  <canvas class="uk-visible@m" height="120"></canvas>
                  <canvas class="uk-hidden@m" height="140"></canvas>
                  <img
                    v-if="provider.cover"
                    :src="$baseUrl + provider.cover"
                    alt="Archiver"
                    class="uk-cover"
                    data-uk-cover
                    loading="lazy"
                  />
                  <router-link
                    :to="'/author/' + provider.id"
                    class="uk-position-cover"
                    aria-label="Archiver"
                  ></router-link>
                </div>
                <div class="uni-artist-content uk-padding-remove-top">
                  <div
                    class="uni-artist-avatar uk-grid uk-flex-center"
                    data-uk-grid
                  >
                    <div>
                      <div
                        class="uk-panel uk-padding-2xsmall uk-radius-circle uk-background-white dark:uk-background-gray-100"
                      >
                        <div class="uni-user-avatar uk-panel">
                          <div
                            class="uk-panel uk-overflow-hidden uk-radius-circle"
                          >
                            <canvas width="60" height="60"></canvas>
                            <img
                              v-if="provider.image"
                              :src="$baseUrl + provider.image"
                              alt="Archiver"
                              class="uni-user-avatar-image uk-cover uk-radius-circle"
                              data-uk-cover
                              loading="lazy"
                            />
                            <router-link
                              aria-label="Archiver"
                              :to="'/author/' + provider.id"
                              class="uk-position-cover"
                            ></router-link>
                          </div>
                          <i
                            class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-xsmall"
                            >check_circle</i
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3
                    class="uni-artist-name uk-text-small uk-h6@m uk-margin-xsmall-top uk-margin-remove-bottom"
                  >
                    <router-link :to="'/author/' + provider.id" class="uk-link">{{
                      provider.name
                    }}</router-link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- top artists end -->

    <!-- explore end -->
  </div>

  <Footer></Footer>
</template>

<style>
/* Styles for the "User Suspended" text */
#uni-user-suspended {
  color: red;
  /* Make the text red */
}

/* Styles for the suspended user link */
.uni-user-link-suspended {
  pointer-events: none;
  /* Disable pointer events */
}
</style>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  name: "HomeView",
  data() {
    return {
      sort: "new",
      searchedValue: "",
      isMobile: window.innerWidth < 600,
    };
  },
  components: {
    Header,
    Footer,
    MobileMenu,
  },
  computed: {
    ...mapState({
      liveAuctions: (state) => state.liveAuctions,
      loggedIn: (state) => state.loggedIn,
      users: (state) => state.users,
      providers: (state) => state.providers,
      bannerData: (state) => state.bannerData,
      currentPage: (state) => state.currentPage,
      lastPage: (state) => state.lastPage,
      errors: (state) => state.errors,
    }),
  },
  methods: {
    ...mapActions(["fetchUsers", "fetchLiveAuction"]),
    updateRemainingTime() {
      this.liveAuctions
        .map((item, index) => {
          const now = moment();
          const end = moment(item.countdown);
          const diff = end.diff(now);

          let days = 0;
          let hours = 0;
          let minutes = 0;
          let seconds = 0;

          if (diff > 0) {
            const duration = moment.duration(diff);
            days = duration.days();
            hours = duration.hours() + days * 24;
            minutes = duration.minutes();
            seconds = duration.seconds();
          }
          return {
            ...item,
            hours,
            minutes,
            seconds,
          };
        })
        .forEach((item, index) => {
          this.liveAuctions[index] = item;
        });
    },
    getSlidesPerView() {
      if (window.innerWidth < 768) {
        return 2; // Show 2 events on small screens
      } else {
        return 4; // Show 4 events on larger screens
      }
    },
    trimIfTextLong(str) {
      if (str.length > (this.isMobile ? 37 : 46)) {
        return str.slice(0, this.isMobile ? 37 : 46) + "..";
      } else {
        return str;
      }
    },
  },

  beforeMount() {
    this.fetchLiveAuction();
    setTimeout(() => {
      const swiper = new Swiper(".swiper-match", {
        slidesPerView: this.getSlidesPerView(),
        spaceBetween: 10,
      });
    }, 3000);
  },
  mounted() {
    this.fetchUsers({
      currentPage: this.currentPage,
      searchedValue: "",
    });
  },
  created() {
    var self = this;
    setInterval(() => {
      self.updateRemainingTime();
    }, 1000);
  },
};
</script>

<style scoped>
/* Tooltip css */
.info-icon {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 10px;
}

.info-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>