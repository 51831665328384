<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>
  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>

    <div
      class="uni-sign-up uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top"
    >
      <!-- Page header -->
      <header class="uni-page-header">
        <div class="uk-container">
          <h1 class="heading uk-h3 uk-h1@m uk-text-center">Sign up</h1>
        </div>
      </header>
      <div class="uk-margin-top uk-margin-large-top@m">
        <div class="uk-container">
          <div
            class="uk-flex-column uk-flex-center uk-width-medium@m uk-margin-auto"
          >
            <form
              class="uk-form-stacked uk-panel uk-width-1-1"
              @submit.prevent="performRegistration"
              method="post"
            >
              <div v-if="errors.length" class="errors">
                <p
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                >
                  {{ error }}
                </p>
              </div>
              <div v-if="message" class="uk-text-meta uk-flex-middle">
                <i
                  class="uk-icon-xsmall uk-margin-right uk-text-success material-icons icon-circle"
                ></i>
                <span
                  class="uk-text-extra-large"
                  style="font-size: 18px; font-weight: bold"
                  >{{ message }}</span
                >
              </div>
              <!-- Registration form fields -->
              <div class="uk-margin-medium">
                <label for="username-signup" class="uk-form-label"
                  >Your username: <span class="uk-text-danger">*</span></label
                >
                <input
                  id="username-signup"
                  type="text"
                  v-model="registrationForm.name"
                  class="uk-input"
                  required
                />
              </div>
              <div class="uk-margin-medium">
                <label for="email-signup-page" class="uk-form-label"
                  >Your email: <span class="uk-text-danger">*</span></label
                >
                <input
                  id="email-signup-page"
                  type="email"
                  v-model="registrationForm.email"
                  class="uk-input"
                  required
                />
              </div>
              <div class="uk-margin">
                <label for="password-signup-page" class="uk-form-label"
                  >Your password: <span class="uk-text-danger">*</span></label
                >
                <input
                  id="password-signup-page"
                  type="password"
                  v-model="registrationForm.password"
                  class="uk-input"
                  required
                />
              </div>
              <div class="uk-margin">
                <label for="confirm-password" class="uk-form-label"
                  >Confirm Password:
                  <span class="uk-text-danger">*</span></label
                >
                <input
                  id="confirm-password"
                  type="password"
                  v-model="registrationForm.confirmPassword"
                  class="uk-input"
                  required
                />
                <p
                  v-if="
                    passwordsNotMatch && registrationForm.confirmPassword !== ''
                  "
                  class="text-danger"
                >
                  Passwords do not match.
                </p>
              </div>
              <div class="uk-margin">
                <label for="profile-image" class="uk-form-label">Profile Image: (optional)</label>
                <input
                  id="profile-image"
                  name="profileImage"
                  type="file"
                  @change="onFileChange($event, 'profile')"
                  class="uk-input"
                />
                <small class="uk-text-muted">Upload a profile image if desired.</small>
              </div>

              <div class="uk-margin">
                <label for="background-image" class="uk-form-label">Background Image: (optional)</label>
                <input
                  id="background-image"
                  name="backgroundImage"
                  type="file"
                  @change="onFileChange($event, 'cover')"
                  class="uk-input"
                />
                <small class="uk-text-muted">Upload a background image if desired.</small>
              </div>

              <div class="uk-margin">
                <re-captcha
                  ref="recaptchaContainer"
                  @recaptcha-verified="handleRecaptchaVerified"
                ></re-captcha>
                <p
                  v-if="!recaptchaClicked && recaptchaResponse === null"
                  class="text-danger"
                >
                  Please verify yourself by clicking the reCAPTCHA.
                </p>
              </div>
              <div class="uk-margin-medium">
                <button
                  type="submit"
                  class="uk-button uk-button-primary uk-button-large@m uk-width-expand"
                  :disabled="!canSubmitForm"
                >
                  <span v-if="isLoading">Loading...</span>
                  <span v-else>Sign up</span>
                </button>
              </div>
            </form>

            <p
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
              class="uk-text-small uk-text-bold uk-text-center"
            >
              You already have an account?
              <router-link
                to="/login"
                class="uk-button uk-button-small uk-button-default uk-radius-large"
              >
                <span>Sign in</span>
              </router-link>
            </p>
          </div>
          <!-- <div v-else>
                    <div v-if="errors.length" class="errors">
                        <p v-for="(error, index) in errors" :key="index" class="text-danger">
                            {{ error }}
                        </p>
                    </div>
                    <div v-if="message" class="uk-text-meta uk-flex-middle">
                        <i class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons">check_circle</i>
                        <span class="uk-text-extra-large" style="font-size: 18px; font-weight: bold">{{ message }}</span>
                    </div>
                </div> -->
        </div>
      </div>
    </div>
  </div>

  <Footer></Footer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import ReCaptcha from "./ReCaptcha.vue";
import router from "../router/index.js";

export default {
  name: "RegisterView",
  components: {
    Header,
    Footer,
    MobileMenu,
    ReCaptcha,
  },
  computed: {
    ...mapState(["registrationForm"]),
    errors: (state) => state.errors,
    passwordsNotMatch() {
      return (
        this.registrationForm.password !== this.registrationForm.confirmPassword
      );
    },
    recaptchaClicked() {
      return !!this.recaptchaResponse;
    },
    canSubmitForm() {
      return (
        !this.isLoading &&
        this.registrationForm.password ===
          this.registrationForm.confirmPassword &&
        this.recaptchaClicked
      );
    },
  },
  data() {
    return {
      message: "",
      errors: [],
      showForm: true,
      isLoading: false,
      recaptchaResponse: null,
      passwordsNotMatch: false,
    };
  },
  methods: {
    ...mapActions(["registerUser"]),
    async loadReCaptcha() {
      await new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        document.head.appendChild(script);
      });

      grecaptcha.ready(() => {
        const container = this.$refs.recaptchaContainer.$el;
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }

        grecaptcha.render(container, {
          sitekey: "6LdtpDMmAAAAAHKNxO6ulhC96kFYztp4svN9z5DN",
          callback: (response) => {
            this.recaptchaResponse = response;
          },
        });
      });
    },
    handleRecaptchaVerified(response) {
      this.recaptchaResponse = response;
    },
    onFileChange(event, file) {
  const selectedFile = event.target.files[0];

  if (selectedFile) {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (file === 'cover') {
        this.registrationForm.backgroundImage = selectedFile;
      } else if (file === 'profile') {
        this.registrationForm.profileImage = selectedFile;
      }
    };
    reader.readAsDataURL(selectedFile);
  } else {
    if (file === 'cover') {
      this.registrationForm.backgroundImage = null;
    } else if (file === 'profile') {
      this.registrationForm.profileImage = null;
    }
  }
},

performRegistration() {
  if (!this.canSubmitForm) return;

  const formData = new FormData();
  formData.append('name', this.registrationForm.name);
  formData.append('email', this.registrationForm.email);
  formData.append('password', this.registrationForm.password);
  formData.append('recaptchaResponse', this.recaptchaResponse);
  if (this.registrationForm.profileImage) {
    formData.append('image', this.registrationForm.profileImage);
  }

  if (this.registrationForm.backgroundImage) {
    formData.append('cover', this.registrationForm.backgroundImage);
  }

  this.isLoading = true;

  axios
    .post('/api/register', formData)
    .then((response) => {
      router.push({ name: 'LoginView' });
    })
    .catch((error) => {
      // Handle error
    })
    .finally(() => {
      this.isLoading = false;
    });
},

  },
  mounted() {
    this.loadReCaptcha();
  },
};
</script>
