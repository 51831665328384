<template>
  <div id="place_bid" class="uk-modal" data-uk-modal>
    <div class="loader" v-if="loading">
      <div class="circle-loader"></div>
      <!-- Display the loading spinner -->
    </div>

    <div
      class="uk-modal-dialog uk-card-xsmall uk-card-small@m uk-padding-medium-horizontal uk-margin-auto-vertical uk-width-xlarge@m uk-radius-large"
    >
      <button
        aria-label="Close"
        class="uk-modal-close-outside"
        type="button"
        data-uk-close
      ></button>
      <div class="uk-panel uk-flex-center uk-flex-middle uk-height-1-1">
        <ul
          hidden
          class="uk-subnav uk-subnav-pill"
          data-uk-switcher="animation: uk-animation-fade; duration: 150;"
        >
          <li><a href="#switcher_wallet">Connect wallet</a></li>
          <li><a href="#switcher_place_bid">Place a bid</a></li>
          <li><a href="#switcher_feedback">Feedback</a></li>
        </ul>
        <ul class="uk-switcher">
          <li>
            <div class="uk-panel">
              <h3 class="uk-h4 uk-h3@m">Submit the screenshots</h3>
              <div data-uk-margin="margin: uk-margin-xsmall-top">
                <form @submit.prevent="submitForm()">
                  <div class="submit-data">
                    <div class="form-group">
                      <img v-if="previewImageUrl" :src="previewImageUrl" alt="Screenshot" style="height: 150px; width: 110px" />

                      <label for="">Screenshot</label>
                      <input
                        type="file"
                        @change="onFileChange($event)"
                        class="form-control py-10 h-max-content"
                        required
                        accept="image/*"
                      />
                    </div>
                    <div class="form-group">
                      <label for="">Winning Amount</label>
                      <input
                        type="number"
                        v-model="submitScreenshotForm.credit"
                        placeholder="Enter the winning  amount"
                        class="form-control py-10 h-max-content"
                        required
                      />
                      <button
                        type="submit"
                        class="uk-button uk-button-medium uk-button-primary w-100 mt-20"
                        :disabled="isButtonDisabled"
                      >
                        Confirm Submission
                      </button>
                      <p class="mt-1">
                        Submission time will not be returned if your winning
                        doesnt qualify
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </li>

          <li>
            <div class="uk-panel uk-text-center uk-flex-column uk-flex-middle">
              <i class="uk-icon-large uk-text-success material-icons"
                >check_circle</i
              >
              <p class="uk-text-xlarge">
                Your bid has been successfully placed!
              </p>
              <button
                type="button"
                class="uk-button uk-button-small uk-button-default uk-button-outline"
                data-uk-switcher-item="previous"
              >
                Place another bid
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "PlaceBidModal",
  data() {
    return {
      file: "",
      loading: false,
      isButtonDisabled: false,
      previewImageUrl: "",
    };
  },
  props:{
    amountForTicket: Number,
  },
  computed: {
    ...mapState({
      singlePost: (state) => state.singlePost,
      submitScreenshotForm: (state) => state.submitScreenshotForm,
    }),
  },

  methods: {
    ...mapActions(["saveSubmission"]),
    onFileChange(event) {
      this.submitScreenshotForm.image = event.target.files[0];

      if (!this.submitScreenshotForm.image.type.startsWith("image/")) {
        alert("Please select an image file.");
        event.target.value = "";
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImageUrl = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    async submitForm() {
      if(this.amountForTicket>this.submitScreenshotForm.credit){
        alert("Your winning amount is less then from screenshot amount per entry");
        return false;
      }
      this.loading = true; 
      this.isButtonDisabled = true;
      
      try {
        await this.saveSubmission(this.submitScreenshotForm);
      } finally {
        this.loading = false; 
        this.isButtonDisabled = true;
      }
    },
  },
  mounted() {
    let post_id = this.$route.params.id;
    this.submitScreenshotForm.post_id = post_id;
  },
};
</script>
<style>
.circle-loader {
  border: 8px solid #948b8b; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite; /* Apply rotation animation */
  margin: 0 auto;
  z-index: 100;
  margin-top: 90px; /* Adjust as needed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
