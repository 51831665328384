<template>
<!-- Menu modal -->
<MobileMenu></MobileMenu>

<!-- Send Report modal -->
<div id="annouce_draw" class="uk-modal" data-uk-modal>
    <div class="uk-modal-dialog uk-card-xsmall uk-card-small@m uk-margin-auto-vertical uk-width-medium@m uk-radius-large">
        <button aria-label="Close" class="uk-modal-close-outside" type="button" data-uk-close></button>
        <div class="uk-panel uk-flex-center uk-flex-middle uk-height-1-1">
            <ul hidden class="uk-subnav uk-subnav-pill" data-uk-switcher="animation: uk-animation-fade; duration: 150;">
                <li><a href="#report">Report</a></li>
                <li><a href="#report_feedback">Feedback</a></li>
            </ul>
            <ul class="uk-switcher">
                <li>
                    <div class="uk-panel">
                        <h3 class="uk-h4">Annouce the draw</h3>
                        <p class="uk-text-muted">Here you can set the draw to default or manula.</p>
                        <div class="uk-form-stack uk-grid-xsmall uk-child-width-1-1 uk-grid" data-uk-grid>
                            <form @submit.prevent="annouceDraw(postDrawForm)">
                                <div class="uk-form-group" v-if="postDrawForm.action=='manual'">
                                    <span class="uk-text-bold">Winner Ticket Number:</span>
                                    <input required v-model="postDrawForm.winner_ticket" class="uk-input" placeholder="Enter the winner tick">
                                </div>
                                <div class="uk-form-group">
                                    <span class="uk-text-bold">Draw method:</span>
                                    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                        <label><input v-model="postDrawForm.action" class="uk-radio" type="radio" value="auto"> Auto</label>
                                        <label><input v-model="postDrawForm.action" class="uk-radio" type="radio" value="manual"> Manual</label>
                                    </div>
                                </div>
                                <div class="uk-form-group">
                                    <button type="submit" class="uk-button uk-button-medium uk-button-danger uk-width-1-1">Annouce Draw</button>
                                    <button @click="cancelDraw()" type="button" class="uk-button uk-button-medium uk-button-default uk-button-alt uk-width-1-1 uk-margin-xsmall-top uk-modal-close">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <!-- <li>
                            <div class="uk-panel uk-text-center uk-flex-column uk-flex-middle">
                                <i class="uk-icon-large uk-text-success material-icons">check_circle</i>
                                <p class="uk-text-lead">We have received your report!</p>
                                <button type="button" class="uk-button uk-button-small uk-button-default uk-button-outline uk-margin-xsmall-top" data-uk-switcher-item="previous">Submit another report</button>
                            </div>
                        </li> -->
            </ul>
        </div>
    </div>
</div>

<div class="wrap">

    <!-- Hero: Header -->
    <Header></Header>

    <div class="uni-explore uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top">

        <!-- Page header -->
        <header class="uni-page-header">
            <div class="uk-container">
                <h1 class="heading uk-h3 uk-h1@m uk-text-center">My All Events</h1>
            </div>
        </header>
        <div class="uk-margin-top uk-margin-large-top@m">
            <div class="uk-container">

               

               <!-- artworks listing + desktop filters -->
                <div class="uni-explore-filter uk-visible">
                    <div class="uk-grid-2xsmall uk-grid-2xlarge@m uk-flex-between uk-flex-middle uk-grid" data-uk-grid="">
                        <div class="uk-width-expand uk-visible@m uk-width-auto@m uk-flex-1@m uk-first-column">
                            <!-- <div class="uk-width-1-1 uk-width-auto@m uk-form-custom" data-uk-form-custom="target: > * > span:first-child">
                                <select>
                                    <option value="1">Live auction</option>
                                    <option value="2">Hot bids</option>
                                    <option value="3">Buy Now</option>
                                    <option value="4">Has Offers</option>
                                    <option value="5">Open for bids</option>
                                </select>
                                <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                    <span>Live auction</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down icon uk-icon-2xsmall">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                </button>
                            </div> -->
                        </div>
                        <div class="uk-width-auto uk-width-auto@m">
                            <div class="uk-grid-2xsmall uk-visible uk-grid" data-uk-grid="">
                                <div>
                                    <div class="uk-inline">
                                        <span class="uk-form-icon uk-form-medium">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search icon uk-icon-small">
                                                <circle cx="11" cy="11" r="8"></circle>
                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                            </svg>
                                        </span>
                                        <input @keyup="filter()" v-model="searchedValue" class="uk-input uk-form-medium" type="text" placeholder="Input">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="uk-width-1-1 uk-width-auto@m uk-flex-right@m uk-flex-1@m uk-first-column">
                            <div class="uk-width-1-1 uk-width-auto@m uk-form-custom" data-uk-form-custom="target: > * > span:first-child">
                                <select @change="sortData()" v-model="sort">
                                    <option value="new">Sort by: Newest</option>
                                    <option value="old">Sort by: Oldest</option>
                                </select>
                                <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                    <span>Sort by: Newest</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down icon uk-icon-2xsmall">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                </button>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="uk-margin-top">
                    <div class="uk-container">
                        <div class="uk-panel">
                            <div class="uk-grid uk-grid-large@m" data-uk-grid>
                                <!-- <div class="uk-width-1-4@m uk-visible@m">
                                            <div class="uk-panel" data-uk-sticky="offset: 120; bottom: true; media: @s;">
                                                <form action="?" class="uk-grid uk-grid-medium uk-child-width-1-1" data-uk-grid>
                                                    <div>
                                                        <div class="uk-form-controls">
                                                            <label class="uk-form-label uk-text-overline uk-text-muted uk-text-bold">Price Range</label>
                                                            <input class="uk-range" type="range" value="2" min="0" max="10" step="0.1">
                                                            <div class="uk-flex-between uk-text-small uk-text-bold uk-margin-xsmall-top" style="line-height: 1">
                                                                <span>0.01 ETH</span>
                                                                <span>10 ETH</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div data-uk-margin="margin: uk-margin-small-top uk-margin-top@m">
                                                            <div class="uk-form-controls">
                                                                <label class="uk-form-label uk-text-overline uk-text-muted uk-text-bold">Status</label>
                                                                <div class="uk-width-1-1" data-uk-form-custom="target: > * > span:first-child">
                                                                    <select>
                                                                        <option value="0">All</option>
                                                                        <option value="1">Live auction</option>
                                                                        <option value="2">Hot bids</option>
                                                                        <option value="3">Buy Now</option>
                                                                        <option value="4">Has Offers</option>
                                                                        <option value="5">Open for bids</option>
                                                                    </select>
                                                                    <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                                                        <span>Select option</span>
                                                                        <i class="uk-icon-2xsmall" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="uk-form-controls">
                                                                <label class="uk-form-label uk-text-overline uk-text-muted uk-text-bold">Category</label>
                                                                <div class="uk-width-1-1" data-uk-form-custom="target: > * > span:first-child">
                                                                    <select>
                                                                        <option value="All">All</option>
                                                                        <option value="Art">Art</option>
                                                                        <option value="Game">Game</option>
                                                                        <option value="Photography">Photography</option>
                                                                        <option value="Music">Music</option>
                                                                        <option value="Video">Video</option>
                                                                    </select>
                                                                    <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                                                        <span>Select option</span>
                                                                        <i class="uk-icon-2xsmall" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="uk-form-controls">
                                                                <label class="uk-form-label uk-text-overline uk-text-muted uk-text-bold">Creator</label>
                                                                <div class="uk-width-1-1" data-uk-form-custom="target: > * > span:first-child">
                                                                    <select>
                                                                        <option value="1">All</option>
                                                                        <option value="2">Verified only</option>
                                                                        <option value="3">Most liked</option>
                                                                    </select>
                                                                    <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                                                        <span>Select option</span>
                                                                        <i class="uk-icon-2xsmall" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="uk-panel">
                                                            <button type="reset" class="uk-button uk-button-small uk-button-primary uk-button-alt uk-width-1-1">
                                                                <i class="uk-icon-small material-icons">clear</i>
                                                                <span>Reset Filter</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <noscript>
                                                        <div>
                                                            <div class="uk-panel">
                                                                <button type="submit" class="uk-button uk-button-small uk-button-primary uk-button-alt uk-width-1-1">
                                                                    <span>Update Filter</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </noscript>
                                                </form>
                                            </div>
                                        </div> -->
                                <div class="uk-width-expand@m">
                                    <div id="explore-list-container" class="uk-grid-2xsmall uk-grid-small@m uk-grid-match uk-child-width-1-2 uk-child-width-1-3@m uk-grid" data-uk-grid>
                                        <div v-for="(post, index) in myPosts" :key="index" class="home_posts">
                                            <div class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle">
                                                <div class="uni-artwork-header uk-panel">
                                                    <div class="uk-grid uk-grid-xsmall uk-flex-middle uk-flex-between uk-text-small uk-text-bold" data-uk-grid>
                                                        <div>
                                                            <div class="uk-text-bold">
                                                                <div class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid" data-uk-grid>
                                                                    <div class="uk-width-auto">
                                                                        <div class="uni-user-avatar uk-panel">
                                                                            <div class="uk-panel uk-overflow-hidden uk-radius-circle">
                                                                                <canvas width="32" height="32"></canvas>
                                                                                <img v-if="post.user && post.user.image" :src="$baseUrl+post.user.image" alt="TheSalvare" class="uni-user-avatar-image uk-cover uk-radius-circle" data-uk-cover>
                                                                                <router-link aria-label="TheSalvare" :to="'post-detail/'+post.id" class="uk-position-cover"></router-link>
                                                                            </div>
                                                                            <i v-if="post.user && post.user.is_verified" class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-3xsmall">check_circle</i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uk-visible@m">
                                                                        <div>
                                                                            <router-link v-if="post.user && post.user.name" class="uni-user-link uk-link-reset" :to="'post-detail/'+post.id">{{ post.user.name }}</router-link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button type="button" class="uk-button uk-button-2xsmall uk-button-icon uk-button-default uk-button-ghost uk-radius-circle">
                                                                <i class="uk-icon-xsmall material-icons">more_horizontal</i>
                                                            </button>
                                                            <div class="uk-dropdown-minimal uk-text-bold uk-dropdown" data-uk-dropdown="mode: click">
                                                                <ul class="uk-nav uk-dropdown-nav">
                                                                    <li>
                                                                        <router-link :to="{ name: 'SubmissionReceivedView', params: { id: post.id } }">View Submissions</router-link>
                                                                    </li>
                                                                    <li v-if="!post.winner_ticket"><a href="#annouce_draw" @click="setAnnouceData(post.id)" data-uk-toggle class="uk-text-success">Annouce Draw</a></li>
                                                                    <!-- <li><a href="#connect_wallet" data-uk-toggle onclick="UIkit.switcher('#connect_wallet [data-uk-switcher]').show(0)">Purchase now</a></li> -->
                                                                    <!-- <li><a href="#share_this" data-uk-toggle>Share</a></li>
                                                                            <li class="uk-nav-divider"></li> -->
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m" style="margin: 16px 0">
                                                    <div style="height:180px;" class="uk-panel w-100 ">
                                                        <img :src="$baseUrl+post.image" alt="" class="uk-radius h-100 w-100 object_fit_cover">
                                                        <router-link :to="'post-detail/'+post.id" class="uk-position-cover" aria-label="Metaverse Game"></router-link>
                                                    </div>
                                                    <!-- <div class="uk-label uk-label-primary uk-background-gradient uk-position-bottom-left uk-font-mono">
                                                                <div class="uk-grid-gallery uk-child-width-auto uk-text-bold uk-grid" data-uk-grid data-uk-countdown="date: 2022-12-31T07:40:25+00:00">
                                                                    <div class="uk-countdown-hours"></div>
                                                                    <div class="sep">:</div>
                                                                    <div class="uk-countdown-minutes"></div>
                                                                    <div class="sep">:</div>
                                                                    <div class="uk-countdown-seconds"></div>
                                                                </div>
                                                            </div> -->
                                                </div>

                                                <div class="uni-artwork-content uk-panel">

                                                    <span v-if="post.winner_ticket" class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-right uk-visible@m">Game winner: {{ post.winner_ticket }}</span>
                                                    <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                                                        
                                                        <router-link class="uk-link-reset" :to="'post-detail/'+post.id">{{ post.title }}</router-link>
                                                        <!-- <span class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m">1 / 1</span> -->
                                                    </h2>
                                                    <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall d-flex justify-content-between">
                                                        <span class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m">Target {{ post.target }}</span>
                                                        <span class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-right uk-visible@m">Target Reached {{ post.total_amount?post.total_amount:0 }}</span>
                                                    </h2>
                                                    <!-- <span class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block">Highest bid</span>
                                                            <span class="uk-text-bold uk-text-gradient">Bid 2.079 ETH</span> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="uni-btn uk-margin-large-top uk-margin-xlarge-top@m uk-flex-center">
                                        <button v-if="currentPage < lastPage" @click="loadMore(++currentPage)" class="uk-button uk-button-small uk-button-medium@m uk-button-default uk-button-outline uk-width-medium@m" type="button" data-load-more-container="#explore-list-container" data-load-more="explore-ajax.html?page=%page%" data-toggle-loading>
                                            <!-- <span class="no-more-loading">No more</span> -->
                                            <span class="not-loading">Load more</span>
                                            <span class="loading uk-margin-remove uk-flex-middle"><span class="uk-flex-middle uk-margin-2xsmall-right" data-uk-spinner="ratio: .5"></span>Loading...</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<Footer></Footer>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import MobileMenu from '../components/MobileMenu.vue';
import {
    mapState,
    mapActions
} from 'vuex';
export default {
    name: 'MyPostView',
    components: {
        Header,
        Footer,
        MobileMenu
    },
    computed: {
        ...mapState({
            myPosts: state => state.myPosts,
            currentPage: state => state.currentPage,
            lastPage: state => state.lastPage,
            postDrawForm: state => state.postDrawForm
        })
    },
    methods: {
        ...mapActions([
            "fetchMyPosts",
            "annouceDraw",
            "cancelDraw"
        ]),
        setAnnouceData(post_id) {
            this.postDrawForm.post_id = post_id
        },
        filter() {
            this.fetchMyPosts({
            currentPage:  this.currentPage,
            searchedValue: this.searchedValue
    });
        },
        loadMore(currentPage){
            this.fetchMyPosts({
                currentPage: currentPage,
                
            });
        }
        
    },
    mounted() {
        this.fetchMyPosts({
            currentPage:  this.currentPage,
            searchedValue: ''
    });
    }
}
</script>
