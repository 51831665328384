<template>
  <div class="wrap">
    <MobileMenu></MobileMenu>
    <!-- Hero: Header -->
    <Header></Header>

    <div
      class="uni-create uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top"
    >
      <div class="uk-container uk-container-xsmall">
        <div class="uk-grid" data-uk-grid>
          <div class="uk-width-2-3@m">
            <div class="uk-panel uk-width-2xlarge@m uk-margin-auto">
              <form
                @submit.prevent="createPost(createPostForm)"
                class="uk-form-stacked uk-grid-xsmall uk-grid-small@m uk-child-width-1-1 uk-grid"
                data-uk-grid
              >
                <div v-if="errors.length" class="errors">
                  <p
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    {{ error }}
                  </p>
                </div>
                <div class="uk-form-group">
                  <h3 class="uk-h6 uk-margin-remove">Upload file</h3>
                  <p
                    class="uk-text-xsmall uk-text-small@m uk-text-bold uk-text-muted uk-margin-remove"
                  >
                    Drag or choose your file to upload.
                  </p>
                </div>
                <div class="uk-form-group">
                  <div
                    class="uk-panel uk-card uk-card-small uk-card-large@m uk-card-border uk-flex-column uk-flex-middle uk-text-center uk-radius"
                    style="border-width: 2px; border-style: dashed"
                  >
                    <p
                      class="uk-text-bold uk-text-xsmall uk-text-muted uk-text-middle uk-margin-small"
                    >
                      PNG,JPEG ,JPG, GIF
                    </p>
                    <div data-uk-form-custom="">
                      <input
                        type="file"
                        @change="createPostImageChanged($event)"
                        required
                        accept="image/*"
                      />
                      <span
                        class="uk-button uk-button-xsmall uk-button-default uk-button-outline"
                      >
                        <i
                          class="uk-icon-xsmall uk-margin-2xsmall-right"
                          data-feather="upload-cloud"
                        ></i>
                        <span>Upload image</span>
                      </span>
                    </div>
                  </div>
                  <img
                    v-if="previewImage"
                    :src="previewImage"
                    width="100px"
                    height="100px"
                    class="uk-width-1-1 uk-height-auto"
                    alt="Preview"
                  />
                </div>
                <div class="uk-form-group">
                  <h3 class="uk-h6 uk-margin-remove">Item details</h3>
                  <p
                    class="uk-text-xsmall uk-text-small@m uk-text-bold uk-text-muted uk-margin-remove"
                  >
                    Describe your item in details.
                  </p>
                </div>
                <div class="uk-form-group">
                  <label class="uk-form-label uk-text-small">Title</label>
                  <div class="uk-form-controls">
                    <input
                      required
                      class="uk-input uk-form-small"
                      v-model="createPostForm.title"
                      type="text"
                      placeholder="Enter the event title"
                    />
                  </div>
                </div>
                <div>
                  <div class="uk-form-group">
                    <label class="uk-form-label uk-text-small"
                      >Description</label
                    >
                    <div class="uk-form-controls">
                      <div id="editor-container" ref="editor"></div>
                    </div>
                  </div>
                </div>
                <div class="uk-form-group">
                  <label class="uk-form-label uk-text-small">Event Link</label>
                  <div class="uk-form-controls">
                    <input
                      required
                      class="uk-input uk-form-small"
                      v-model="createPostForm.post_link"
                      type="text"
                      placeholder="Enter the event description"
                    />
                  </div>
                </div>
                <div class="uk-form-group">
                  <label class="uk-form-label uk-text-small"
                    >Total Winnings Accumulated</label
                  >
                  <div class="uk-form-controls">
                    <input
                      required
                      class="uk-input uk-form-small"
                      v-model="createPostForm.target"
                      type="number"
                      placeholder="Target amount for the event"
                    />
                  </div>
                </div>
                <div class="uk-form-group">
                  <label class="uk-form-label uk-text-small"
                    >Lucky Draw Winner Amount</label
                  >
                  <div class="uk-form-controls">
                    <input
                      required
                      class="uk-input uk-form-small"
                      v-model="createPostForm.sponsor_amount"
                      type="number"
                      placeholder="Enter the amount for lucky draw winner"
                    />
                  </div>
                </div>
                <div class="uk-form-group">
                  <label class="uk-form-label uk-text-small"
                    >Amount Required For a Lucky Draw Ticket</label
                  >
                  <div class="uk-form-controls">
                    <input
                      required
                      class="uk-input uk-form-small"
                      v-model="createPostForm.amount_for_ticket"
                      type="number"
                      placeholder="Enter the amount required for one ticket"
                    />
                  </div>
                </div>
                <div class="uk-form-group">
                  <label class="uk-form-label uk-text-small"
                    >Max ticket per submission</label
                  >
                  <div class="uk-form-controls">
                    <input
                      required
                      class="uk-input uk-form-small"
                      v-model="createPostForm.ticket_per_user"
                      type="number"
                      placeholder="Enter Max ticket per submission"
                    />
                  </div>
                </div>
                <div class="uk-form-group">
                  <label class="uk-form-label uk-text-small">End Date</label>
                  <div class="uk-form-controls">
                    <input
                      type="datetime-local"
                      required
                      class="uk-input uk-form-small"
                      v-model="createPostForm.auction_end"
                      placeholder="Enter the event ending date"
                    />
                    <span
                      v-if="new Date(createPostForm.auction_end) < new Date()"
                      class="text-danger"
                      >Auction end date must be after current date and
                      time</span
                    >
                  </div>
                </div>
                <div class="uk-form-group">
                  <hr />
                </div>
                <div class="uk-form-group">
                  <div
                    class="uk-grid-xsmall uk-flex-middle uk-flex-center uk-flex-between@m uk-grid"
                    data-uk-grid
                  >
                    <div class="uk-width-1-1 uk-width-expand@m">
                      <button
                        type="submit"
                        class="uk-button uk-button-gradient uk-width-1-1 uk-width-xsmall@m"
                      >
                        Create event
                      </button>
                    </div>
                    <div v-if="message" class="uk-text-meta uk-flex-middle">
                      <i
                        class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons"
                        >check_circle</i
                      >
                      <span
                        class="uk-text-extra-large"
                        style="font-size: 18px; font-weight: bold"
                        >{{ message }}</span
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { mapState, mapActions } from "vuex";

export default {
  name: "CreatePost",
  components: {
    Header,
    Footer,
    MobileMenu,
  },
  data() {
    return {
      previewImage: "",
      quill: null,
    };
  },
  computed: {
    ...mapState({
      createPostForm: (state) => state.createPostForm,
      errors: (state) => state.errors,
      message: (state) => state.message,
    }),
  },

  mounted() {
    this.initializeQuill();
  },
  methods: {
    ...mapActions(["createPost"]),
    createPostImageChanged(event) {
      const file = event.target.files[0];
      if (!file.type.startsWith("image/")) {
        alert("Please select an image file.");
        event.target.value = "";
        return;
      }
      this.createPostForm.image = file;
      this.previewImage = URL.createObjectURL(file);
    },
    initializeQuill() {
      this.quill = new Quill(this.$refs.editor, {
        theme: "snow",
        // Other Quill configuration options
      });
      this.quill.on("text-change", this.handleEditorChange);
    },
    handleEditorChange() {
      if (
        this.quill &&
        this.quill.root &&
        document.activeElement !== this.$refs.editor
      ) {
        this.createPostForm.description = this.quill.root.innerHTML;
      }
    },
    handleSubmit() {
      if (this.quill && this.quill.root) {
        this.createPostForm.description = this.quill.root.innerHTML;
      }
      this.createPost(this.createPostForm);
    },
  },
};
</script>
