<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>

  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>

    <div
      class="uni-explore uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top"
    >
      <!-- Page header -->
      <header class="uni-page-header">
        <div class="uk-container">
          <h1 class="heading uk-h3 uk-h1@m uk-text-center">My Submissions</h1>
        </div>
      </header>
      <div class="uk-margin-top uk-margin-large-top@m">
        <div class="uk-container">
          <!-- mobile filters -->
          <!-- <div class="uni-explore-filter-mobile uk-hidden@m">
                    <div class="uk-grid-gallery uk-grid" data-uk-grid>
                        <div class="uk-width-expand">
                            <div class="uk-inline uk-width-1-1">
                                <span class="uk-form-icon uk-form-icon-flip">
                                    <i class="uk-icon-small" data-feather="search"></i>
                                </span>
                                <input type="search" class="uk-input uk-form-small" placeholder="Search items, collections..">
                            </div>
                        </div>
                        <div>
                            <button class="toggle-filter-button uk-button uk-button-small uk-button-icon uk-button-primary" type="button" data-uk-toggle="target: #toggle-filter">
                                <i class="uk-icon-small material-icons">filter_list</i>
                            </button>
                        </div>
                        <div class="uk-width-1-1">
                            <div id="toggle-filter" hidden>
                                <div class="uk-panel uk-card uk-card-2xsmall uk-radius uk-background-gray-10 dark:uk-background-gray-90">
                                    <ul class="uk-nav uk-dropdown-nav">
                                        <li class="uk-nav-header">By Type</li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="1" checked> Hot Bids</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="2"> Buy Now</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="3"> Live Auctions</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="4"> Has Offers</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="5"> Open for bids</label>
                                        </li>
                                        <li class="uk-nav-header">By category</li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="1" checked> All</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="2"> Art</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="3"> Game</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="4"> Photography</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="5"> Music</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="6"> Video</label>
                                        </li>
                                        <li class="uk-nav-header">Sort by</li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="1" checked> Newest</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="2"> Oldest</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="3"> Price <i class="uk-icon-2xsmall material-icons">north</i></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="4"> Price <i class="uk-icon-2xsmall material-icons">south</i></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="5"> Reserve <i class="uk-icon-2xsmall material-icons">north</i></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="6"> Reserve <i class="uk-icon-2xsmall material-icons">south</i></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="7"> Ending Soon</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

          <!-- artworks listing + desktop filters -->
          <div class="uk-margin-top">
            <div class="uk-container">
              <div class="uk-panel">
                <div class="uk-grid uk-grid-large@m" data-uk-grid>
                  <div class="uk-width-expand@m">
                    <div id="explore-list-container" class="submission_grid">
                      <div
                        class="h-100"
                        v-for="(submission, index) in mySubmissionsList"
                        :key="index"
                      >
                        <div
                          class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle h-100"
                        >
                          <div class="uni-artwork-header uk-panel">
                            <div
                              class="uk-grid uk-grid-xsmall uk-flex-middle uk-flex-between uk-text-small uk-text-bold"
                              data-uk-grid
                            >
                              <div>
                                <div class="uk-text-bold">
                                  <div
                                    class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid"
                                    data-uk-grid
                                  >
                                    <div class="uk-width-auto">
                                      <div class="uni-user-avatar uk-panel">
                                        <div
                                          class="uk-panel uk-overflow-hidden uk-radius-circle"
                                        >
                                          <canvas
                                            width="32"
                                            height="32"
                                          ></canvas>
                                          <img
                                            v-if="
                                              submission.user &&
                                              submission.user.image
                                            "
                                            :src="
                                              $baseUrl + submission.user.image
                                            "
                                            alt="TheSalvare"
                                            class="uni-user-avatar-image uk-cover uk-radius-circle"
                                            data-uk-cover
                                          />
                                          <a
                                            aria-label="TheSalvare"
                                            class="uk-position-cover"
                                          ></a>
                                        </div>
                                        <i
                                          v-if="
                                            submission.user &&
                                            submission.user.is_verified
                                          "
                                          class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-3xsmall"
                                          >check_circle</i
                                        >
                                      </div>
                                    </div>
                                    <div class="uk-visible@m">
                                      <div>
                                        <a
                                          v-if="
                                            submission.user &&
                                            submission.user.name
                                          "
                                          class="uni-user-link uk-link-reset"
                                          href="author.html"
                                          >{{ submission.user.name }}</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div>
                                                            <button type="button" class="uk-button uk-button-2xsmall uk-button-icon uk-button-default uk-button-ghost uk-radius-circle">
                                                                <i class="uk-icon-xsmall material-icons">more_horizontal</i>
                                                            </button>
                                                            <div class="uk-dropdown-minimal uk-text-bold uk-dropdown" data-uk-dropdown="mode: click">
                                                                <ul class="uk-nav uk-dropdown-nav">
                                                                    <li><a href="#connect_wallet" data-uk-toggle onclick="UIkit.switcher('#connect_wallet [data-uk-switcher]').show(0)">Purchase now</a></li>
                                                                            <li><a href="#share_this" data-uk-toggle>Share</a></li>
                                                                            <li class="uk-nav-divider"></li>
                                                                    <li><a href="#send_report" @click="setReportData(submission.user.id,submission.post.id,submission.id)" data-uk-toggle class="uk-text-danger">Report</a></li>
                                                                </ul>
                                                            </div>
                                                        </div> -->
                            </div>
                          </div>

                          <div
                            class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m"
                            style="margin: 16px 0"
                          >
                            <div class="uk-panel uk-image-middle">
                              <img
                                v-if="submission.image"
                                :src="$baseUrl + submission.image"
                                alt=""
                                class="uk-radius"
                              />
                              <router-link
                                :to="'post-detail/' + submission.post_id"
                                class="uk-position-cover"
                                aria-label="Metaverse Game"
                              ></router-link>
                            </div>
                            <!-- <div class="uk-label uk-label-primary uk-background-gradient uk-position-bottom-left uk-font-mono">
                                                                <div class="uk-grid-gallery uk-child-width-auto uk-text-bold uk-grid" data-uk-grid data-uk-countdown="date: 2022-12-31T07:40:25+00:00">
                                                                    <div class="uk-countdown-hours"></div>
                                                                    <div class="sep">:</div>
                                                                    <div class="uk-countdown-minutes"></div>
                                                                    <div class="sep">:</div>
                                                                    <div class="uk-countdown-seconds"></div>
                                                                </div>
                                                            </div> -->
                          </div>

                          <div class="uni-artwork-content uk-panel">
                            <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                              <!-- <router-link class="uk-link-reset" :to="'post-detail/'+post.id">{{ post.title }}</router-link> -->
                              <span
                                class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m"
                                >Date:
                                {{ $formatDate(submission.created_at) }}</span
                              >
                            </h2>
                            <!-- <span class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block">Highest bid</span> -->
                            <div class="d-flex justify-content-between">
                              <span class="uk-text-bold uk-text-gradient"
                                >Winning amount: {{ submission.amount }}</span
                              >
                              <span
                                v-if="submission.is_verified === 1"
                                class="verification"
                              >
                                <span class="uk-text-meta uk-flex-middle">
                                  <i
                                    class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons"
                                    >check_circle</i
                                  >
                                </span>
                              </span>
                              <span
                                v-else-if="submission.is_verified === 0"
                                class="verification"
                              >
                                <span class="uk-text-meta uk-flex-middle">
                                  <i
                                    class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-warning material-icons"
                                    >schedule</i
                                  >
                                </span>
                              </span>
                              <span
                                v-else-if="submission.is_verified === 2"
                                class="verification"
                              >
                                <span class="uk-text-meta uk-flex-middle">
                                  <i
                                    class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-danger material-icons"
                                    >close</i
                                  >
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="uni-btn uk-margin-large-top uk-margin-xlarge-top@m uk-flex-center"
                    >
                      <button
                        v-if="currentPage < lastPage"
                        @click="loadMore(post_id, ++currentPage)"
                        class="uk-button uk-button-small uk-button-medium@m uk-button-default uk-button-outline uk-width-medium@m"
                        type="button"
                        data-load-more-container="#explore-list-container"
                        data-toggle-loading
                      >
                        <!-- <span class="no-more-loading">No more</span> -->
                        <span class="not-loading">Load more</span>
                        <span class="loading uk-margin-remove uk-flex-middle"
                          ><span
                            class="uk-flex-middle uk-margin-2xsmall-right"
                            data-uk-spinner="ratio: .5"
                          ></span
                          >Loading...</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "MySubmissionView",
  data() {
    return {
      post_id: null,
    };
  },
  components: {
    Header,
    Footer,
    MobileMenu,
  },
  computed: {
    ...mapState({
      mySubmissionsList: (state) => state.mySubmissionsList,
      currentPage: (state) => state.currentPage,
      lastPage: (state) => state.lastPage,
    }),
  },
  methods: {
    ...mapActions(["fetchMySubmissionsList"]),
    loadMore(post_id, currentPage) {
      this.fetchMySubmissionsList({
        post_id,
        currentPage,
      });
    },
  },
  mounted() {
    if (this.$route.params.id) this.post_id = this.$route.params.id;

    this.fetchMySubmissionsList({
      post_id: this.post_id,
      currentPage: 1,
    });
  },
};
</script>
