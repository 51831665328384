<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>

  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>
    <div class="uni-author-profile uk-panel uk-overflow-hidden">
      <div class="uk-section uk-section-medium@m uk-padding-remove-top">
        <div class="uk-container uk-container-large">
          <div
            class="uni-author-profile-cover uk-panel uk-overflow-hidden uk-background-primary-30 uk-radius-large"
          >
            <canvas class="uk-visible@m" height="250"></canvas>
            <canvas class="uk-hidden@m" height="170"></canvas>
            <img
              :src="$baseUrl + authorDetails.cover"
              alt="Thesalvare"
              class="uk-cover"
              data-uk-cover
              loading="lazy"
            />
          </div>
        </div>
        <div class="uk-container">
          <div class="uk-grid-small uk-grid-large@m uk-grid" data-uk-grid>
            <div class="uk-width-1-3@m uk-text-center">
              <div
                class="uni-author-profile-details uk-card uk-card-small@m uk-flex-column uk-flex-middle"
              >
                <div class="uk-panel">
                  <div
                    class="uk-padding-2xsmall uk-radius-circle uk-background-white dark:uk-background-gray-100"
                  >
                    <div class="uk-panel uk-overflow-hidden uk-radius-circle">
                      <canvas
                        class="uk-visible@m"
                        width="100"
                        height="100"
                      ></canvas>
                      <canvas
                        class="uk-hidden@m"
                        width="90"
                        height="90"
                      ></canvas>
                      <img
                        :src="$baseUrl + authorDetails.image"
                        alt="Thesalvare"
                        class="uk-cover"
                        data-uk-cover
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <span
                    class="uni-user-verified uk-position-bottom-right"
                    style="line-height: 10px; transform: translate(-5px, -5px)"
                  >
                    <i
                      v-if="authorDetails.is_verified"
                      class="material-icons uk-icon-xsmall uk-icon-small@m uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100"
                      style="padding: 2px"
                      >verified</i
                    >
                  </span>
                </div>
                <h4
                  class="uk-h5 uk-h4@m uk-margin-xsmall-top uk-margin-top@m uk-margin-remove-bottom"
                >
                  {{ authorDetails.name }}
                </h4>
                <span class="uk-text-small uk-text-medium@m uk-text-muted"
                  >@{{ authorDetails.name }}</span
                >
                <!-- <p class="uk-margin-xsmall uk-margin@m">
                                <a href="#follow" class="uk-button uk-button-primary uk-button-small uk-button-medium@m uk-width-xsmall@m" data-toggle-active data-not-active-class="uk-button-primary uk-box-shadow-small" data-active-class="uk-button-default uk-button-outline">
                                    <span class="not-active">Follow</span>
                                    <span class="active">Following</span>
                                </a>
                            </p> -->
                <!-- <p class="uk-margin-xsmall uk-margin@m">
                  <button
                    v-if="
                      authorDetails.id != userInfo.id &&
                     // !authorDetails.is_following
                    "
                    role="button"
                    class="uk-button uk-button-primary uk-button-small uk-button-medium@m uk-width-xsmall@m"
                    data-toggle-active
                    data-not-active-class="uk-button-primary uk-box-shadow-small"
                    data-active-class="uk-button-default uk-button-outline"
                    @click="followUser(authorDetails)"
                  >
                    <span class="text-white">Follow</span>
                  </button>
                  <button
                    v-else-if="authorDetails.id != userInfo.id"
                    role="button"
                    class="uk-button uk-button-primary uk-button-small uk-button-medium@m uk-width-xsmall@m"
                    data-toggle-active
                    data-not-active-class="uk-button-primary uk-box-shadow-small"
                    data-active-class="uk-button-default uk-button-outline"
                    @click="unfollowUser(authorDetails)"
                  >
                    <span class="text-white">Following</span>
                  </button>
                </p> -->
                <div
                  class="uk-card uk-card-xsmall uk-radius uk-width-1-1 uk-margin-xsmall-top"
                >
                  <div
                    class="uk-grid-small uk-grid-divider uk-grid-match uk-child-width-1-3 uk-grid"
                    data-uk-grid
                  >
                    <div>
                      <div class="uk-panel">
                        <h5 class="uk-h5 uk-margin-remove">
                          {{ authorPosts.length ? authorPosts.length : "0" }}
                        </h5>
                        <p
                          class="uk-text-xsmall uk-text-small@m uk-text-muted uk-margin-remove uk-margin-2xsmall-top@m"
                        >
                          Events
                        </p>
                      </div>
                    </div>
                    <div>
                      <div class="uk-panel">
                        <h5 class="uk-h5 uk-margin-remove">
                          {{
                            AuthorSubmissions ? AuthorSubmissions.length : "0"
                          }}
                        </h5>
                        <p
                          class="uk-text-xsmall uk-text-small@m uk-text-muted uk-margin-remove uk-margin-2xsmall-top@m"
                        >
                          Submissions
                        </p>
                      </div>
                    </div>
                    <div>
                      <div class="uk-panel">
                        <h5 class="uk-h5 uk-margin-remove" v-if="AuthorPrizesGiven.winner_ticket !==null && AuthorPrizesGiven.winner_ticket !==drawn">
                          {{
                            AuthorPrizesGiven.length
                             
                          }}
                        </h5>
                        <h5 class="uk-h5 uk-margin-remove" v-else>
                          {{
                            "0"
                              
                          }}
                        </h5>
                        <p
                          class="uk-text-xsmall uk-text-small@m uk-text-muted uk-margin-remove uk-margin-2xsmall-top@m"
                        >
                          Prizes Given
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-inline uk-width-expand"></div>
              </div>
            </div>
            <div class="uk-width-expand">
              <div class="uni-author-profile-content uk-padding-medium-top@m">
                <div
                  id="profile-tabs"
                  class="uk-switcher-overflow"
                  data-uk-switcher="toggle: > *; animation: uk-animation-fade; duration: 100;"
                >
                  <button
                    class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-margin-xsmall-left"
                    type="button"
                    :class="activeTab == 'created' ? 'uk-active' : ''"
                    :aria-expanded="activeTab == 'created' ? true : false"
                    @click="activateTab('created')"
                  >
                    <span>Created</span>
                    <span class="uk-opacity-100">{{
                      authorPosts.length ? authorPosts.length : "0"
                    }}</span>
                  </button>
                  <button
                    class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-margin-xsmall-left"
                    type="button"
                    :class="{
                      'uk-active': activeTab == 'submissions',
                      // 'uk-hidden': authorDetails.id != userInfo.id,
                    }"
                    :aria-expanded="activeTab == 'submissions' ? true : false"
                    @click="activateTab('submissions')"
                  >
                    <span>Submissions</span>
                    <span class="uk-opacity-100">{{
                      AuthorSubmissions ? AuthorSubmissions.length : "0"
                    }}</span>
                  </button>
                  <button
                    class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-margin-xsmall-left"
                    type="button"
                    :class="activeTab == 'prizes' ? 'uk-active' : ''"
                    :aria-expanded="activeTab == 'prizes' ? true : false"
                    @click="activateTab('prizes')"
                  >
                    <span>Prize Given</span>
                    <span class="uk-opacity-100" v-if=" AuthorPrizesGiven.winner_ticket !== undefined">
                        {{ AuthorPrizesGiven.length  }}
                  </span>
                  <span class="uk-opacity-100" v-else>
                        {{ "0" }}
                  </span>

                  </button>

                  <!-- activeTab == 'editprofile' ? 'uk-active' : ''; authorDetails.id == userInfo.id; -->
                  <!-- <button
                    class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-margin-xsmall-left"
                    type="button"
                    :class="{
                      'uk-active': activeTab == 'editprofile',
                      'uk-hidden': authorDetails.id != userInfo.id,
                    }"
                    :aria-expanded="activeTab == 'editprofile' ? true : false"
                    @click="activateTab('editprofile')"
                  >
                    Edit Profile
                  </button> -->
                  <button
                    class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-margin-xsmall-left"
                    type="button"
                    :class="{
                      'uk-active': activeTab == 'editprofile',
                      'uk-hidden': authorDetails.id != userInfo.id,
                    }"
                    :aria-expanded="activeTab == 'editprofile' ? true : false"
                    @click="activateTab('editprofile')"
                  >
                    Edit Profile
                  </button>
                </div>
                <ul
                  v-show="isEditProfileListOpen"
                  class="uk-switcher uk-margin-medium-top"
                >
                  <li
                    :class="{
                      'uk-active': activeTab == 'submissions',
                      // 'uk-hidden': authorDetails.id != userInfo.id,
                    }"
                  >
                    <div
                      class="uk-grid-2xsmall uk-grid-xsmall@m uk-child-width-1-2 uk-child-width-1-3@m uk-grid"
                      data-uk-grid
                    >
                      <div
                        v-for="submission in AuthorSubmissions"
                        :key="submission.id"
                      >
                        <div
                          class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle h-100"
                        >
                          <div class="uni-artwork-header uk-panel"></div>
                          <div
                            class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m"
                            style="margin: 16px 0px"
                          >
                            <div class="uk-panel uk-image-middle">
                              <img
                                :src="$baseUrl + submission.image"
                                :style="{ height: '200px', width: '300px' }"
                                alt=""
                                class="uk-radius object_cover"
                              />
                              <router-link
                                :to="'/post-detail/' + submission.id"
                                class="uk-position-cover"
                                aria-label="Metaverse Game"
                              ></router-link>
                            </div>
                          </div>
                          <div class="uni-artwork-content uk-panel">
                            <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                              <!-- <router-link class="uk-link-reset" :to="'post-detail/'+post.id">{{ post.title }}</router-link> --><span
                                class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m"
                                >Date:
                                {{ formatDate(submission.created_at) }}</span
                              >
                            </h2>
                            <!-- <span class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block">Highest bid</span> --><span
                              class="uk-text-bold uk-text-gradient"
                              >Credit {{ submission.amount }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li :class="activeTab == 'created' ? 'uk-active' : ''">
                    <div
                      class="uk-grid-2xsmall uk-grid-xsmall@m uk-child-width-1-2 uk-child-width-1-3@m uk-grid"
                      data-uk-grid
                    >
                      <div v-for="(post, index) in authorPosts" :key="index">
                        <div
                          class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle"
                        >
                          <div class="uni-artwork-header uk-panel">
                            <div
                              class="uk-grid uk-grid-xsmall uk-flex-middle uk-flex-between uk-text-small uk-text-bold"
                              data-uk-grid
                            >
                              <div>
                                <div class="uk-text-bold">
                                  <div
                                    class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid"
                                    data-uk-grid
                                  >
                                    <div class="uk-width-auto">
                                      <div class="uni-user-avatar uk-panel">
                                        <div
                                          class="uk-panel uk-overflow-hidden uk-radius-circle"
                                        >
                                          <canvas
                                            width="32"
                                            height="32"
                                          ></canvas>
                                          <img
                                            v-if="authorDetails.image"
                                            :src="
                                              $baseUrl + authorDetails.image
                                            "
                                            alt="TheSalvare"
                                            class="uni-user-avatar-image uk-cover uk-radius-circle object_cover"
                                            data-uk-cover
                                          />
                                          <router-link
                                            :to="'/author/' + authorDetails.id"
                                            aria-label="TheSalvare"
                                            class="uk-position-cover"
                                          ></router-link>
                                        </div>
                                        <i
                                          v-if="authorDetails.is_verified"
                                          class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-3xsmall"
                                          >check_circle</i
                                        >
                                      </div>
                                    </div>
                                    <div class="uk-visible@m">
                                      <div>
                                        <router-link
                                          v-if="authorDetails.name"
                                          class="uni-user-link uk-link-reset"
                                          :to="'/author/' + authorDetails.id"
                                          >{{ authorDetails.name }}</router-link
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m"
                            style="margin: 16px 0"
                          >
                            <div class="uk-panel uk-image-middle">
                              <img
                                :src="$baseUrl + post.image"
                                :style="{ height: '200px', width: '300px' }"
                                alt=""
                                class="uk-radius object_cover"
                              />
                              <router-link
                                :to="'/post-detail/' + post.id"
                                class="uk-position-cover"
                                aria-label="Metaverse Game"
                              ></router-link>
                            </div>
                          </div>

                          <div class="uni-artwork-content uk-panel">
                            <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                              <router-link
                                :to="'/post-detail/' + post.id"
                                class="uk-link-reset"
                              >
                                {{ post.title }}
                              </router-link>
                              <span
                                class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m"
                              >
                                1 / 1
                              </span>
                            </h2>
                            <span
                              class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                              >Highest bid</span
                            >
                            <span class="uk-text-bold uk-text-gradient">{{
                              post.highest_bid ? post.highest_bid : 0
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li :class="activeTab == 'prizes' ? 'uk-active' : ''">
                    
                      

                          
                    <!-- New -->
                    <div
                      class="uk-grid-2xsmall uk-grid-xsmall@m uk-child-width-1-2 uk-child-width-1-3@m uk-grid"
                      data-uk-grid
                    >
                      <div v-if="AuthorPrizesGiven.winner_ticket !== null && AuthorPrizesGiven.winner_ticket !== drawn && AuthorPrizesGiven && AuthorPrizesGiven.length > 0">
                          <div
                            v-for="(prize, index) in AuthorPrizesGiven"
                            :key="index"
                          >
                            <div
                              class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle h-100"
                            >
                              <div class="uni-artwork-header uk-panel"></div>
                              <div
                                class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m"
                                style="margin: 16px 0px"
                              >
                                <div class="uk-panel uk-image-middle">
                                  <img
                                    :src="$baseUrl + prize.images"
                                    :style="{ height: '200px', width: '300px' }"
                                    alt=""
                                    class="uk-radius object_cover"
                                  />
                                  <router-link
                                      :to="'/post/' + prize.id"
                                      class="uk-position-cover"
                                      aria-label="Thesalvare"
                                  ></router-link>
                                </div>
                              
                              </div>
                              <div class="uni-artwork-content uk-panel">
                                <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                                    
                              <a
                                :href="'/post/' + prize.id"
                                class="uk-link-reset"
                                >{{ prize.name }}</a
                              >
                            </h2>
                            <span
                                    class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m"
                                    >
                                    
                            <div
                              class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                            >
                              Sponsor Amount: {{ prize.sponsor_amount }} ₹
                            </div>
                                  <!-- <router-link class="uk-link-reset" :to="'post-detail/'+post.id">{{ post.title }}</router-link> -->
                                  
                                    <div
                                        class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                                      >
                                        Winner ticket: {{ prize.winner_ticket }}
                                    </div>
                                    <div
                                        class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block"
                                      >
                                        Post Name: {{ prize.post_name }}
                                    </div>
                                      Post End Time: {{ prize.post_end_time }}
                                  
                                    </span>
              
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </li>

                  <li
                    :class="{
                      'uk-active': activeTab == 'editprofile',
                      'uk-hidden': authorDetails.id != userInfo.id,
                    }"
                  >
                    <div class="uk-panel">
                      <form @submit.prevent="updateProfile(userProfileInfo)">
                        <div
                          class="uk-grid uk-grid-small uk-child-width-1-1"
                          data-uk-grid
                        >
                          <div>
                            <h3 class="uk-h5">Edit profile</h3>
                          </div>
                          <div class="uk-width-1-2@m">
                            <div class="uk-form-controls">
                              <div
                                class="uk-panel uk-card-small uk-card-border uk-flex-column uk-flex-middle uk-text-center uk-radius"
                                style="border-style: dashed"
                              >
                                <p
                                  class="uk-text-bold uk-text-xsmall uk-text-muted uk-text-middle uk-margin-small"
                                >
                                  (500x500) Jpg, png, webp. max 300 kb.
                                </p>
                                <div data-uk-form-custom="">
                                  <input
                                    @change="onFileChange($event, 'profile')"
                                    type="file"
                                    style="cursor: pointer"
                                  />
                                  <span
                                    class="uk-button uk-button-small uk-button-primary uk-button-alt"
                                  >
                                    <i
                                      class="uk-icon-xsmall uk-margin-2xsmall-right"
                                      data-feather="upload-cloud"
                                    ></i>
                                    <span>Upload Profile </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="uk-width-1-2@m">
                            <div class="uk-form-controls">
                              <div
                                class="uk-panel uk-card-small uk-card-border uk-flex-column uk-flex-middle uk-text-center uk-radius"
                                style="border-style: dashed"
                              >
                                <p
                                  class="uk-text-bold uk-text-xsmall uk-text-muted uk-text-middle uk-margin-small"
                                >
                                  (1400x250) Jpg, png, webp. max 800 kb.
                                </p>
                                <div data-uk-form-custom="">
                                  <input
                                    @change="onFileChange($event, 'cover')"
                                    type="file"
                                    style="cursor: pointer"
                                  />
                                  <span
                                    class="uk-button uk-button-small uk-button-primary uk-button-alt"
                                  >
                                    <i
                                      class="uk-icon-xsmall uk-margin-2xsmall-right"
                                      data-feather="upload-cloud"
                                    ></i>
                                    <span>Upload cover</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h3 class="uk-h5 uk-margin-top">Change passowrd</h3>
                          </div>
                          <div class="uk-width-1-2@m">
                            <div class="uk-form-controls">
                              <input
                                v-model="userProfileInfo.password"
                                class="uk-input"
                                type="password"
                                placeholder="Old password"
                              />
                            </div>
                          </div>
                          <div class="uk-width-1-2@m">
                            <div class="uk-form-controls">
                              <input
                                v-model="userProfileInfo.new_password"
                                class="uk-input"
                                type="password"
                                placeholder="New password"
                              />
                            </div>
                          </div>
                          <div>
                            <div class="uk-form-controls">
                              <button
                                type="submit"
                                class="uk-button uk-button-medium uk-button-primary uk-width-1-1 uk-margin-small-top@m"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import moment from "moment";

import { mapState, mapActions } from "vuex";

export default {
  name: "AuthorView",
  data() {
    return {
      isAuthenticated: false,
      searchedValue: "",
      activeTab: "editprofile",
      winnerInformation: null,
    };
  },
  components: {
    Header,
    Footer,
    MobileMenu,
  },
  computed: {
    ...mapState({
      authorDetails: (state) => state.authorDetails,
      userInfo: (state) => state.userInfo,
      userProfileInfo: (state) => state.userProfileInfo,
      authorPosts: (state) => state.authorPosts,
      AuthorPrizesGiven: (state) => state.AuthorPrizesGiven,
      AuthorSubmissions: (state) => state.AuthorSubmissions,
    }),

    defaultEditProfileListOpen() {
      return this.isEditProfileListOpen;
    },
  },
  methods: {
    ...mapActions([
      "fetchAuthor",
      "followUser",
      "unfollowUser",
      "updateProfile",
    ]),

    onFileChange(event, file) {
      console.log(event.target.files[0]);
      if (file === "cover") this.userProfileInfo.cover = event.target.files[0];
      else if (file === "profile")
        this.userProfileInfo.image = event.target.files[0];
    },
    formatDate(dateString) {
      return moment(dateString).format("YYYY-MM-DD");
    },
    activateTab(val) {
      localStorage.setItem("activeTab", val);
      this.activeTab = val;
    },
  },
  created() {
    this.isEditProfileListOpen = true;
  },
  mounted() {
    let author_id = this.$route.params.id;
    this.fetchAuthor(author_id);

    this.activeTab = localStorage.getItem("activeTab") || "editprofile";
  },
};
</script>
