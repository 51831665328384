export default {
  getUserInfo: (state) => state.userInfo,
  getPosts: (state) => state.posts,

  getPasswordResetSuccess(state) {
    return state.passwordResetSuccess;
  },

  getPasswordResetError(state) {
    return state.passwordResetError;
  },
  getWinningAmount: state => state.winningAmount,
  getLoginStatus(state) {
    return state.loggedIn;
  },
};
