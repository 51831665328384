<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>
  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>
    <div
      class="uni-sign-in uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top"
    >
      <!-- Page header -->
      <header class="uni-page-header">
        <div class="uk-container">
          <h1 class="heading uk-h3 uk-h1@m uk-text-center">Sign in</h1>
        </div>
      </header>

      <div class="uk-margin-top uk-margin-large-top@m">
        <div class="uk-container">
          <div
            class="uk-flex-column uk-flex-center uk-width-medium@m uk-margin-auto"
          >
            <form
              class="uk-form-stacked uk-panel uk-width-1-1"
              @submit.prevent="login(loginForm)"
              method="post"
            >
              <div
                v-if="showMessagew.length"
                class="uk-text-meta uk-flex-middle"
              >
                <i
                  class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons"
                  >cross_circle</i
                >
                <span
                  v-for="(msg, ind) in showMessagew"
                  :key="ind"
                  class="text-danger-extra-large"
                  style="font-size: 18px; color: red; font-weight: bold"
                  >{{ msg }}</span
                >
              </div>

              <div class="uk-margin">
                <label for="email-login-page" class="uk-form-label"
                  >Your email: <span class="uk-text-danger">*</span></label
                >
                <input
                  id="email-login-page"
                  type="email"
                  v-model="loginForm.email"
                  class="uk-input"
                  required
                />
              </div>
              <div class="uk-margin">
                <label for="password-login-page" class="uk-form-label"
                  >Your password: <span class="uk-text-danger">*</span></label
                >
                <input
                  id="password-login-page"
                  type="password"
                  v-model="loginForm.password"
                  class="uk-input"
                  required
                />
              </div>
              <div
                class="uk-margin uk-grid uk-flex-between uk-flex-middle"
                data-uk-grid
              >
                <div>
                  <router-link
                    to="/forgot-password"
                    class="uk-link-line uk-text-small uk-text-bold"
                  >
                    <span>Forgot password</span>
                  </router-link>
                </div>
              </div>
              <div class="uk-margin-medium">
                <button
                  type="submit"
                  class="uk-button uk-button-primary uk-button-large@m uk-width-expand"
                >
                  Log in
                </button>
              </div>
            </form>

            <p class="uk-text-small uk-text-bold uk-text-center">
              Don't have an account?
              <router-link to="/register" class="uk-link-line"
                ><span>Sign up</span></router-link
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "LoginView",
  components: {
    Header,
    Footer,
    MobileMenu,
  },
  data() {
    return {
      status: false,
    };
  },
  computed: {
    ...mapState(["loginForm", "errors", "message"]),
    showMessagew() {
      if (
        !this.$route ||
        !this.$route.path ||
        !this.loginForm.email ||
        !this.loginForm.password
      ) {
        return [];
      }

      const errorKeys = this.errors ? Object.keys(this.errors) : [];
      const errorMessages = errorKeys.map((key) => this.errors[key]);

      const filteredErrorMessages = errorMessages.filter(
        (message) => !message.includes("headerError")
      );

      if (filteredErrorMessages.length > 0 && !this.message) {
        return filteredErrorMessages;
      } else if (this.message) {
        return [this.message];
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapActions(["login"]),
  },
};
</script>
