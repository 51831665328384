<template>
  <!-- Menu modal -->
  <MobileMenu></MobileMenu>

  <div class="wrap">
    <!-- Hero: Header -->
    <Header></Header>

    <div
      class="uni-activity uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top"
    >
      <!-- Page header -->
      <header class="uni-page-header">
        <div class="uk-container">
          <h1 class="heading uk-h3 uk-h1@m uk-text-center">Activity</h1>
        </div>
      </header>
      <div class="uk-margin-top uk-margin-large-top@m">
        <div class="uk-container">
          <div class="uk-panel">
            <div
              class="uni-activity-filters uk-grid-small uk-grid"
              data-uk-grid
              data-uk-filter="target: .uni-activity-filter; animation: delayed-fade;"
            >
              <div class="uni-activity-filter-wrap uk-width-1-3@m uk-visible@m">
                <div
                  class="uk-panel uk-card uk-card-border uk-card-small uk-radius"
                  data-uk-sticky="offset: 100; bottom: true; media: 720"
                >
                  <div
                    class="uk-grid-xsmall uk-flex-middle uk-grid"
                    data-uk-grid
                  >
                    <div>
                      <h5 class="uk-h6 uk-h5@m uk-margin-remove">Filters</h5>
                    </div>
                    <div>
                      <button
                        @click="fetchActivites(1)"
                        type="button"
                        class="uk-button uk-button-xsmall uk-button-primary uk-button-ghost"
                      >
                        Reset filter
                      </button>
                    </div>
                  </div>
                  <div class="uk-margin-top">
                    <div class="uk-grid-2xsmall uk-grid" data-uk-grid>
                      <div>
                        <a
                          class="uk-button uk-button-default uk-button-xsmall uk-button-outline"
                          href="javascript:void 0;"
                          @click="filter('event')"
                        >
                          <span
                            ><i class="material-icons uk-icon-2xsmall"
                              >sell</i
                            ></span
                          >
                          <span>Events</span>
                        </a>
                      </div>

                      <div>
                        <a
                          class="uk-button uk-button-default uk-button-xsmall uk-button-outline"
                          href="javascript:void 0;"
                          @click="filter('submission')"
                        >
                          <span
                            ><i class="material-icons uk-icon-2xsmall"
                              >shopping_cart</i
                            ></span
                          >
                          <span>Submissions</span>
                        </a>
                      </div>

                      <div>
                        <a
                          class="uk-button uk-button-default uk-button-xsmall uk-button-outline"
                          href="javascript:void 0;"
                          @click="filter('draw')"
                        >
                          <span
                            ><i class="material-icons uk-icon-2xsmall"
                              >savings</i
                            ></span
                          >
                          <span>Draw</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-expand@m uk-flex-first@m">
                <div class="uk-panel uk-width-1-1">
                  <div
                    id="activities-list-container"
                    class="uni-activity-filter uk-grid-xsmall uk-child-width-1-1 uk-grid  "
                    data-uk-grid
                  >
                    <div  v-if="activities.length">
                      <div
                        v-for="(notification, index) in activities"
                        :key="index"
                      >
                        <div
                          class="uni-activity-item mb-10 uk-card uk-card-border uk-card-xsmall uk-card-small@m uk-radius-large"
                        >
                          <div
                            class="uk-grid-xsmall uk-grid@m uk-flex-middle uk-grid"
                            data-uk-grid
                          >
                            <div class="uk-width-auto">
                              <div class="uk-panel">
                                <div
                                  class="uk-panel uk-overflow-hidden uk-radius"
                                >
                                  <canvas width="72" height="72"></canvas>
                                  <img
                                    :src="$baseUrl + notification.image"
                                    alt="#24 Midnite"
                                    class="uk-cover"
                                    data-uk-cover
                                    loading="lazy"
                                  />
                                  <a
                                    aria-label="#24 Midnite"
                                    class="uk-position-cover"
                                    href="#"
                                  ></a>
                                </div>
                                <!-- <span class="uni-filter-category uk-position-top-left" style="line-height: 10px"><i class="material-icons uk-icon-2xsmall uk-radius-circle uk-background-success uk-text-white">sell</i></span> -->
                              </div>
                            </div>
                            <div class="uk-width-expand">
                              <div
                                class="uk-panel uk-text-bold uk-text-muted uk-text-small uk-text-medium@m"
                              >
                                <h5
                                  class="uk-text-small uk-h6@m uk-margin-remove"
                                >
                                  <a class="uk-link-reset" href="#">{{
                                    notification.name
                                  }}</a>
                                </h5>
                                <p class="uk-margin-2xsmall">
                                  {{ parseData(notification.data).message }}
                                  <router-link
                                    :to="
                                      'post-detail/' +
                                      parseData(notification.data).post_id
                                    "
                                    class="uk-link uk-text-bold uk-text-gray-100 dark:uk-text-white"
                                    >@{{
                                      parseData(notification.data).post_title
                                    }}</router-link
                                  >
                                </p>
                                <span
                                  class="uk-flex-middle uk-text-xsmall uk-text-small@m"
                                  ><i
                                    class="uk-icon-2xsmall uk-margin-2xsmall-right"
                                    data-feather="clock"
                                  ></i>
                                  {{
                                    $activityDate(notification.created_at)
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <!-- Render the empty data message -->
                      <p>Record not found.</p>
                    </div>                    
                  </div>

                  <div
                    class="uni-btn uk-margin-top uk-margin-xlarge-top@m uk-flex-center"
                  >
                    <button
                      v-if="currentPage < lastPage"
                      @click="loadMore(++currentPage)"
                      class="uk-button uk-button-small uk-button-medium@m uk-button-default uk-button-outline uk-width-medium@m"
                      type="button"
                      data-load-more-container="#explore-list-container"
                      data-load-more="explore-ajax.html?page=%page%"
                      data-toggle-loading
                    >
                      <!-- <span class="no-more-loading">No more</span> -->
                      <span class="not-loading">Load more</span>
                      <span class="loading uk-margin-remove uk-flex-middle"
                        ><span
                          class="uk-flex-middle uk-margin-2xsmall-right"
                          data-uk-spinner="ratio: .5"
                        ></span
                        >Loading...</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-height-2xsmall" hidden></div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MobileMenu from "../components/MobileMenu.vue";

import { mapState, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "ActivityView",
  data() {
    return {
      searchedValue: "",
    };
  },
  components: {
    Header,
    Footer,
    MobileMenu,
  },
  computed: {
    ...mapState({
      activities: (state) => state.activities,
      currentPage: (state) => state.currentPage,
      lastPage: (state) => state.lastPage,
      isPayloadEmpty() {
        return (
          this.activities.payload === undefined ||
          this.activities.payload.length === 0
        );
      },
    }),
  },
  methods: {
    ...mapActions(["fetchActivites"]),
    parseData(data) {
      return JSON.parse(data);
    },
    loadMore(currentPage) {
      this.fetchActivites({
        currentPage: currentPage,
        searchedValue: this.searchedValue,
      });
    },
    filter(searchedValue) {
      this.searchedValue = searchedValue;
      this.fetchActivites({
        currentPage: this.currentPage,
        searchedValue: searchedValue,
      });
    },
  },
  mounted() {
    this.fetchActivites(1);
  },
};
</script>
