<template>
<!-- Menu modal -->
<MobileMenu></MobileMenu>

<!-- Send Report modal -->
<div id="send_report" class="uk-modal" data-uk-modal>
    <div class="uk-modal-dialog uk-card-xsmall uk-card-small@m uk-margin-auto-vertical uk-width-medium@m uk-radius-large">
        <button aria-label="Close" class="uk-modal-close-outside" type="button" data-uk-close></button>
        <div class="uk-panel uk-flex-center uk-flex-middle uk-height-1-1">
            <ul hidden class="uk-subnav uk-subnav-pill" data-uk-switcher="animation: uk-animation-fade; duration: 150;">
                <li><a href="#report">Report</a></li>
                <li><a href="#report_feedback">Feedback</a></li>
            </ul>
            <ul class="uk-switcher">
                <li>
                    <div class="uk-panel">
                        <h3 class="uk-h4">Why are you reporting?</h3>
                        <p class="uk-text-muted">Briefly describe what is the issue of this user and why should be removed</p>
                        <div class="uk-form-stack uk-grid-xsmall uk-child-width-1-1 uk-grid" data-uk-grid>
                            <form @submit.prevent="saveReport(reportForm)">
                                <div class="uk-form-group">
                                    <span class="uk-text-bold">User you are reporting:</span>
                                    <p class="uk-margin-small-top">{{ reportForm.userName }}</p>
                                </div>
                                <div class="uk-form-group">
                                    <span class="uk-text-bold">Your message:</span>
                                    <textarea required v-model="reportForm.description" class="uk-input uk-height-xsmall uk-margin-xsmall-top" placeholder="Tell us some details.."></textarea>
                                </div>
                                <div class="uk-form-group">
                                    <button type="submit" class="uk-button uk-button-medium uk-button-danger uk-width-1-1">Send report</button>
                                    <button @click="cancelReport()" type="button" class="uk-button uk-button-medium uk-button-default uk-button-alt uk-width-1-1 uk-margin-xsmall-top uk-modal-close">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <!-- <li>
                            <div class="uk-panel uk-text-center uk-flex-column uk-flex-middle">
                                <i class="uk-icon-large uk-text-success material-icons">check_circle</i>
                                <p class="uk-text-lead">We have received your report!</p>
                                <button type="button" class="uk-button uk-button-small uk-button-default uk-button-outline uk-margin-xsmall-top" data-uk-switcher-item="previous">Submit another report</button>
                            </div>
                        </li> -->
            </ul>
        </div>
    </div>
</div>

<div class="wrap">

    <!-- Hero: Header -->
    <Header></Header>

    <div class="uni-explore uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-border-top">

        <!-- Page header -->
        <header class="uni-page-header">
            <div class="uk-container">
                <h2 v-if="submissionOnMyPosts.length>0" class="heading uk-h3 uk-h1@m uk-text-center">Submissions on my events</h2>
                <h3 v-else class="heading uk-h3 uk-h1@m uk-text-center">There is no submission on your events</h3>
            </div>
        </header>
        <div class="uk-margin-top uk-margin-large-top@m">
            <div class="uk-container">

                <!-- mobile filters -->
                <!-- <div class="uni-explore-filter-mobile uk-hidden@m">
                    <div class="uk-grid-gallery uk-grid" data-uk-grid>
                        <div class="uk-width-expand">
                            <div class="uk-inline uk-width-1-1">
                                <span class="uk-form-icon uk-form-icon-flip">
                                    <i class="uk-icon-small" data-feather="search"></i>
                                </span>
                                <input type="search" class="uk-input uk-form-small" placeholder="Search items, collections..">
                            </div>
                        </div>
                        <div>
                            <button class="toggle-filter-button uk-button uk-button-small uk-button-icon uk-button-primary" type="button" data-uk-toggle="target: #toggle-filter">
                                <i class="uk-icon-small material-icons">filter_list</i>
                            </button>
                        </div>
                        <div class="uk-width-1-1">
                            <div id="toggle-filter" hidden>
                                <div class="uk-panel uk-card uk-card-2xsmall uk-radius uk-background-gray-10 dark:uk-background-gray-90">
                                    <ul class="uk-nav uk-dropdown-nav">
                                        <li class="uk-nav-header">By Type</li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="1" checked> Hot Bids</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="2"> Buy Now</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="3"> Live Auctions</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="4"> Has Offers</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-type" value="5"> Open for bids</label>
                                        </li>
                                        <li class="uk-nav-header">By category</li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="1" checked> All</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="2"> Art</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="3"> Game</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="4"> Photography</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="5"> Music</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-category" value="6"> Video</label>
                                        </li>
                                        <li class="uk-nav-header">Sort by</li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="1" checked> Newest</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="2"> Oldest</label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="3"> Price <i class="uk-icon-2xsmall material-icons">north</i></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="4"> Price <i class="uk-icon-2xsmall material-icons">south</i></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="5"> Reserve <i class="uk-icon-2xsmall material-icons">north</i></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="6"> Reserve <i class="uk-icon-2xsmall material-icons">south</i></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" class="uk-radio" name="filter-sort" value="7"> Ending Soon</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- artworks listing + desktop filters -->
                <div class="uk-margin-top">
                    <div class="uk-container">
                        <div class="uk-panel">
                            <div class="uk-grid uk-grid-large@m" data-uk-grid>
                                <!-- <div class="uk-width-1-4@m uk-visible@m">
                                            <div class="uk-panel" data-uk-sticky="offset: 120; bottom: true; media: @s;">
                                                <form action="?" class="uk-grid uk-grid-medium uk-child-width-1-1" data-uk-grid>
                                                    <div>
                                                        <div class="uk-form-controls">
                                                            <label class="uk-form-label uk-text-overline uk-text-muted uk-text-bold">Price Range</label>
                                                            <input class="uk-range" type="range" value="2" min="0" max="10" step="0.1">
                                                            <div class="uk-flex-between uk-text-small uk-text-bold uk-margin-xsmall-top" style="line-height: 1">
                                                                <span>0.01 ETH</span>
                                                                <span>10 ETH</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div data-uk-margin="margin: uk-margin-small-top uk-margin-top@m">
                                                            <div class="uk-form-controls">
                                                                <label class="uk-form-label uk-text-overline uk-text-muted uk-text-bold">Status</label>
                                                                <div class="uk-width-1-1" data-uk-form-custom="target: > * > span:first-child">
                                                                    <select>
                                                                        <option value="0">All</option>
                                                                        <option value="1">Live auction</option>
                                                                        <option value="2">Hot bids</option>
                                                                        <option value="3">Buy Now</option>
                                                                        <option value="4">Has Offers</option>
                                                                        <option value="5">Open for bids</option>
                                                                    </select>
                                                                    <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                                                        <span>Select option</span>
                                                                        <i class="uk-icon-2xsmall" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="uk-form-controls">
                                                                <label class="uk-form-label uk-text-overline uk-text-muted uk-text-bold">Category</label>
                                                                <div class="uk-width-1-1" data-uk-form-custom="target: > * > span:first-child">
                                                                    <select>
                                                                        <option value="All">All</option>
                                                                        <option value="Art">Art</option>
                                                                        <option value="Game">Game</option>
                                                                        <option value="Photography">Photography</option>
                                                                        <option value="Music">Music</option>
                                                                        <option value="Video">Video</option>
                                                                    </select>
                                                                    <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                                                        <span>Select option</span>
                                                                        <i class="uk-icon-2xsmall" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="uk-form-controls">
                                                                <label class="uk-form-label uk-text-overline uk-text-muted uk-text-bold">Creator</label>
                                                                <div class="uk-width-1-1" data-uk-form-custom="target: > * > span:first-child">
                                                                    <select>
                                                                        <option value="1">All</option>
                                                                        <option value="2">Verified only</option>
                                                                        <option value="3">Most liked</option>
                                                                    </select>
                                                                    <button class="uk-button uk-button-xsmall uk-button-default uk-button-outline uk-radius uk-width-expand" type="button" tabindex="-1" style="justify-content: space-between">
                                                                        <span>Select option</span>
                                                                        <i class="uk-icon-2xsmall" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="uk-panel">
                                                            <button type="reset" class="uk-button uk-button-small uk-button-primary uk-button-alt uk-width-1-1">
                                                                <i class="uk-icon-small material-icons">clear</i>
                                                                <span>Reset Filter</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <noscript>
                                                        <div>
                                                            <div class="uk-panel">
                                                                <button type="submit" class="uk-button uk-button-small uk-button-primary uk-button-alt uk-width-1-1">
                                                                    <span>Update Filter</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </noscript>
                                                </form>
                                            </div>
                                        </div> -->
                                <div class="uk-width-expand@m">
                                    <div v-if="submissionOnMyPosts.length>0" id="explore-list-container" class="submission_grid submission_posts_grid">
                                        <div v-for="(submission, index) in submissionOnMyPosts" :key="index" class="h-100">
                                            <div class="uni-artwork uk-card uk-card-xsmall uk-card-border uk-overflow-hidden uk-radius uk-radius-large@m uk-box-shadow-hover-medium uk-visible-toggle uk-transition-toggle">
                                                <div class="uni-artwork-header uk-panel">
                                                    <div class="uk-grid uk-grid-xsmall uk-flex-middle uk-flex-between uk-text-small uk-text-bold" data-uk-grid>
                                                        <div>
                                                            <div class="uk-text-bold">
                                                                <div class="uni-user uk-flex-middle uk-grid-2xsmall uk-grid" data-uk-grid>
                                                                    <div class="uk-width-auto">
                                                                        <div class="uni-user-avatar uk-panel">
                                                                            <div class="uk-panel uk-overflow-hidden uk-radius-circle">
                                                                                <canvas width="32" height="32"></canvas>
                                                                                <img v-if="submission.user && submission.user.image" :src="$baseUrl+submission.user.image" alt="TheSalvare" class="uni-user-avatar-image uk-cover uk-radius-circle" data-uk-cover>
                                                                                <router-link aria-label="TheSalvare" :to="'post-detail/'+submission.post_id" class="uk-position-cover"></router-link>
                                                                            </div>
                                                                            <i v-if="submission.user && submission.user.is_verified" class="uni-user-verified material-icons uk-radius-circle uk-background-white uk-text-info dark:uk-background-gray-100 uk-icon-3xsmall">check_circle</i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uk-visible@m">
                                                                        <div>
                                                                            <router-link v-if="submission.user && submission.user.name" class="uni-user-link uk-link-reset" :to="'post-detail/'+submission.post_id">{{ submission.user.name }}</router-link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button type="button" class="uk-button uk-button-2xsmall uk-button-icon uk-button-default uk-button-ghost uk-radius-circle">
                                                                <i class="uk-icon-xsmall material-icons">more_horizontal</i>
                                                            </button>
                                                            <div class="uk-dropdown-minimal uk-text-bold uk-dropdown" data-uk-dropdown="mode: click">
                                                                <ul class="uk-nav uk-dropdown-nav">
                                                                    <!-- <li><a href="#connect_wallet" data-uk-toggle onclick="UIkit.switcher('#connect_wallet [data-uk-switcher]').show(0)">Purchase now</a></li>
                                                                            <li><a href="#share_this" data-uk-toggle>Share</a></li>
                                                                            <li class="uk-nav-divider"></li> -->
                                                                    <span v-if="!submission.is_verified">
                                                                        <li><button @click="changeVerification(submission.id,'verify')" class="uk-button uk-button-medium uk-button-success uk-width-1-1">Verify</button></li>
                                                                        <li><button @click="changeVerification(submission.id,'reject')" class="uk-button uk-button-medium uk-button-warning uk-width-1-1">Reject</button></li>
                                                                    </span>
                                                                    <li><a href="#send_report" @click="setReportData(submission)" data-uk-toggle class="uk-text-danger">Report</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="uni-artwork-featured-image uk-panel uk-overflow-hidden uk-flex-middle uk-flex-center uk-height-medium@m" style="margin: 16px 0">
                                                    <div class="uk-panel uk-image-middle">
                                                        <img v-if="submission.image" :src="$baseUrl+submission.image" alt="" class="uk-radius">
                                                        <router-link :to="'post-detail/'+submission.post.id" class="uk-position-cover" aria-label="Metaverse Game"></router-link>
                                                    </div>
                                                    <!-- <div class="uk-label uk-label-primary uk-background-gradient uk-position-bottom-left uk-font-mono">
                                                                <div class="uk-grid-gallery uk-child-width-auto uk-text-bold uk-grid" data-uk-grid data-uk-countdown="date: 2022-12-31T07:40:25+00:00">
                                                                    <div class="uk-countdown-hours"></div>
                                                                    <div class="sep">:</div>
                                                                    <div class="uk-countdown-minutes"></div>
                                                                    <div class="sep">:</div>
                                                                    <div class="uk-countdown-seconds"></div>
                                                                </div>
                                                            </div> -->
                                                </div>

                                                <div class="uni-artwork-content uk-panel d-flex justify-content-between">
                                                    <span>
                                                        <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                                                            <p v-if="submission.luckdrawnumbers.length>0" class="uk-link-reset" href="#">{{ getNumbers(submission.luckdrawnumbers) }}</p>
                                                            <span class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block">Lucky No.</span>
                                                        </h2>
                                                        <h2 class="uk-h6 uk-h5@m uk-margin-2xsmall">
                                                            <!-- <router-link class="uk-link-reset" :to="'post-detail/'+post.id">{{ post.title }}</router-link> -->
                                                            <span class="uk-text-meta uk-text-bold uk-text-middle uk-margin-2xsmall-left uk-visible@m">Date: {{ $formatDate(submission.created_at) }}</span>
                                                        </h2>
                                                        <!-- <span class="uk-text-bold uk-text-xsmall uk-text-muted uk-display-block">Highest bid</span> -->
                                                        <span class="uk-text-bold uk-text-gradient">Winning amount: {{ submission.amount }}</span>
                                                    </span>
                                                    <!-- <span v-if="submission.is_verified==1" class="verification">
                                                        <span class="uk-text-meta uk-flex-middle"><i class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons">check_circle</i>
                                                        </span>
                                                    </span> -->
                                                    <span v-if="submission.is_verified === 1" class="verification">
                                                        <span class="uk-text-meta uk-flex-middle">
                                                            <i class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-success material-icons">check_circle</i>
                                                        </span>
                                                    </span>
                                                    <span v-else-if="submission.is_verified === 0" class="verification">
                                                        <span class="uk-text-meta uk-flex-middle">
                                                            <i class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-warning material-icons">schedule</i>
                                                        </span>
                                                    </span>
                                                    <span v-else-if="submission.is_verified === 2" class="verification">
                                                        <span class="uk-text-meta uk-flex-middle">
                                                            <i class="uk-icon-xsmall uk-margin-2xsmall-right uk-text-danger material-icons">close</i>
                                                        </span>
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="uni-btn uk-margin-large-top uk-margin-xlarge-top@m uk-flex-center">
                                        <button v-if="currentPage < lastPage" @click="loadMore(post_id,++currentPage)" class="uk-button uk-button-small uk-button-medium@m uk-button-default uk-button-outline uk-width-medium@m" type="button" data-load-more-container="#explore-list-container" data-toggle-loading>
                                            <!-- <span class="no-more-loading">No more</span> -->
                                            <span class="not-loading">Load more</span>
                                            <span class="loading uk-margin-remove uk-flex-middle"><span class="uk-flex-middle uk-margin-2xsmall-right" data-uk-spinner="ratio: .5"></span>Loading...</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<Footer></Footer>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import MobileMenu from '../components/MobileMenu.vue';
import {
    mapState,
    mapActions
} from 'vuex';
export default {
    name: 'SubmissionReceivedView',
    data() {
        return {
            post_id: null
        }
    },
    components: {
        Header,
        Footer,
        MobileMenu
    },
    computed: {
        ...mapState({
            submissionOnMyPosts: state => state.submissionOnMyPosts,
            currentPage: state => state.currentPage,
            lastPage: state => state.lastPage,
            reportForm: state => state.reportForm
        })
    },
    methods: {
        ...mapActions([
            "fetchSubmissionOnMyPosts",
            "submissionApproval",
            "saveReport",
            "cancelReport"
        ]),
        setReportData(submission) {
            this.reportForm.report_against = submission.user.id;
            this.reportForm.userName = submission.user.name;
            this.reportForm.post_id = submission.post.id;
            this.reportForm.submission_id = submission.id;
        },
        changeVerification(submission_id, verification) {
            this.submissionApproval({
                submission_id,
                verification
            });
        },
        loadMore(post_id, currentPage) {
            this.fetchSubmissionOnMyPosts({
                post_id,
                currentPage
            });
        },
        getNumbers(numbers) {
            let luckyNumber = '';
            numbers.forEach((item) => luckyNumber = luckyNumber + item.number + " , ");
            return luckyNumber.slice(0, -2);
        }
    },
    mounted() {
        if (this.$route.params.id)
            this.post_id = this.$route.params.id;

        this.fetchSubmissionOnMyPosts({
            post_id: this.post_id,
            currentPage: 1
        });
    }
}
</script>
