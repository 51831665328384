import { createRouter, createWebHistory } from "vue-router";

//Components Routes

import IndexView from "../views/HomeView.vue";
import PostDetailView from "../views/PostDetailView.vue";
import LoginPage from "../views/LoginView.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetpasswordView.vue";
import RegisterView from "../views/RegisterView.vue";
import CreatePost from "../views/CreatePost.vue";
import MyPostView from "../views/MyPostView.vue";
import SubmissionReceivedView from "../views/SubmissionReceivedView.vue";
import MySubmissionView from "../views/MySubmissionView.vue";
import ActivityView from "../views/ActivityView.vue";
import AuthorView from "../views/AuthorView.vue";
import AuthorsView from "../views/AuthorsView.vue";
import HomeView from "../views/IndexView.vue";
import VerifyEmail from "../views/VerifyemailView.vue";
import ContactView from "../views/ContactView.vue";
import store from "../store/index";
import EditSubmissionModal from '../views/EditSubmissionModal.vue';

const routes = [
  {
    path: "/explore",
    name: "IndexView",
    component: IndexView,

    meta: { requiresAuth: false },
  },

  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,

    meta: { requiresAuth: false },
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: { requiresAuth: false },
  },
  {
    path: "/my-events",
    name: "MyPostView",
    component: MyPostView,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch("fetchUserInfo");

        const userRole = store.getters.getUserInfo.role;

        if (userRole === "user") {
          next("/");
        } else {
          next();
        }
      } catch (error) {
        console.error(error);
        next("/error");
      }
    },
  },

  {
    path: "/all-authors",
    name: "AuthorsView",
    component: AuthorsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/activity",
    name: "ActivityView",
    component: ActivityView,
    meta: { requiresAuth: true },
  },
  {
    path: "/submissions/:id?",
    name: "SubmissionReceivedView",
    component: SubmissionReceivedView,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch("fetchUserInfo");

        const userRole = store.getters.getUserInfo.role;

        if (userRole === "user") {
          next("/");
        } else {
          next();
        }
      } catch (error) {
        console.error(error);
        next("/error");
      }
    },
  },

  {
    path: "/my-submissions/:id?",
    name: "MySubmissionView",
    component: MySubmissionView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginPage,
    meta: { requiresAuth: false },
  },

  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordView",
    component: ResetPassword,
  },
  {
    path: "/author/:id",
    name: "AuthorView",
    component: AuthorView,
    meta: { requiresAuth: true },
  },
  {
    path: "/register",
    name: "RegisterView",
    component: RegisterView,
    meta: { requiresAuth: false },
  },
  {
    path: "/post-detail/:id(\\d+)",
    name: "postDetailView",
    component: PostDetailView,
    meta: { requiresAuth: false },
  },
  {
    path: '/post-detail/:id',
    name: 'post-detail',
    component: EditSubmissionModal,
  },
  {
    path: "/create-post",
    name: "CreatePost",
    component: CreatePost,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch("fetchUserInfo");

        const userRole = store.getters.getUserInfo.role;

        if (userRole === "user") {
          next("/");
        } else {
          next();
        }
      } catch (error) {
        console.error(error);
        next("/error");
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const sanctum_token = localStorage.getItem("sanctum_token");
  console.log(to);
  if (
    to.meta.requiresAuth &&
    (sanctum_token == "undefined" || !sanctum_token)
  ) {
    // redirect to login view if the user is not authenticated
    next({ name: "LoginView" });
  }
  if (to.path == "/login" && sanctum_token && sanctum_token != "undefined") {
    // redirect to login view if the user is not authenticated
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
